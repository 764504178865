import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Injector,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { CommitteeService, DocumentService, PhotoService, UserService } from '@src/core/services';
import { CommitteeUI, UserUI, ViewMode } from '@src/models';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { ObjectId } from '@src/types/id';
import { Nullable } from '@src/types/utils';
import { isFileContact, reload } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';
import { LogoutService, SessionService } from '@src/app/modules/auth';

import { DeleteAuthUserDialogComponent } from './components';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent implements OnChanges, OnDestroy {
  @Input() mode: ViewMode = 'view';
  @Input() userId: Nullable<ObjectId>;
  @Input() organisationId?: string | null;
  @Input() allowDeleting?: boolean;
  @Input() searchByPhone: boolean = true;
  @Input() isParentOrganisation: boolean = false;
  @Output() saved: EventEmitter<number | string> = new EventEmitter(); // TODO: Fix type
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() deleted: EventEmitter<UserUI> = new EventEmitter();

  data$: BehaviorSubject<UserUI | null> = this.userService.user$;
  committeesList$: BehaviorSubject<CommitteeUI[] | null> = this.committeeService.committeesForUser$;
  allowEditing$: BehaviorSubject<boolean | undefined>;
  allowDeleting$: BehaviorSubject<boolean | undefined>;
  allowIsDefaultOrganisationEditing$: BehaviorSubject<boolean | undefined>;
  allowSpecialFieldsViewing$: BehaviorSubject<boolean | undefined>;
  allowSpecialFieldsEditing$: BehaviorSubject<boolean | undefined>;
  allowSpecialFieldsForAssociationViewing$: BehaviorSubject<boolean | undefined>;
  allowSpecialFieldsForAssociationEditing$: BehaviorSubject<boolean | undefined>;
  allowJobTitleFieldsEditing$: BehaviorSubject<boolean | undefined>;
  allowJobTitlesForAdminOnlyEditing$: BehaviorSubject<boolean | undefined>;
  allowAssociationEmployeeEditing$: BehaviorSubject<boolean | undefined>;
  allowOrganisationEmployeeEditing$: BehaviorSubject<boolean | undefined>;
  allowEmailFieldViewing$: BehaviorSubject<boolean | undefined>;
  allowEmailFieldEditing$: BehaviorSubject<boolean | undefined>;
  allowContactFieldCreating$: BehaviorSubject<boolean | undefined>;
  allowContactFieldEditing$: BehaviorSubject<boolean | undefined>;
  allowContactFieldDeleting$: BehaviorSubject<boolean | undefined>;

  loading = false;

  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmCancelEditingDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.undoEditHeader'),
      size: 's',
      closeable: false,
    },
  );

  private readonly confirmDeleteAuthUserDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DeleteAuthUserDialogComponent, this.injector),
    {
      label: this.translateService.instant('components.userInfo.dialogs.deleteAuthUserHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly committeeService: CommitteeService,
    private readonly photoService: PhotoService,
    private readonly documentService: DocumentService,
    private readonly userService: UserService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly translateService: TranslateService,
    private readonly logoutService: LogoutService,
    private readonly session: SessionService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    this.allowEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowDeleting$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowIsDefaultOrganisationEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowSpecialFieldsViewing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowSpecialFieldsEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowSpecialFieldsForAssociationViewing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowSpecialFieldsForAssociationEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowJobTitleFieldsEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowJobTitlesForAdminOnlyEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowAssociationEmployeeEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowOrganisationEmployeeEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowEmailFieldViewing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowEmailFieldEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowContactFieldCreating$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowContactFieldEditing$ = new BehaviorSubject<boolean | undefined>(undefined);
    this.allowContactFieldDeleting$ = new BehaviorSubject<boolean | undefined>(undefined);

    this.data$.pipe(takeUntil(this.destroyed$$)).subscribe(user => {
      if (user?.id) {
        this.committeeService.getCommitteesByUserId(user.id);
      } else {
        this.committeeService.resetCommitteesForUser();
      }
      this.loading = false;
      this.cdr.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userId) {
      this.userService.resetUser();
      if (!!this.userId) {
        this.mode = 'view';
        this.userService.loadUser(this.userId);
      }
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.userService.resetUser();
      }
    }

    this.userService.authUser$
      .subscribe(user => {
        if (!user || !user.organisationId) return;

        const permissions = this.ngxPermissionsService.getPermissions();
        const permissionsForOrganisation = this.organisationId
          ? user.permissionsForOrganisations?.[this.organisationId]
          : [];

        // Разрешение редактирования пользователя
        this.allowEditing$.next(
          'organisationEmployeeEditing' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeEditing') ||
            user.id === this.userId,
        );

        // Разрешение редактирования дефолтной организации пользователя
        this.allowIsDefaultOrganisationEditing$.next(
          'organisationEmployeeEditing' in permissions || user.id === this.userId,
        );

        // Разрешение удаления пользователя
        this.allowDeleting$.next(
          'organisationEmployeeDeleting' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeDeleting'),
        );

        // Разрешения для полей Телефон и Роль
        this.allowSpecialFieldsViewing$.next(
          'organisationEmployeeSpecialFieldsViewing' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsViewing'),
        );

        this.allowSpecialFieldsEditing$.next(
          'organisationEmployeeSpecialFieldsEditing' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsEditing'),
        );

        // Разрешения для полей Куратор, Взнос не оплачен и Функциональные должности
        this.allowSpecialFieldsForAssociationViewing$.next(
          'organisationEmployeeSpecialFieldsForAssociationViewing' in permissions,
        );

        this.allowSpecialFieldsForAssociationEditing$.next(
          'organisationEmployeeSpecialFieldsForAssociationEditing' in permissions,
        );

        // Разрешение для поля Должность в компании
        this.allowJobTitleFieldsEditing$.next(
          'organisationEmployeeJobTitleFieldsEditing' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeJobTitleFieldsEditing') ||
            ('profileJobTitleFieldsEditing' in permissions && user.id === this.userId),
        );

        // Разрешение для фильтрации списка Функциональные должности
        this.allowJobTitlesForAdminOnlyEditing$.next('organisationEmployeeJobTitlesForAdminOnlyEditing' in permissions);

        // Разрешения для полей Должность в компании, Функциональные должности и Роль
        this.allowAssociationEmployeeEditing$.next('organisationEmployeeEditing' in permissions);
        // TODO: Вроде нужно объединить с условием выше через || (или), по аналогии с остальными разрешениями
        this.allowOrganisationEmployeeEditing$.next(
          permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeEditing'),
        );

        // Разрешения для поля Email
        this.allowEmailFieldViewing$.next(
          'organisationEmployeeSpecialFieldsViewing' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsViewing') ||
            user.id === this.userId,
        );

        this.allowEmailFieldEditing$.next(
          'organisationEmployeeSpecialFieldsEditing' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsEditing') ||
            user.id === this.userId,
        );

        // Разрешения для Доп. полей (контактов)
        this.allowContactFieldCreating$.next(
          'contactsCreating' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeContactsCreating'),
        );

        this.allowContactFieldEditing$.next(
          'contactsEditing' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeContactsEditing'),
        );

        this.allowContactFieldDeleting$.next(
          'contactsDeleting' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeContactsDeleting'),
        );
      })
      .unsubscribe();

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onChangePhone(phone: string) {
    if (!this.searchByPhone) return;

    if (!phone || phone.length < 9) return;

    this.userService.getUserByPhone(phone.slice(1), value => {
      this.loading = value;
      this.cdr.markForCheck();
    });
  }

  onStartEditing() {
    this.mode = 'edit';
  }

  async onSaveData(data: UserUI): Promise<void> {
    if (this.mode === 'edit' || data.organisationsToAdd) {
      this.updateUser(data);
    } else if (this.mode === 'create') {
      this.createUser(data);
    }
  }

  onCancel(): void {
    this.confirmCancelEditingDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.canceled.emit();
          if (this.userId) {
            this.userService.resetUser();
            this.userService.loadUser(this.userId);
          }

          this.mode = 'view';
        }
      },
    });
  }

  onDelete(user: UserUI): void {
    this.deleted.emit(user);
  }

  onDeleteAuthUser(userId: string): void {
    this.confirmDeleteAuthUserDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: async res => {
        if (res) {
          this.loading = true;
          await lastValueFrom(this.userService.deleteUser(userId));
          this.logoutService.logout();
        }
      },
    });
  }

  private createUser(data: UserUI): void {
    this.userService
      .createUser(data)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(async res => {
        const newUserId = res.id;
        if (newUserId) {
          if (data.photo) {
            await this.uploadPhoto(data.photo, newUserId, true);
          }

          if (data.paymentDetails) {
            await this.uploadDocument(data.paymentDetails, 16, newUserId); // 16 Письмо с реквизитами для пользователя
          }

          if (data.contacts) {
            const promises = data.contacts.map(async contact => {
              if (isFileContact(contact.contactTypeId)) {
                if (contact.oldDocument?.id) {
                  contact.contact = contact.oldDocument.id;
                }

                if (contact.newDocument) {
                  contact.contact = await this.uploadDocument(contact.newDocument, 10); // 10 Документ
                }

                contact.oldDocument = undefined;
                contact.newDocument = undefined;
              }
              return contact;
            });
            await Promise.all(promises);

            await this.userService.replaceUserContacts({ userId: newUserId, contacts: data.contacts });
          }
        }

        this.saved.emit(newUserId);

        this.mode = 'view';
        this.cdr.markForCheck();
      });
  }

  private updateUser(data: UserUI): void {
    this.userService
      .editUser(data)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(async res => {
        const userId = res.id;
        if (userId) {
          if (data.photo) {
            await this.uploadPhoto(data.photo, userId, true);
          }

          if (data.paymentDetails) {
            await this.uploadDocument(data.paymentDetails, 16, userId); // 16 Письмо с реквизитами для пользователя
          }

          if (data.deletePaymentDetailsId) {
            await lastValueFrom(this.deleteDocument(data.deletePaymentDetailsId));
          }

          if (data.contacts) {
            const promises = data.contacts.map(async contact => {
              if (isFileContact(contact.contactTypeId)) {
                if (contact.oldDocument?.id) {
                  contact.contact = contact.oldDocument.id;
                }

                if (contact.newDocument) {
                  contact.contact = await this.uploadDocument(contact.newDocument, 10); // 10 Документ
                }

                contact.oldDocument = undefined;
                contact.newDocument = undefined;
              }
              return contact;
            });
            await Promise.all(promises);

            await this.userService.replaceUserContacts({ userId, contacts: data.contacts });
          }

          this.userService.loadUser(userId);
        }

        this.saved.emit(userId);

        this.mode = 'view';
        this.cdr.markForCheck();

        // TODO: START
        // временно решили брать только роль дефолтной организации по задаче #10370 (ждем когда бэк будет работать с несколькими ролями)
        const authUser = this.userService.authUser$.value;
        const defaultOrganisationId = data.organisations?.find(org => org.isDefault)?.organisationId;
        if (
          authUser &&
          authUser.id === data.id &&
          authUser.organisationId !== defaultOrganisationId &&
          authUser.parentOrganisationId
        ) {
          this.session.changeToken(authUser.parentOrganisationId).subscribe(() => reload());
        }
        // TODO: END
      });
  }

  private async uploadPhoto(photo: File, attachId: string, isCover: boolean) {
    if (!photo) return;

    return lastValueFrom(this.photoService.uploadPhoto(photo, attachId, isCover));
  }

  private async uploadDocument(document: File, documentType?: number, attachId?: string): Promise<string | undefined> {
    if (!document) return;

    const documentList = await lastValueFrom(this.documentService.addDocuments([document], attachId, documentType));

    return documentList?.files?.[0].id;
  }

  private deleteDocument(documentId: string) {
    return this.documentService.deleteDocuments([documentId])[0];
  }
}
