<div *ngIf="mode === 'view'" class="field field_size_{{ size ? size : 'l' }}">
  <div class="field__name" [title]="'menu.committees' | customNames">{{ 'menu.committees' | customNames }} &nbsp;</div>
  <div class="field__value">
    <a *ngIf="committee$ | async as committee; else loading" [routerLink]="'/committees/' + committee.id">
      {{ committee.name }}
    </a>
    <ng-template #loading>
      <span>{{ 'common.labels.loading' | translate }}...</span>
    </ng-template>
  </div>
</div>
