<div class="search-users">
  <div *ngIf="headerVisible" class="search-users__header">
    {{ 'components.searchUsers.labels.selected' | translate }}: {{ members?.length }}
  </div>
  <div>
    <tui-multi-select
      #parent="tuiActiveZone"
      [tuiTextfieldSize]="size ?? tuiElementLargeSize"
      [tuiDropdown]="dropdownContent"
      [tuiDropdownManual]="dropdownContentVisible"
      [tuiTextfieldLabelOutside]="true"
      [expandable]="true"
      [rows]="10"
      [editable]="false"
      [stringify]="stringify"
      [disabled]="disabled"
      [(ngModel)]="members"
      (ngModelChange)="onChangeMembers(members)"
      (tuiActiveZoneChange)="onActiveZone($event)"
      (click)="onClickField()"
    >
      <ng-content></ng-content>
    </tui-multi-select>
  </div>
</div>

<ng-template #dropdownContent let-activeZone>
  <mat-grid-list class="search-users__filter" [cols]="multiple && users?.length ? 2 : 1" rowHeight="44px">
    <mat-action-list>
      <mat-grid-tile>
        <mat-list-item class="filter__button" (click)="onClickFilterButton()">
          <mat-icon matListIcon>filter_alt</mat-icon>
          <h3 matLine>{{ 'common.labels.filter' | translate }}</h3>
        </mat-list-item>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="multiple && users?.length">
        <mat-list-item
          class="filter__select-all"
          (click)="onClickSelectAll(!selectAll, usersList)"
          [disabled]="loadingSelected"
          [disableRipple]="loadingSelected"
        >
          <mat-checkbox
            matListIcon
            class="checkbox-select-all"
            [checked]="selectAll"
            [indeterminate]="getIndeterminate(selectedUsers)"
          ></mat-checkbox>
          <h3 matLine class="select-all-title">
            {{
              selectAll
                ? ('components.searchUsers.labels.deleteAll' | translate)
                : ('components.searchUsers.labels.addAll' | translate)
            }}
            <tui-loader size="s" [showLoader]="loadingSelected" [overlay]="false"></tui-loader>
          </h3>
        </mat-list-item>
      </mat-grid-tile>
    </mat-action-list>
  </mat-grid-list>
  <div *ngIf="users?.length" class="members-counter">
    <span class="members-counter__title">{{ 'common.labels.quantity' | translate }}: {{ users?.length }}</span>
  </div>
  <div class="search-input">
    <tui-input
      tuiTextfieldSize="s"
      [tuiTextfieldCleaner]="true"
      [(ngModel)]="filter.name"
      (ngModelChange)="startSearch()"
    >
      {{ 'components.searchUsers.labels.searchByName' | translate }}
    </tui-input>
  </div>
  <div class="search-users__list" [tuiActiveZoneParent]="parent">
    <tui-scrollbar class="users-list__wrap">
      <tui-loader [inheritColor]="true" [showLoader]="!users">
        <mat-grid-list [cols]="1" rowHeight="70px">
          <mat-selection-list
            #usersList
            class="users-list"
            color="primary"
            [multiple]="!!multiple"
            [(ngModel)]="selectedUsers"
            [disabled]="loadingSelected"
            (selectionChange)="onChangeSelectedUsers($event)"
          >
            <div *ngIf="!users?.length" class="nodata">{{ 'common.labels.noData' | translate }}</div>

            <mat-grid-tile *ngFor="let user of users">
              <mat-list-option class="users-list-item" [value]="user" checkboxPosition="after">
                <div matListIcon class="users-list-item__photo">
                  <tui-avatar
                    size="m"
                    [autoColor]="!user.photoPath"
                    [rounded]="true"
                    [avatarUrl]="user.photoPath ? user.photoPath : ''"
                    [text]="user | userDisplayableName : 'short'"
                  ></tui-avatar>
                </div>
                <div matLine class="users-list-item__title" [title]="user | userDisplayableName">
                  {{ user | userDisplayableName }}
                </div>
                <ng-container *ngFor="let organisationJobTitle of user.jobTitlesInOrganisation">
                  <div
                    matLine
                    class="users-list-item__subtitle"
                    [title]="organisationJobTitle | orgJobTitleDisplayableName"
                  >
                    {{ organisationJobTitle | orgJobTitleDisplayableName }}
                  </div>
                </ng-container>
              </mat-list-option>
            </mat-grid-tile>
          </mat-selection-list>
        </mat-grid-list>
      </tui-loader>
    </tui-scrollbar>
  </div>
</ng-template>

<ng-template #filterDialogTemplate let-observer>
  <app-filter
    [(filter)]="filter"
    [excludeFields]="excludeFields"
    [size]="tuiElementLargeSize"
    (applyFilter)="applyFilter(observer)"
    (cancelFilter)="cancelFilter(observer)"
  ></app-filter>
</ng-template>
