<div class="parse-link__wrapper">
  <span #root class="parse-link">
    <ng-container *ngFor="let element of preparedHTMLStructure">
      <ng-container [ngSwitch]="element.type">
        <span *ngSwitchCase="'space'">{{ ' ' }}</span>

        <br *ngSwitchCase="'new-line'" />

        <span *ngSwitchCase="'text'">{{ element?.value }}</span>

        <a *ngSwitchCase="'email'" class="email" [href]="'mailto:' + element?.value" target="_blank">
          {{ element?.value }}
        </a>

        <a
          *ngSwitchCase="'url'"
          app-render-link
          [displayableText]="element?.value ?? ''"
          [url]="element?.url ?? ''"
        ></a>
      </ng-container>
    </ng-container>
  </span>
</div>

<ng-template #tmpRedirectToUrl let-observer>
  <div class="parse-link__prompt">
    <div>
      <h1>{{ 'components.parseLinks.dialogs.redirectToUrlTitle' | translate }}</h1>

      <p>
        {{ 'components.parseLinks.dialogs.redirectToUrlDescription' | translate }}
        <strong>{{ href }}</strong>
      </p>
    </div>

    <div class="actions">
      <button tuiButton type="button" appearance="secondary" size="m" (click)="observer.complete()">
        {{ 'common.buttons.cancel' | translate }}
      </button>

      <button tuiButton type="button" appearance="primary" size="m" (click)="gotoExternalLink(observer)">
        {{ 'common.buttons.goTo' | translate }}
      </button>
    </div>
  </div>
</ng-template>
