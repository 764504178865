import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TuiSizeL } from '@taiga-ui/core';

/**
 * Переключатель с подписью
 * @param {boolean} value - значение переключателя
 * @param {string} label - (опционально) подпись слева от переключателя
 * @param {TuiSizeL} size {@link TuiSizeL} - размер переключателя
 *
 * @see https://www.figma.com/file/tdtoaaGrQa3XOG9Qqv96VI/Unions-(Taiga-3.0)?type=design&node-id=2249-120402&mode=dev
 */
@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO: Научить работать с формами через extends AbstractTuiControl<boolean>
export class ToggleComponent {
  @Input() size: TuiSizeL = 'l';
  @Input() label?: string;
  @Input() value: boolean = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

  onChangeValue(value: boolean) {
    this.valueChange.emit(value);
  }
}
