<div class="group-add">
  <div class="form">
    <tui-avatar
      class="form__photo"
      [autoColor]="!group.photo?.source"
      [rounded]="true"
      size="xl"
      [avatarUrl]="group.photo ? (group.photo.source | safe : 'resourceUrl') : 'assets/images/icon-add-photo.svg'"
      (click)="onClickPhoto($event)"
    >
      <div class="file-input">
        <input #fileInput type="file" accept="image/png, image/jpeg" (change)="fileInputChange($event)" />
      </div>
    </tui-avatar>
    <div>
      <div class="form__name">
        <tui-input
          tuiTextfieldSize="m"
          [tuiTextfieldLabelOutside]="true"
          [(ngModel)]="group.name"
          (ngModelChange)="onChangeData()"
          tuiAutoFocus
        >
          {{
            isChannel
              ? ('components.telegramGroupAdd.fields.channelTitle' | translate)
              : ('components.telegramGroupAdd.fields.groupTitle' | translate)
          }}
        </tui-input>
      </div>
      <div class="form__description">
        <tui-input
          tuiTextfieldSize="m"
          [tuiTextfieldLabelOutside]="true"
          [(ngModel)]="group.description"
          (ngModelChange)="onChangeData()"
        >
          {{ 'components.telegramGroupAdd.fields.description' | translate }}
        </tui-input>
      </div>
    </div>
  </div>
  <div class="members">
    <app-search-users [multiple]="true" (changeMembers)="onChangeMembers($event)">
      {{ 'components.groupAdd.fields.addMembers' | translate }}
    </app-search-users>
  </div>
</div>
