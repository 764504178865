<form *ngIf="!!users" class="users-table-edit" [formGroup]="form">
  <div class="users-table-edit__header">
    {{ 'components.usersTableEdit.labels.headerUsers' | translate }}
    <span class="users-table-edit__count">{{ users.length }}</span>
  </div>

  <tui-scrollbar *ngIf="users.length" class="users-table-edit__body">
    <table tuiTable class="table" [columns]="columns" [size]="'s'">
      <thead>
        <tr tuiThGroup>
          <th *tuiHead="'user'" tuiTh [sorter]="null">
            {{ 'components.usersTableEdit.labels.tableHeaderUser' | translate }}
          </th>
          <th *tuiHead="'role'" tuiTh [sorter]="null">
            {{ 'components.usersTableEdit.labels.tableHeaderRole' | translate }}
          </th>
          <th *tuiHead="'comment'" tuiTh [sorter]="null">
            {{ 'components.usersTableEdit.labels.tableHeaderComment' | translate }}
          </th>
        </tr>
      </thead>
      <tbody tuiTbody formArrayName="participants">
        <tr *ngFor="let participant of participants.controls; let uIndex = index" tuiTr>
          <ng-container [formGroupName]="uIndex">
            <td *tuiCell="'user'" tuiTd>
              <cell-user [user]="getUser(participant.get('userId')?.value)" [statusVisible]="true"></cell-user>
            </td>

            <td *tuiCell="'role'" tuiTd>
              <tui-combo-box
                class="role"
                formControlName="participantRoleId"
                [tuiTextfieldSize]="'s'"
                [stringify]="stringify"
                [tuiTextfieldLabelOutside]="true"
                [(search)]="queryString[uIndex]"
                (searchChange)="onSearchChange($event)"
              >
                {{ 'components.usersTableEdit.fields.userRole' | translate }}
                <input
                  tuiTextfield
                  [placeholder]="'components.usersTableEdit.fields.userRole' | translate"
                  [maxLength]="100"
                />

                <ng-template tuiDataList>
                  <tui-data-list *ngIf="items$ | async as items; else loading" [emptyContent]="emptyContent">
                    <tui-opt-group>
                      <button *ngFor="let item of items" tuiOption size="m" [value]="item.id">
                        {{ item.name }}
                      </button>
                    </tui-opt-group>

                    <ng-container
                      *ngIf="!!queryString[uIndex] && items.length"
                      [ngTemplateOutlet]="createButton"
                    ></ng-container>
                  </tui-data-list>

                  <ng-template #loading>
                    <tui-loader class="tui-space_vertical-4"></tui-loader>
                  </ng-template>

                  <ng-template #emptyContent>
                    <span *ngIf="!queryString[uIndex]; else createButton">
                      {{ 'components.usersTableEdit.labels.emptyContentRole' | translate }}
                    </span>
                  </ng-template>

                  <ng-template #createButton>
                    <tui-opt-group
                      [label]="
                        'components.usersTableEdit.labels.createRole' | translate : { value: queryString[uIndex] }
                      "
                    >
                      <button
                        tuiButton
                        appearance="primary"
                        size="s"
                        [disabled]="!queryString[uIndex]"
                        (click)="
                          !!queryString[uIndex]
                            ? onCreate(queryString[uIndex]!, participant?.get('participantRoleId'))
                            : ''
                        "
                      >
                        {{ 'components.usersTableEdit.buttons.createRole' | translate }}
                      </button>
                    </tui-opt-group>
                  </ng-template>
                </ng-template>
              </tui-combo-box>
            </td>

            <td *tuiCell="'comment'" tuiTd>
              <tui-textarea
                class="comment"
                formControlName="participantComment"
                [rows]="7"
                [maxLength]="150"
                [expandable]="true"
                [tuiTextfieldSize]="'s'"
              >
                {{ 'components.usersTableEdit.fields.yourText' | translate }}
              </tui-textarea>
              <tui-error
                formControlName="participantComment"
                [error]="['maxLength'] | tuiFieldError | async"
              ></tui-error>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </tui-scrollbar>

  <div class="users-table-edit__footer">
    <button tuiButton class="button" type="button" appearance="primary" [size]="'m'" (click)="onClickEditButton()">
      {{
        users.length
          ? ('components.usersTableEdit.buttons.editUsersList' | translate)
          : ('components.usersTableEdit.buttons.addUsers' | translate)
      }}
    </button>
  </div>
</form>
