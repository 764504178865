<div class="enter-by-code">
  <button
    *ngIf="step !== 'phone'"
    tuiButton
    class="button-return"
    type="button"
    appearance="link"
    icon="tuiIconChevronLeftLarge"
    size="s"
    [title]="'common.buttons.return' | translate"
    (click)="onClickEditButton()"
  >
    {{ 'common.buttons.return' | translate }}
  </button>

  <ng-container *ngIf="isBrand; else unionsLogo">
    <div class="enter-by-code__logo logo">
      <app-branding-logo class="logo__image"></app-branding-logo>
    </div>
  </ng-container>

  <ng-template #unionsLogo>
    <div class="enter-by-code__logo logo">
      <app-branding-logo class="logo__image" [width]="40" [height]="40"></app-branding-logo>
      <span class="logo__label">Unions</span>
    </div>
  </ng-template>

  <div class="enter-by-code__form">
    <!--Шаг ввода номера телефона-->
    <ng-container *ngIf="step === 'phone'">
      <div class="enter-by-code__step-title-wrapper">
        <div class="enter-by-code__title">
          {{ title || ('components.enterByCode.labels.enterPhoneNumber' | translate) }}
        </div>
      </div>

      <div>
        {{ 'components.enterByCode.labels.phoneNumber' | translate }}
        <app-input-phone
          size="l"
          [(ngModel)]="phoneNumber"
          [disabled]="loading"
          [pseudoInvalid]="phoneInvalid"
          [labelOutside]="true"
          [autofocus]="true"
          (ngModelChange)="onPhoneNumberChange($event)"
        >
          {{ 'components.enterByCode.labels.phoneNumber' | translate }}
        </app-input-phone>
      </div>

      <tui-loader [showLoader]="loading" [inheritColor]="true" [overlay]="true">
        <button tuiButton size="l" type="button" appearance="primary" class="button-next" (click)="validateCaptcha()">
          {{ 'common.buttons.next' | translate }}
        </button>
      </tui-loader>

      <ng-content></ng-content>
    </ng-container>

    <!--Шаг ввода кода полученного на почту-->
    <ng-container *ngIf="step === 'code'">
      <ng-container [ngSwitch]="responseServiceId">
        <ng-container *ngSwitchCase="'email'">
          <div class="enter-by-code__title tui-space_bottom-6">
            {{ 'components.enterByCode.labels.enterCode' | translate }}
          </div>
          <div class="enter-by-code__description">
            {{ ('components.enterByCode.labels.emailText' | translate) + ' ' + maskedEmail }}
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'telegram'">
          <div class="enter-by-code__title">{{ 'components.enterByCode.labels.enterCode' | translate }}</div>
          <div class="enter-by-code__description">{{ 'components.enterByCode.labels.telegramText' | translate }}</div>
        </ng-container>

        <ng-container *ngSwitchCase="'phoneCall'">
          <div class="enter-by-code__title tui-space_bottom-4">
            {{ 'components.enterByCode.labels.receiveCall' | translate }}
          </div>
          <div class="enter-by-code__description">
            {{ 'components.enterByCode.labels.phoneCallTextPart1' | translate }}
            <b>{{ 'components.enterByCode.labels.phoneCallTextPart2' | translate }}</b>
            {{ 'components.enterByCode.labels.phoneCallTextPart3' | translate }}
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'sms'">
          <div class="enter-by-code__title tui-space_bottom-4">
            {{ 'components.enterByCode.labels.enterSmsCode' | translate }}
          </div>
          <div class="enter-by-code__description">
            {{ ('components.enterByCode.labels.smsText' | translate) + ' ' + phoneNumber | formatPhone }}
          </div>
        </ng-container>
      </ng-container>

      <app-input-code
        [codeLength]="4"
        [(code)]="code"
        [(codeInvalid)]="codeInvalid"
        (codeIsReady)="sendCode()"
      ></app-input-code>

      <tui-loader [showLoader]="loading" [inheritColor]="true" [overlay]="true">
        <button tuiButton size="l" type="button" appearance="primary" class="button-next" (click)="sendCode()">
          {{ 'common.buttons.next' | translate }}
        </button>
      </tui-loader>

      <div class="resend-code">
        <ng-container *ngIf="isCountdownStarted; else resendCodeTmp">
          <div class="resend-code__countdown">
            {{ 'components.enterByCode.labels.countdownText' | translate }}
            {{
              countdownValue
                | numeralPlurals
                  : [
                      'components.enterByCode.labels.secondOne' | translate,
                      'components.enterByCode.labels.secondFew' | translate,
                      'components.enterByCode.labels.secondMany' | translate
                    ]
            }}
          </div>
        </ng-container>

        <ng-template #resendCodeTmp>
          <a tuiLink class="resend-code__button" (click)="sendPhone()">
            {{ 'components.enterByCode.buttons.resendCode' | translate }}
          </a>
          <a tuiLink class="resend-code__button" (click)="codeHintVisible = true">
            {{ 'components.enterByCode.buttons.showCodeHint' | translate }}
          </a>
        </ng-template>
      </div>

      <div *ngIf="codeHintVisible" class="code-hint">
        <button
          tuiIconButton
          class="code-hint__close"
          type="button"
          icon="tuiIconCloseLarge"
          appearance="icon"
          size="s"
          [title]="'common.buttons.delete' | translate"
          (click)="codeHintVisible = false"
        ></button>

        <div class="code-hint__message">
          {{ 'components.enterByCode.labels.codeHint' | translate }}
        </div>
      </div>
    </ng-container>
  </div>
</div>

<app-captcha></app-captcha>
