<div *ngIf="mode === 'view'" class="field field_size_{{ size ? size : 'l' }}">
  <div class="field__name" [class.display-none]="!name" [title]="name">{{ name }} &nbsp;</div>
  <div class="field__value" [class.no-underline]="noUnderline">
    <a *ngIf="user; else empty" [routerLink]="'/association-users/' + user.id">{{ user.fullName }}</a>
  </div>
</div>

<div *ngIf="mode === 'preview'">
  <a
    *ngIf="user; else empty"
    tuiLink
    icon="tuiIconMail"
    iconAlign="right"
    [routerLink]="'/association-users/' + user.id"
    [pseudo]="true"
  >
    {{ user.fullName }}
  </a>
</div>

<ng-template #empty>
  <div class="tui-skeleton tui-skeleton_text empty">&nbsp;</div>
</ng-template>
