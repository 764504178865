import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { getInnerRouteDefinitionFromLink, RouteDefinition } from '@src/utils';

import { MAX_URL_LENGTH, MAX_URL_TAIL_LENGTH } from './constants';

type UrlType = 'external' | 'internal';

@Component({
  selector: 'a[app-render-link]',
  templateUrl: './render-link.component.html',
  styleUrls: ['./render-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderLinkComponent implements OnInit {
  @Input() set url(value: string) {
    this.originUrl = value;

    this.routeDefinition = getInnerRouteDefinitionFromLink(value);
  }

  @Input() displayableText = '';

  private routeDefinition: RouteDefinition | null = null;
  private originUrl = '';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}

  get text() {
    if (this.routeDefinition === null) {
      return this.displayableText;
    }

    return this.routeDefinition.title;
  }

  get href() {
    return this.originUrl;
  }

  get commands() {
    return this.routeDefinition?.commands;
  }

  get urlType(): UrlType {
    return this.routeDefinition === null ? 'external' : 'internal';
  }

  get normalizedDisplayableText() {
    if (this.displayableText.length > MAX_URL_LENGTH) {
      return (
        this.displayableText.substring(0, MAX_URL_LENGTH - MAX_URL_TAIL_LENGTH) +
        '...' +
        this.displayableText.slice(this.displayableText.length - MAX_URL_TAIL_LENGTH)
      );
    }

    return this.displayableText;
  }
}
