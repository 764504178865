import { TuiControlValueTransformer, TuiDay, TuiTime } from '@taiga-ui/cdk';

export class TuiDayTimeTransformer
  implements TuiControlValueTransformer<[TuiDay | null, TuiTime | null], Date | undefined>
{
  fromControlValue(controlValue?: Date): [TuiDay | null, TuiTime | null] {
    if (!controlValue) {
      return [null, null];
    }

    const date = new Date(
      controlValue.getFullYear(),
      controlValue.getMonth(),
      controlValue.getDate(),
      controlValue.getHours(),
      controlValue.getMinutes(),
      controlValue.getSeconds(),
    );

    return [
      new TuiDay(date.getFullYear(), date.getMonth(), date.getDate()),
      new TuiTime(date.getHours(), date.getMinutes(), date.getSeconds()),
    ];
  }

  toControlValue([day, time]: [TuiDay | null, TuiTime | null]): Date | undefined {
    if (!day) return;

    if (!time) {
      time = new TuiTime(0, 0);
    }

    return new Date(day.year, day.month, day.day, time.hours, time.minutes);
  }

  static dateToTuiDay(value: Date): TuiDay {
    const date = new Date(value.getFullYear(), value.getMonth(), value.getDate());

    return new TuiDay(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static tuiDayToDate(value: TuiDay): Date {
    return new Date(value.year, value.month, value.day, 0, 0);
  }
}
