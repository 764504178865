import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { EnvService } from '@src/app/modules/env';
import { Nullable, Optional } from '@src/types/utils';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Input() href: Optional<Nullable<string>> = '';
  @Input() displayText = '';

  constructor(private readonly env: EnvService) {}

  openLink(url: string) {
    this.env.openLink(url);
  }
}
