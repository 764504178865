/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactLoyaltyView } from './contactLoyaltyView';
import { DiscountGroupLoyaltyView } from './discountGroupLoyaltyView';
import { CategoryView } from './categoryView';
import { RegionView } from './regionView';
import { PhotoDataLoyaltyView } from './photoDataLoyaltyView';


/**
 * Просмотреть продукт программы лояльности – товар / услуга
 */
export interface ViewLoyaltyProduct { 
    /**
     * Наименование продукта лояльности.
     */
    name: string;
    /**
     * Описание продукта лояльности.
     */
    description: string;
    /**
     * ID организации продукта.
     */
    organisationId?: string;
    /**
     * ID ассоциации.
     */
    parentOrganisationId?: string;
    /**
     * Информация о компании.
     */
    organisationInfo?: string;
    /**
     * Спимок групп получателей скидки.
     */
    discountGroups?: Array<DiscountGroupLoyaltyView>;
    /**
     * Список контактных лиц.
     */
    contacts?: Array<ContactLoyaltyView>;
    /**
     * Список фоттографий.
     */
    photos?: Array<PhotoDataLoyaltyView>;
    /**
     * ответственный сотрудник из объединения
     */
    contactUserId?: string;
    /**
     * идентификатор-признак ассоциации из сторонней БД
     */
    unionId?: string;
    externalAuthorId?: string;
    externalOrganisationId?: string;
    externalOrganisationName?: string;
    externalOrganisationLogoId?: string;
    /**
     * ID записи.
     */
    id: string;
    /**
     * Дата публикации продукта.
     */
    datePublished?: string;
    /**
     * вычисляемый рейтинг продукта: Непримечательное, Популярное, Заметное, Бестселлер
     */
    rating: number;
    /**
     * Статус продукта.
     */
    status: number;
    /**
     * Краткое наименование организации.
     */
    organisationShortName?: string;
    /**
     * Полное наименование организации.
     */
    organisationFullName?: string;
    /**
     * ID обложки организации.
     */
    organisationLogoId?: string;
    /**
     * ID группы категории.
     */
    categoryGroupId?: string;
    /**
     * Наименование группы категории.
     */
    categoryGroupName?: string;
    /**
     * ID категории.              УСТАРЕВШИЙ! оставлен для совместимости.
     */
    categoryId?: string;
    /**
     * Наименование категории.
     */
    categoryName?: string;
    /**
     * ID региона.              УСТАРЕВШИЙ! оставлен для совместимости.
     */
    regionId?: string;
    /**
     * Наименование региона.
     */
    regionName?: string;
    /**
     * Список ID категорий.
     */
    categories: Array<CategoryView>;
    /**
     * Список ID регионов.
     */
    regions?: Array<RegionView>;
}

