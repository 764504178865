import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Inject,
  ViewChild,
} from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { lastValueFrom, Observer, Subject } from 'rxjs';
import { isEqual } from 'lodash-es';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { UserUnion } from '@airgram/web';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { UsersSearchParameters } from '@src/api';
import { BreakpointObserverHelperService, SearchService, UserService } from '@src/core/services';
import { ScreenTypes, UserUI } from '@src/models';
import { getImageSrc, sortingUsers, getFilter } from '@src/utils';

import { FieldsOptions } from '../filter';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchUsersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() multiple?: boolean = false;
  @Input() headerVisible?: boolean = false;
  @Input() value?: number | string | (number | string)[];
  @Input() filter: UsersSearchParameters;
  @Input() excludeFields: FieldsOptions[] = [];
  @Input() excludeIds?: string[] = [];
  @Input() excludeTelegramIds?: number[] = [];
  @Input() excludeUsers?: UserUI[] | null = [];
  @Input() disabled: boolean = false;
  @Input() size?: TuiSizeS | TuiSizeL;
  @Output() changeMembers: EventEmitter<UserUI[] | null | undefined>;
  @ViewChild('filterDialogTemplate') filterDialogTemplate?: PolymorpheusContent<TuiDialogContext>;
  @ViewChild(MatSelectionList) matSelectionList?: MatSelectionList;

  members?: UserUI[] | null = [];
  users?: UserUI[] = [];
  selectedUsers: UserUI[] = [];
  selectAll: boolean = false;
  loadingSelected: boolean = false;

  jobTitleIds?: string[];
  committeeIds?: string[];
  organisationIds?: string[];
  organisationTypeIds?: string[];

  dropdownContentVisible: boolean = false;

  private readonly defaultFilter: UsersSearchParameters = {
    jobTitleIds: [],
    committeeIds: [],
    organisationIds: [],
    organisationTypeIds: [],
    name: '',
    searchForExactMatchName: false,
    searchForRegistredOnly: undefined,
  };
  private screenType: ScreenTypes = 'extra-large';
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    private readonly searchService: SearchService<UserService, UsersSearchParameters, UserUI>,
  ) {
    this.changeMembers = new EventEmitter();
    this.filter = this.defaultFilter;
  }

  get screenTypesLargeSet(): ScreenTypes[] {
    return this.breakpointObserverHelperService.getScreenTypesBiggerThanTarget('large');
  }

  get tuiElementLargeSize(): TuiSizeL {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'l' : 'm';
  }

  get tuiElementMediumSize(): TuiSizeS {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'm' : 's';
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(this.breakpointObserverHelperService.breakpointsSet)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe((state: BreakpointState) => {
        this.screenType = this.breakpointObserverHelperService.getScreenType(state);
        this.cdr.markForCheck();
      });

    this.userService
      .getAllContactsForUserDescriptionsForOrganisations()
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(contactsForUserNamesList => {
        this.filter.contacts = contactsForUserNamesList.map(name => {
          return {
            name: name,
            value: '',
          };
        });
        this.cdr.markForCheck();
      });

    this.startSearch();

    this.searchService
      .searchFilter(this.userService)
      .pipe(takeUntil(this.destroyed$$))
      .pipe(
        map(contacts => {
          const users: UserUI[] = [];
          (contacts || [])
            .filter(contact => !this.excludeIds || (contact.id && !this.excludeIds.includes(contact.id)))
            .filter(
              contact =>
                !this.excludeTelegramIds ||
                !contact.telegramId ||
                (contact.telegramId && !this.excludeTelegramIds.includes(contact.telegramId)),
            )
            .filter(
              contact =>
                !this.excludeUsers || (contact.id && !this.excludeUsers.map(user => user.id).includes(contact.id)),
            )
            .forEach(async (contact: UserUI) => {
              if (contact.photoId) {
                contact.photoPath = getImageSrc(contact.photoId);
              }

              users.push(contact);

              setTimeout(() => {
                this.selectOption(contact);
                this.setSelectAllCheckbox();
              }, 0);
            });

          return users;
        }),
      )
      .subscribe(users => {
        this.users = users;
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      if (this.value) {
        this.loadingSelected = true;
        const membersIds = Array.isArray(this.value) ? this.value : [this.value];
        lastValueFrom(this.userService.getUsersData(membersIds)).then(users => {
          this.members = isEqual(users, [{}]) ? [] : sortingUsers(users); // TODO: refactoring
          this.loadingSelected = false;
          this.cdr.markForCheck();
        });
      } else {
        this.members = [];
      }
    }

    if (changes.filter) {
      if (this.filter) {
        this.filter = {
          ...this.defaultFilter,
          ...this.filter,
        };
      } else {
        this.filter = this.defaultFilter;
      }
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  readonly stringify: TuiStringHandler<UserUnion | TuiContextWithImplicit<UserUnion>> = item => {
    const lastName = 'lastName' in item ? item.lastName : item.$implicit.lastName;
    const firstName = 'firstName' in item ? item.firstName : item.$implicit.firstName;
    return [lastName, firstName].join(' ');
  };

  onClickField(): void {
    this.dropdownContentVisible = !this.dropdownContentVisible;
    if (this.dropdownContentVisible) {
      this.startSearch();
    }
    this.cdr.markForCheck();
  }

  onActiveZone(value: boolean): void {
    if (!value) {
      this.dropdownContentVisible = false;
    }
    this.cdr.markForCheck();
  }

  onClickSelectAll(value: boolean, usersList: MatSelectionList): void {
    this.selectAll = value;
    if (value) usersList.selectAll();
    else usersList.deselectAll();

    if (this.users) this.setMembers(value, this.users);
  }

  onClickFilterButton(): void {
    if (this.filterDialogTemplate) {
      this.dialogService
        .open(this.filterDialogTemplate, {
          label: this.translateService.instant('common.dialogs.filterHeader'),
          size: this.tuiElementMediumSize,
          closeable: false,
          dismissible: false,
        })
        .subscribe();
    }
  }

  getIndeterminate(value?: any[] | null): boolean {
    if (!value) {
      return false;
    }
    return value.length > 0 && !this.selectAll;
  }

  onChangeMembers(members?: UserUI[] | null): void {
    this.changeMembers.emit(members);
    if (!members) return;

    this.selectedUsers = this.selectedUsers.filter(
      selectedUser => members.findIndex(member => member.id === selectedUser.id) > -1,
    );
    this.setSelectAllCheckbox();
  }

  onChangeSelectedUsers(selectionList: MatSelectionListChange): void {
    const selectionOption = selectionList.options[0];
    const selectionValue: UserUI = selectionOption.value;

    this.setMembers(selectionOption.selected, [selectionValue]);
    if (this.multiple) {
      this.setSelectAllCheckbox();
    }
  }

  setMembers(selected: boolean, users: UserUI[]): void {
    if (!this.members) this.members = [];

    users.forEach(user => {
      if (!this.members) return;

      if (selected) {
        if (this.members.findIndex(member => member.id === user.id) === -1) {
          if (this.multiple) {
            this.members = this.members.concat(user);
          } else {
            this.members = [user];
          }
        }
      } else if (this.members.findIndex(member => member.id === user.id) > -1) {
        this.members = this.members.filter(member => member.id !== user.id);
      }
    });

    this.members = sortingUsers(this.members);

    this.changeMembers.emit(this.members);
  }

  applyFilter(observer: Observer<void>): void {
    observer.complete();
    this.startSearch();
    this.dropdownContentVisible = true;
    this.cdr.markForCheck();
  }

  cancelFilter(observer: Observer<void>): void {
    observer.complete();
    this.dropdownContentVisible = true;
    this.cdr.markForCheck();
  }

  startSearch(): void {
    this.searchService.startSearch(this.userService, getFilter(this.filter));
  }

  private selectOption(userProfile: UserUI): void {
    if (this.matSelectionList && this.members && this.members.findIndex(member => member.id === userProfile.id) > -1) {
      const findOption = this.matSelectionList.options.find(option => (option.value as UserUI).id === userProfile.id);
      if (findOption) findOption.selected = true;
    }
  }

  private setSelectAllCheckbox(): void {
    this.selectAll = this.users?.length === this.selectedUsers?.length;
  }
}
