import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { EnvService } from '@src/app/modules/env';
import { DocumentService } from '@src/core/services';

/**
 * Компонент для отображения ссылки
 * @param {string} fileLink - ссылка на файл
 * @param {string} fileId - id файла
 *
 * @description Использовать нужно один из параметров. Подпись передаётся в контенте
 */
@Component({
  selector: 'app-download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadLinkComponent {
  @Input() fileLink?: string;
  @Input() isLocalFile = true;
  @Input() fileId?: string;

  constructor(private readonly env: EnvService, private readonly documentService: DocumentService) {}

  openLink() {
    if (this.fileId) {
      this.documentService.downloadDocumentById(this.fileId);
    }

    if (this.fileLink) {
      this.env.openLink(this.fileLink, this.isLocalFile);
    }
  }
}
