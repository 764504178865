import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * Компонент для отображения лейбла над полями
 * @param {string} label - Отображаемое значение
 *
 * @description Оборачиваем поле в этот модуль и передаем параметр {@link label}
 */
@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
  @Input() label: string = '';
}
