import { EventEmitter, Output } from '@angular/core';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserUI } from '@src/models';
import { APP_CONFIG } from '@src/core';

export interface AddGroupModel {
  name: string;
  photo?: any;
  description?: string;
  members?: UserUI[] | null;
}

@Component({
  selector: 'telegram-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.scss'],
})
export class GroupAddComponent {
  @Input() isChannel?: boolean;
  @Output() changeData: EventEmitter<AddGroupModel>;
  @ViewChild('fileInput') fileInput?: ElementRef;

  group: AddGroupModel;

  constructor(public dialog: MatDialog) {
    this.group = {
      name: '',
      description: '',
      photo: '',
      members: [],
    };
    this.changeData = new EventEmitter();
  }

  onClickPhoto(e: any) {
    e.stopPropagation();

    if (!this.fileInput) return;

    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  fileInputChange(e: Event) {
    const fileList = (e.target as HTMLInputElement).files as FileList;

    if (fileList.length) {
      const fileTemp = fileList.item(0);

      if (fileTemp) {
        if (fileTemp.size > APP_CONFIG.fileSizeMax.image) {
          // TODO: notify('Обнаружены файлы с превышением допустимого размера (4мб). Они были исключены из загрузки', 'error', 5000);
          return;
        }

        this.group.photo = fileTemp;
        this.group.photo.source = URL.createObjectURL(fileTemp);
      }
    }

    this.onChangeData();
  }

  onChangeMembers(members?: UserUI[] | null) {
    this.group.members = members;
    this.onChangeData();
  }

  onChangeData() {
    this.changeData.emit(this.group);
  }
}
