import { Component, ChangeDetectionStrategy, Input, Inject, Output, EventEmitter } from '@angular/core';
import { TuiDialogContext, TuiDialogService, TuiSizeS } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-preview-button',
  templateUrl: './preview-button.component.html',
  styleUrls: ['./preview-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewButtonComponent {
  @Input() contextTemplate?: PolymorpheusContent<TuiDialogContext>;
  @Input() size: TuiSizeS = 'm';
  @Output() openChanges: EventEmitter<boolean> = new EventEmitter();

  constructor(@Inject(TuiDialogService) private readonly dialogService: TuiDialogService) {}

  showPreview(click: MouseEvent) {
    click.stopPropagation();

    if (this.contextTemplate) {
      this.dialogService
        .open(this.contextTemplate, {
          closeable: true,
          dismissible: true,
        })
        .subscribe();
    }
  }
}
