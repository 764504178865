import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiLinkModule } from '@taiga-ui/core';
import { NgxPermissionsModule, NgxPermissionsRestrictStubModule } from 'ngx-permissions';
import { SelectItemsModule } from '@src/app/shared/select-items';
import { TranslateModule } from '@ngx-translate/core';

import { BusinessTypesComponent } from './components/business-types/business-types.component';
import { BusinessTypesDialogComponent } from './components/business-types-dialog/business-types-dialog.component';
import { JobTitlesComponent } from './components/job-titles/job-titles.component';
import { JobTitlesDialogComponent } from './components/job-titles-dialog/job-titles-dialog.component';
import { WithDictionaryButtonWrapperComponent } from './components/with-dictionary-button-wrapper/with-dictionary-button-wrapper.component';

@NgModule({
  declarations: [
    BusinessTypesComponent,
    BusinessTypesDialogComponent,
    JobTitlesComponent,
    JobTitlesDialogComponent,
    WithDictionaryButtonWrapperComponent,
  ],
  imports: [
    CommonModule,
    SelectItemsModule,
    TuiLinkModule,
    NgxPermissionsModule,
    NgxPermissionsRestrictStubModule,
    TranslateModule,
  ],
  exports: [BusinessTypesComponent, JobTitlesComponent, WithDictionaryButtonWrapperComponent],
})
export class DictionariesModule {}
