import { Injectable } from '@angular/core';
import mime from 'mime';
import { TranslateService } from '@ngx-translate/core';
import { logger } from '@src/utils';
import { AlertService } from '@src/core/services';
import { AndroidPermissions, ICordovaPluginAndroidPermissions } from '@src/models';
import { APP_CONFIG } from '@src/core';

let self: any;

interface EventForCalendar {
  title?: string;
  eventLocation?: string;
  notes?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  onlineLink?: string;
}

type GetUpdateAvailabilityResult =
  | 'UPDATE_AVAILABLE' // Когда этот метод возвращает значение UPDATE_AVAILABLE, ваше приложение готово использовать следующие методы для запроса обновления у пользователя.
  | 'UPDATE_NOT_AVAILABLE'
  | 'DEVELOPER_TRIGGERED_UPDATE_IN_PROGRESS'
  | 'UNKNOWN';

type UpdateFlexibleResult =
  | 'UPDATE_NOT_AVAILABLE' // В Play Store нет доступных обновлений.
  | 'DEVELOPER_TRIGGERED_UPDATE_IN_PROGRESS' // Выполняется либо гибкое, либо немедленное обновление.
  | 'UPDATE_PROMPT' // Пользователю будет предложено открыть диалоговое окно Play Store, чтобы загрузить гибкое обновление или проигнорировать его.
  | 'RESULT_OK' // Пользователь согласился загрузить гибкое обновление.
  | 'RESULT_CANCELED' // Пользователь отклонил диалоговое окно гибкого обновления или обновление было прервано во время выполнения.
  | 'RESULT_IN_APP_UPDATE_FAILED' // Что-то пошло не так с ответом в диалоговом окне обновления.
  | 'ACTIVITY_RESULT_UNKNOWN' // Неизвестный код результата, возвращаемый диалоговым окном.
  | 'DOWNLOADING' // В данный момент в фоновом режиме загружается обновление.
  | 'DOWNLOADED'; // Обновление было загружено, и на экране появилась панель быстрого доступа с кнопкой перезапуска.

@Injectable({
  providedIn: 'root',
})
export class CordovaService {
  cordova: any;
  isCordova?: boolean;
  permissions?: ICordovaPluginAndroidPermissions;
  requiredPermissions: AndroidPermissions[] = [
    AndroidPermissions.WRITE_EXTERNAL_STORAGE,
    AndroidPermissions.READ_EXTERNAL_STORAGE,
    AndroidPermissions.RECORD_AUDIO,
    AndroidPermissions.MODIFY_AUDIO_SETTINGS,
    AndroidPermissions.CAMERA,
    AndroidPermissions.READ_CALENDAR,
    AndroidPermissions.WRITE_CALENDAR,
  ];
  fileOpener2: any;
  calendar: any;
  inAppUpdate: any;

  constructor(private alertService: AlertService, private readonly translateService: TranslateService) {
    this.init();
  }

  async init() {
    self = this;
    this.cordova = (window as any).cordova;
    this.isCordova = this.cordova !== undefined;

    this.permissions = this.cordova?.plugins?.permissions;
    if (this.permissions) {
      this.checkRequiredPermissions();
    }

    this.fileOpener2 = this.cordova?.plugins.fileOpener2;
    this.calendar = (window as any).plugins?.calendar;

    this.inAppUpdate = this.cordova?.plugins.InAppUpdate;
    if (this.inAppUpdate) {
      this.checkUpdate();
    }
  }

  checkUpdate() {
    this.inAppUpdate.getUpdateAvailability(
      (result: GetUpdateAvailabilityResult) => {
        console.log('++ getUpdateAvailability result', result);

        if (result === 'UPDATE_AVAILABLE') {
          this.inAppUpdate.updateFlexible(
            (result: UpdateFlexibleResult) => {
              console.log('++ updateFlexible result', result);
            },
            (error: string) => {
              console.log('++ updateFlexible error', error);
            },
          );
        }
      },
      (error: string) => {
        console.log('++ getUpdateAvailability error', error);
      },
    );
  }

  checkRequiredPermissions() {
    if (!this.permissions) return;

    this.permissions.requestPermissions(
      this.requiredPermissions,
      res => {
        logger('checkRequiredPermissions', res);
      },
      err => {
        logger('checkRequiredPermissions', err);
      },
    );
  }

  checkPermission(permission: AndroidPermissions): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.permissions) return resolve(false);

      this.permissions.checkPermission(
        permission,
        res => {
          return resolve(res.hasPermission ?? false);
        },
        err => {
          logger('checkPermission', err);
          return resolve(false);
        },
      );
    });
  }

  requestPermission(permission: AndroidPermissions): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.permissions) return resolve(false);

      this.permissions.requestPermission(
        permission,
        res => {
          return resolve(res.hasPermission ?? false);
        },
        err => {
          logger('requestPermission', err);
          return resolve(false);
        },
      );
    });
  }

  downloadFile(blob: Blob | string, fileName: string) {
    if (!blob || !fileName) return;

    // Описание всех папок для Android и iOS https://github.com/apache/cordova-plugin-file#file-system-layouts
    const fileDir = self.cordova.file.dataDirectory;

    (window as any).resolveLocalFileSystemURL(
      fileDir,
      function (fs: any) {
        logger(`$ file system opened directory ${fileDir}`);
        logger(`$ opening ${fileName}`);
        fs.getFile(
          fileName,
          { create: true, exclusive: false },
          function (fileEntry: any) {
            logger('getFile - ok!');
            self.writeFile(fileEntry, blob);
          },
          function (err: any) {
            logger('getFile - error', err);
          },
        );
      },
      function (err: any) {
        logger('$ resolveLocalFileSystemURL err', err);
      },
    );
  }

  writeFile(fileEntry: any, blob: Blob | string) {
    const fileName = fileEntry.name;
    const fileMIMEType = mime.getType(fileName);
    logger('fileMIMEType', fileMIMEType);

    fileEntry.createWriter(function (fileWriter: any) {
      fileWriter.onwriteend = function () {
        logger('fileWriter - ok!');
        self.alertService.success(
          `${self.translateService.instant('common.labels.file')} ${fileName} ${self.translateService.instant(
            'common.labels.uploaded',
          )}`,
        );
        self.openFile(fileEntry.nativeURL, fileMIMEType);
      };
      fileWriter.onerror = function (err: any) {
        logger('fileWriter - error', err);
      };
      fileWriter.write(blob);
    });
  }

  openURL(url: string, baseUrl: boolean = false) {
    this.cordova.InAppBrowser.open(baseUrl ? `${APP_CONFIG.baseUrl}/${url}` : url, '_system');
  }

  openFile(filePath: string, fileMIMEType: string) {
    if (!this.fileOpener2) {
      // TODO: localization console перевести
      logger('Ошибка! Плагин fileOpener2 для Cordova не подключен');
      return;
    }

    this.fileOpener2.open(filePath, fileMIMEType, {
      error: function (err: any) {
        logger('Error status: ' + err.status + ' - Error message: ' + err.message);
      },
      success: function () {
        logger('file opened successfully');
      },
    });
  }

  addEventToCalendar({
    title = '',
    eventLocation = '',
    notes = '',
    startDate = null,
    endDate = null,
    onlineLink = undefined,
  }: EventForCalendar) {
    const calOptions = this.calendar.getCalendarOptions();
    if (onlineLink) {
      calOptions.url = onlineLink;
    }

    this.calendar.createEventInteractivelyWithOptions(
      title,
      eventLocation,
      notes,
      startDate,
      endDate,
      calOptions,
      () => {
        this.alertService.success(this.translateService.instant('common.alerts.successes.addEventToCalendar'));
      },
      (error: any) => {
        this.alertService.error(this.translateService.instant('common.alerts.errors.addEventToCalendar'));
        logger(this.translateService.instant('common.alerts.errors.addEventToCalendar'), error);
      },
    );
  }
}
