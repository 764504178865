<div class="policy-page">
  <div class="policy-page__title">{{ 'components.policy.labels.title' | translate }}</div>

  <div class="policy-page__links">
    <!-- Политика конфиденциальности ассоциации -->
    <app-download-link
      *ngIf="privacyPolicyLink"
      class="download-association-link"
      [fileLink]="privacyPolicyLink"
      [isLocalFile]="false"
    >
      {{ 'components.policy.buttons.association' | translate }}
      {{ associationShortName }}
    </app-download-link>

    <!-- Политика конфиденциальности UNIONS -->
    <app-download-link class="download-unions-link" fileLink="assets/docs/unions-policy.pdf">
      {{ 'components.policy.buttons.unions' | translate }}
    </app-download-link>

    <!-- Политика конфиденциальности Яндекс (для SmartCaptcha) -->
    <app-download-link
      *ngIf="enterType === 'by-email' && !isCordova"
      class="download-yandex-link"
      fileLink="https://yandex.ru/legal/smartcaptcha_notice/"
      [isLocalFile]="false"
    >
      {{ 'components.policy.buttons.yandex' | translate }}
    </app-download-link>

    <!-- Согласие на получение рекламы -->
    <app-download-link class="download-advertising-receive" fileLink="assets/docs/Согласие на получение рекламы.pdf">
      {{ 'components.policy.buttons.advertisingReceive' | translate }}
    </app-download-link>
  </div>

  <button tuiButton class="policy-page__button-close" size="m" (click)="closePage()">
    {{ 'common.buttons.close' | translate }}
  </button>
</div>
