import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, Observer, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { BasicGroupFullInfoUnion, ChatMember, ChatMemberUnion, SupergroupFullInfoUnion } from '@airgram/web';
import { TuiDialogContext, TuiDialogService, TuiSizeS } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { TelegramAuthService, TelegramMessengerService } from '@src/app/modules/telegram';
import { GroupUI, ScreenTypes, ViewMode, UserUI } from '@src/models';
import {
  BreakpointObserverHelperService,
  SubscriptionsForUsersService,
  SubscriptionsForUsersCommitteeService,
  UserService,
  PhotoService,
} from '@src/core/services';
import { APP_CONFIG } from '@src/core';

import { GroupInfoService } from './index';

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mode: ViewMode = 'view';
  @Input() type?: 'organisation' | 'committee';
  @Input() data?: GroupUI;
  @Input() isChannel: boolean = false;
  @Input() organisationId?: string;
  @Input() allowSubscribing?: boolean | null = false;
  @Input() allowSpecialFieldsViewing?: boolean | null = false;
  @Output() saved: EventEmitter<GroupUI>;
  @Output() canceled: EventEmitter<void>;
  @Output() deleted: EventEmitter<string>;
  @ViewChild('confirmDialogTemplate') confirmDialogTemplate?: PolymorpheusContent<TuiDialogContext>;

  loading: boolean = false;
  membersLoading: boolean = false;
  allowEditing$?: Observable<boolean | undefined>;
  allowDeleting$?: Observable<boolean | undefined>;
  allowDeletingFromTelegram = false;
  authUser?: UserUI;
  group?: SupergroupFullInfoUnion | BasicGroupFullInfoUnion;
  groupId?: number;
  members?: ChatMemberUnion[];
  memberCount?: number;
  deleteFromTelegram: boolean = false;

  private screenType: ScreenTypes = 'extra-large';
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private breakpointObserverHelperService: BreakpointObserverHelperService,
    private messengerService: TelegramMessengerService,
    private groupInfoService: GroupInfoService,
    private userService: UserService,
    private ngxPermissionsService: NgxPermissionsService,
    private subscriptionsForUsersService: SubscriptionsForUsersService,
    private subscriptionsForUsersCommitteeService: SubscriptionsForUsersCommitteeService,
    private readonly telegramAuthService: TelegramAuthService,
    private readonly translateService: TranslateService,
    private readonly photoService: PhotoService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {
    this.saved = new EventEmitter();
    this.canceled = new EventEmitter();
    this.deleted = new EventEmitter();
  }

  get isLargeScreen(): boolean {
    return this.breakpointObserverHelperService.getScreenTypesBiggerThanTarget('large').includes(this.screenType);
  }

  get tuiElementMediumSize(): TuiSizeS {
    return this.isLargeScreen ? 'm' : 's';
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(this.breakpointObserverHelperService.breakpointsSet)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe((state: BreakpointState) => {
        this.screenType = this.breakpointObserverHelperService.getScreenType(state);
        this.cdr.markForCheck();
      });

    this.telegramAuthService.isLogged$.subscribe(isLogged => {
      if (isLogged) {
        this.allowDeletingFromTelegram = this.userService.authUser?.id === this.data?.ownerId;
        this.loadMembers().finally(() => (this.membersLoading = false));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.groupId = undefined;
      this.group = undefined;
      this.members = undefined;

      if (!!this.telegramAuthService.isLogged$.value) {
        this.loadMembers().finally(() => (this.membersLoading = false));
      }
    }

    if (changes.isChannel) {
      this.data = {
        ...this.data,
        isChannel: this.isChannel,
      };
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.data = {
          isChannel: this.isChannel,
          organisationId: this.organisationId,
        };
      }
    }

    this.allowEditing$ = this.userService.authUser$.pipe(
      map(user => {
        this.authUser = user;

        if (!user || !user.organisationId) return false;

        const permissions = this.ngxPermissionsService.getPermissions();
        const permissionsForOrganisation = this.data?.organisationId
          ? user.permissionsForOrganisations?.[this.data?.organisationId]
          : [];

        return (
          ((this.data && user.id === this.data.ownerId) || this.mode === 'create') &&
          ('organisationChannelEditing' in permissions ||
            permissionsForOrganisation?.includes('onlyYourOrganisationChannelEditing'))
        );
      }),
    );

    this.allowDeleting$ = this.userService.authUser$.pipe(
      map(user => {
        this.authUser = user;

        if (!user) return false;

        const permissions = this.ngxPermissionsService.getPermissions();

        return 'organisationChannelEditing' in permissions;
      }),
    );

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async loadMembers() {
    const chatId = this.data?.chatId;

    if (this.membersLoading || !chatId || !this.data?.active) {
      return;
    }

    this.membersLoading = true;

    await this.messengerService.getAllChatIds();

    return this.messengerService.api.getChat(chatId).then(chat => {
      if (chat.type._ === 'chatTypeBasicGroup') {
        this.groupId = chat.type.basicGroupId;
        this.isChannel = false;
        this.messengerService.api.getBasicGroupFullInfo(this.groupId).then(basicGroup => {
          this.group = basicGroup;
          this.getMembers(basicGroup, this.groupId, chatId);
        });
      }

      if (chat.type._ === 'chatTypeSupergroup') {
        this.groupId = chat.type.supergroupId;
        this.isChannel = chat.type.isChannel;
        this.messengerService.api.getSupergroupFullInfo(this.groupId).then(superGroup => {
          this.group = superGroup;
          this.memberCount = superGroup.memberCount;
          this.getMembers(superGroup, this.groupId, chatId);
        });
      }
    });
  }

  refreshMembers() {
    this.getMembers(this.group, this.groupId, this.data?.chatId);
  }

  //TODO: Избавиться от параметра chatId. Реализовать метод отмены предыдущего запроса
  async getMembers(group?: SupergroupFullInfoUnion | BasicGroupFullInfoUnion, id?: number, chatId?: number) {
    let members: ChatMember[] = [];

    if (group?._ === 'basicGroupFullInfo' && id) {
      members = group.members;
    }

    if (group?._ === 'supergroupFullInfo' && group.canGetMembers && id) {
      const superGroupMembers = await this.messengerService.api.getSupergroupMembers(id).catch(() => undefined);
      if (!superGroupMembers) {
        this.allowDeletingFromTelegram = false;
        this.membersLoading = false;
        this.cdr.markForCheck();
        return;
      }

      members = superGroupMembers.members;

      const limit = APP_CONFIG.loadingLimit.chatMembers;
      if (group.memberCount > limit) {
        for (let i = 1; i < Math.ceil(group.memberCount / limit); i++) {
          const updatedSupergroupMembers = await this.messengerService.api.getSupergroupMembers(id, limit * i, limit);
          members = [...new Set(members.concat(...updatedSupergroupMembers.members))];
        }
      }
    }

    if (this.data?.chatId === chatId) {
      this.members = members;
    }

    this.membersLoading = false;
    this.cdr.markForCheck();
  }

  async onSaveData(data: GroupUI): Promise<void> {
    if (!data || !data.name) return;
    this.loading = true;

    if (this.mode === 'create') {
      const newGroupData = await this.groupInfoService.createGroup(data);
      if (newGroupData) {
        data = newGroupData;
        this.messengerService.getAllChatIds(true);
      } else {
        this.loading = false;
        this.cdr.markForCheck();
        return;
      }
    } else if (this.mode === 'edit') {
      await this.groupInfoService.editGroup(data);
    }

    this.saved.emit(data);
    this.mode = 'view';
    this.loading = false;
    this.cdr.markForCheck();
  }

  applyDelete(observer: Observer<void>): void {
    observer.complete();

    if (!this.data?.chatId) return;

    if (this.deleteFromTelegram) {
      this.groupInfoService.deleteGroup(this.data!.chatId!).then(res => {
        if (res) {
          this.deleted.emit();
        }
      });
    } else {
      this.deleted.emit();
    }
  }

  onCancel(): void {
    if (this.mode === 'edit') {
      this.mode = 'view';
    }
    this.canceled.emit();
  }

  onStartEditing(): void {
    this.mode = 'edit';
  }

  async onDeletePhoto(): Promise<void> {
    if (this.data?.photoId) {
      this.photoService.deletePhoto(this.data.photoId);
    }

    if (this.data?.chatId) {
      await this.messengerService.api.deleteChatPhoto(this.data.chatId);
    }
  }

  onSubscribe(): void {
    if (!this.data?.id || !this.data.chatId || !this.data?.codeName) return;

    if (this.type === 'organisation') {
      this.subscriptionsForUsersService.subscribeChannel(this.data.id, this.data.chatId, this.data.codeName);
    }

    if (this.type === 'committee') {
      this.subscriptionsForUsersCommitteeService.subscribeGroup(this.data.id, this.data.chatId, this.data.codeName);
    }
  }

  onUnsubscribe(): void {
    if (!this.data?.id || !this.data?.chatId || !this.authUser?.telegramId) return;

    if (this.type === 'organisation') {
      this.subscriptionsForUsersService.unsubscribeChannel(this.data.id, this.data.chatId, this.authUser.telegramId);
    }

    if (this.type === 'committee') {
      this.subscriptionsForUsersCommitteeService.unsubscribeGroup(
        this.data.id,
        this.data.chatId,
        this.authUser.telegramId,
      );
    }
  }

  openConfirmDialog(): void {
    if (!this.confirmDialogTemplate) return;

    this.deleteFromTelegram = false;

    this.dialogService
      .open(this.confirmDialogTemplate, {
        label: this.isChannel
          ? this.translateService.instant('components.groupInfo.dialogs.channelDeleteHeader')
          : this.translateService.instant('components.groupInfo.dialogs.groupDeleteHeader'),
        size: 's',
        closeable: false,
        dismissible: false,
      })
      .subscribe();
  }
}
