<a
  tuiLink
  icon="tuiIconDownload"
  iconAlign="right"
  class="download-link"
  [pseudo]="true"
  routerLink="."
  (click)="openLink()"
>
  <ng-content></ng-content>
</a>
