import { OrganisationView } from '../api';

import { ContactUI } from './contact.model';

export interface OrganisationUpsert extends OrganisationView {
  photo?: File;
  document?: File;
  contacts?: ContactUI[];
  invoice?: File;
  card?: File;
  deleteCard?: string | null;
  deleteInvoice?: string | null;
  businessTypeIDs?: string[] | undefined;
}

export interface OrganisationUI extends OrganisationView {
  businessTypesComplected?: string;
}
