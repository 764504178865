<form [formGroup]="form" class="preferences">

  <div class="preference">
    <div class="preference__field"
         [class.tui-skeleton]="loading || saving"
    >
      {{ 'components.preferences.labels.showUsers' | translate }}
      <tui-toggle
        formControlName="showParentOrgUsersInLists"
        [showIcons]="true"
        [tuiTextfieldSize]="'m'"
      ></tui-toggle>
    </div>
    <div class="preference__description">
      {{ 'components.preferences.fields.showParentOrgUsersInLists' | translate }}
    </div>
  </div>

  <div class="preference">
    <div class="preference__field"
         [class.tui-skeleton]="loading || saving"
    >
      {{ 'components.preferences.labels.showOnlyChannels' | translate }}
      <tui-toggle
        formControlName="showOnlyChannelsInNewsFeed"
        [showIcons]="true"
        [tuiTextfieldSize]="'m'"
      ></tui-toggle>
    </div>
    <div class="preference__description">
      {{ 'components.preferences.fields.showOnlyChannelsInNewsFeed' | translate }}
    </div>
  </div>
</form>
