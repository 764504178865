/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Статистика за месяц.
 */
export interface MonthStatistics { 
    /**
     * Месяц.
     */
    date?: string;
    /**
     * Кол-во записей для выборок activityType = \"read\".
     */
    readCount?: number;
    /**
     * Кол-во записей для выборок activityType = \"discount\".
     */
    discountsUsedCount?: number;
}

