<tui-hosted-dropdown tuiDropdownAlign="right" [content]="moreMenu" [(open)]="open">
  <button
    tuiIconButton
    class="more-button"
    appearance="outline"
    icon="tuiIconMoreVerticalLarge"
    [size]="size"
    (click)="onClickButton($event)"
  ></button>
</tui-hosted-dropdown>

<ng-template #moreMenu>
  <tui-data-list>
    <ng-container *ngFor="let item of items">
      <button *ngIf="item.visible !== false" tuiOption class="more-menu-item" (click)="onClickItem(item)">
        <app-icon *ngIf="item.icon" [src]="item.icon" size="24px" color="var(--tui-link)"></app-icon>
        {{ item.title }}
      </button>
    </ng-container>
  </tui-data-list>
</ng-template>
