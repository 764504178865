import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganisationModule } from '../organisation-page';

import { AssociationComponent } from './association.component';

@NgModule({
  declarations: [AssociationComponent],
  imports: [CommonModule, OrganisationModule],
  exports: [AssociationComponent],
})
export class AssociationModule {}
