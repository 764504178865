import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TuiButtonModule, TuiLinkModule, TuiLoaderModule, TuiScrollbarModule } from '@taiga-ui/core';
import { TuiAvatarModule, TuiBadgedContentModule, TuiBadgeModule } from '@taiga-ui/kit';
import { ComponentsModule } from '@src/app/components';
import { IconModule } from '@src/app/modules/icon';

import { SharedModule } from '../shared.module';

import { ListComponent } from './list.component';
import { ListItemChannelsComponent } from './list-item-channels/list-item-channels.component';
import { ListItemChatsComponent } from './list-item-chats/list-item-chats.component';
import { ListItemCommitteesComponent } from './list-item-committees/list-item-committees.component';
import { ListItemEventsComponent } from './list-item-events/list-item-events.component';
import { ListItemOrganisationsComponent } from './list-item-organisations/list-item-organisations.component';
import { ListItemPollsComponent } from './list-item-polls/list-item-polls.component';
import { ListItemUsersComponent } from './list-item-users/list-item-users.component';

@NgModule({
  declarations: [
    ListComponent,
    ListItemChannelsComponent,
    ListItemChatsComponent,
    ListItemCommitteesComponent,
    ListItemEventsComponent,
    ListItemOrganisationsComponent,
    ListItemPollsComponent,
    ListItemUsersComponent,
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    RouterModule,
    SharedModule,
    TuiAvatarModule,
    TuiBadgeModule,
    TuiBadgedContentModule,
    TuiButtonModule,
    TuiLinkModule,
    TuiLoaderModule,
    TuiScrollbarModule,
  ],
  exports: [ListComponent],
})
export class ListModule {}
