import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiAvatarModule, TuiCheckboxBlockModule, TuiInputModule, TuiTextareaModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiAutoFocusModule } from '@taiga-ui/cdk';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from '@src/app/shared';
import { WaitTelegramChatsModule } from '@src/app/modules/wait-telegram-chats';
import { IconModule } from '@src/app/modules/icon';
import { PhotoModule } from '@src/app/modules/photo';

import { UsersListModule } from '../users-list';
import { ModernSearchUsersModule } from '../search-users';

import { GroupInfoComponent } from './group-info.component';
import { GroupInfoViewComponent } from './group-info-view/group-info-view.component';
import { GroupInfoService } from './group-info.service';

@NgModule({
  declarations: [GroupInfoComponent, GroupInfoViewComponent],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    NgxPermissionsModule,
    PhotoModule,
    ReactiveFormsModule,
    ModernSearchUsersModule,
    SharedModule,
    TuiAutoFocusModule,
    TuiAvatarModule,
    TuiButtonModule,
    TuiCheckboxBlockModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    UsersListModule,
    WaitTelegramChatsModule,
  ],
  exports: [GroupInfoComponent],
  providers: [GroupInfoService],
})
export class GroupInfoModule {}
