import { formatDate } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvService } from '@src/app/modules/env';
import { Language } from '@src/core';
import { DocumentService, UserService } from '@src/core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['../styles/administration-content.scss', './reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsComponent implements OnDestroy {
  reports = [
    {
      id: 1,
      title: this.translateService.instant('components.reports.labels.activeUsersTitle'),
      description: this.translateService.instant('components.reports.labels.activeUsersDescription'),
      action: () => this.getActiveUsersReport(),
    },
  ];

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private documentService: DocumentService,
    private env: EnvService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  private async getActiveUsersReport(): Promise<void> {
    this.userService
      .getActiveUsers(undefined, undefined, this.env.isBot)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(doc => {
        this.downloadFile(doc, `ActiveUsers ${formatDate(new Date(), 'dd.MM.yyyy HH:mm:ss', Language.RU)}.xlsx`);
      });
  }

  private downloadFile(doc: Blob, fileName: string) {
    if (this.env.isBot) {
      this.env.sendDownloadNotification();
    } else {
      this.documentService.downloadDocument(doc, fileName);
    }
  }
}
