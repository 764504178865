import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const EVENT_TYPES = [
  { id: 0, name: marker('common.constants.eventTypes.exhibition') },
  { id: 1, name: marker('common.constants.eventTypes.conference') },
  { id: 2, name: marker('common.constants.eventTypes.seminar') },
  { id: 3, name: marker('common.constants.eventTypes.meeting') },
  { id: 4, name: marker('common.constants.eventTypes.council') },
  { id: 5, name: marker('common.constants.eventTypes.competition') },
  { id: 6, name: marker('common.constants.eventTypes.leisure') },
  { id: 8, name: marker('common.constants.eventTypes.tournament') },
  { id: 7, name: marker('common.constants.eventTypes.other') },
];
