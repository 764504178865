<form class="user-info-edit" [formGroup]="form">
  <div class="user-info-edit__block">
    <app-photo
      class="image"
      [mode]="formDisabled ? 'view' : 'edit'"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [photoId]="data?.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
      (photoChange)="onPhotoChange($event)"
    ></app-photo>

    <tui-input
      tuiAutoFocus
      tabindex="2"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      formControlName="lastName"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.lastName' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.lastName" />
    </tui-input>

    <tui-input
      tabindex="3"
      formControlName="firstName"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.firstName' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.firstName" />
    </tui-input>

    <tui-input
      tabindex="4"
      formControlName="middleName"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.middleName' | translate }}
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.middleName" />
    </tui-input>

    <div class="field">
      <div tuiGroup>
        <tui-input-number
          tabindex="5"
          formControlName="birthDateDay"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [min]="1"
          [max]="31"
        >
          {{ 'components.userInfo.fields.day' | translate }}
        </tui-input-number>

        <tui-select
          tabindex="6"
          formControlName="birthDateMonth"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [valueContent]="months ? monthStringify(months) : ''"
        >
          {{ 'components.userInfo.fields.month' | translate }}
          <tui-data-list *tuiDataList>
            <button *ngFor="let month of months" tuiOption [value]="month.id">
              {{ month.name | translate }}
            </button>
          </tui-data-list>
        </tui-select>

        <tui-input-year
          tabindex="7"
          formControlName="birthDateYear"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldCleaner]="true"
          [min]="1900"
          [max]="2990"
        >
          {{ 'components.userInfo.fields.year' | translate }}
        </tui-input-year>
      </div>
    </div>

    <!-- FIXME: app-input-phone -->
    <app-input-phone
      *ngIf="mode === 'create' && allowSpecialFieldsEditing"
      tabindex="8"
      formControlName="phone"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [size]="tuiElementLargeSize"
      [phoneValue]="phoneControl.value"
      (phoneValueChange)="onChangePhone($event)"
      [pseudoInvalid]="phoneControl.invalid"
      [forceDisabled]="mode === 'create' ? false : undefined"
    >
      {{ 'components.userInfo.fields.mobilePhone' | translate }}
      <span *ngIf="mode === 'create'" class="tui-required"></span>
    </app-input-phone>

    <tui-input
      *ngIf="allowEmailFieldEditing"
      tabindex="9"
      formControlName="email"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.email' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.email" />
    </tui-input>

    <tui-textarea
      class="field field_height_min"
      tabindex="11"
      formControlName="resources"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [expandable]="true"
    >
      {{ 'components.userInfo.fields.resources' | translate }}
    </tui-textarea>

    <tui-input
      tabindex="12"
      formControlName="hobbies"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.hobbies' | translate }}
    </tui-input>

    <ng-container *ngIf="allowSpecialFieldsForAssociationEditing">
      <search-users-input
        *ngIf="authUser$ | async as authUser"
        mode="single"
        formControlName="curator"
        [label]="'components.userInfo.fields.curator' | translate"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [filter]="{
          searchForRegistredOnly: true,
          organisationIds: authUser.parentOrganisationId ? [authUser.parentOrganisationId] : []
        }"
        [excludeFilterFields]="['organizations']"
        [showFilterButton]="true"
      ></search-users-input>

      <tui-input-date
        tabindex="13"
        formControlName="authorityValidTill"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.userInfo.fields.authorityValidTill' | translate }}
      </tui-input-date>

      <ng-container *ngIf="!form.value.oldPaymentDetails">
        <tui-input-files
          *ngIf="!form.value.paymentDetails"
          class="field size_{{ tuiElementLargeSize }}"
          tabindex="14"
          formControlName="paymentDetails"
          [label]="' '"
          [link]="'label.userProfileAttachment' | customNames"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [size]="tuiElementLargeSize"
          [maxFileSize]="maxDocFileSize"
        >
          <input tuiInputFiles accept=".pdf, .doc, .docx, .rtf" />
        </tui-input-files>
        <tui-files *ngIf="!!form.value.paymentDetails">
          <tui-file [file]="form.value.paymentDetails" (removed)="form.get('paymentDetails')?.reset()"></tui-file>
        </tui-files>
      </ng-container>
      <ng-container *ngIf="!!form.value.oldPaymentDetails">
        <tui-files>
          <tui-file
            [file]="form.value.oldPaymentDetails"
            (removed)="form.get('oldPaymentDetails')?.reset()"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          ></tui-file>
        </tui-files>
      </ng-container>

      <tui-checkbox-block
        class="field"
        tabindex="16"
        formControlName="isFeePaid"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [size]="tuiElementSmallSize"
      >
        {{ 'components.userInfo.fields.feeNotPaid' | translate }}
      </tui-checkbox-block>
    </ng-container>
  </div>

  <div class="user-info-edit__organisations" formArrayName="organisations">
    <tui-island *ngFor="let organisation of organisations.controls; let oIndex = index" class="tui-island">
      <button
        *ngIf="allowIsDefaultOrganisationEditing"
        tuiIconButton
        type="button"
        appearance="icon"
        size="xs"
        [disabled]="isDefaultOrganisation(oIndex)"
        (click)="isDefaultOrganisation(oIndex) ? undefined : setDefaultOrganisation(oIndex)"
      >
        <tui-svg
          class="tui-island__icon"
          [class.tui-island__icon_isDefault]="isDefaultOrganisation(oIndex)"
          [src]="'tuiIconStar'"
        ></tui-svg>
      </button>

      <p class="tui-island__title">{{ organisation.get('organisationName')?.value }}</p>
      <div class="tui-island__paragraph item" [formGroupName]="oIndex">
        <ng-container
          *ngIf="
            allowJobTitleFieldsEditing &&
            (allowAssociationEmployeeEditing ||
              (allowOrganisationEmployeeEditing && organisation.get('organisationId')?.value === organisationId) ||
              authUser$.value?.id === data?.id)
          "
        >
          <tui-input
            class="field"
            tabindex="17"
            formControlName="jobTitlesDescription"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
            [tuiTextfieldSize]="tuiElementLargeSize"
          >
            {{ 'components.userInfo.fields.jobTitlesDescription' | translate }}
          </tui-input>

          <tui-multi-select
            *tuiLet="jobsForEditing$ | async as jobs"
            class="field"
            tabindex="18"
            formControlName="jobTitlesIds"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [tuiTextfieldCleaner]="true"
            [expandable]="true"
            [editable]="false"
            [stringify]="stringifyJobTitle"
          >
            {{ 'field.jobTitle' | customNames }}
            <span class="tui-required"></span>
            <tui-data-list *tuiDataList tuiMultiSelectGroup>
              <button *ngFor="let job of jobs" tuiOption [value]="job.jobTitleId">
                {{ job.jobTitleName }}
              </button>
            </tui-data-list>
          </tui-multi-select>
        </ng-container>

        <div
          *ngIf="
            allowSpecialFieldsEditing &&
            (allowAssociationEmployeeEditing ||
              (allowOrganisationEmployeeEditing && organisation.get('organisationId')?.value === organisationId))
          "
        >
          <tui-select
            *tuiLet="
              (organisation.get('organisationId')?.value === currentParentOrganisationId || isParentOrganisation
                ? rolesForAssociation$
                : rolesForOrganisation$
              ) | async as roles
            "
            class="field"
            tabindex="19"
            formControlName="roleIds"
            [class.tui-skeleton]="loading || !roles?.length"
            [class.tui-skeleton_text]="loading || !roles?.length"
            [valueContent]="roles ? rolesStringify(roles) : ''"
            [tuiTextfieldSize]="tuiElementLargeSize"
          >
            {{ 'components.userInfo.fields.role' | translate }}
            <span class="tui-required"></span>
            <tui-data-list *tuiDataList>
              <button *ngFor="let role of roles" tuiOption [value]="[role.id]">
                {{ role.description }}
              </button>
            </tui-data-list>
          </tui-select>
        </div>
      </div>
    </tui-island>
  </div>

  <div class="user-info-edit__contacts" formArrayName="contacts">
    <tui-island *ngFor="let contact of contacts.controls; let cIndex = index" class="tui-island">
      <div class="tui-island__paragraph item" [formGroupName]="cIndex">
        <div class="contact">
          <!-- Тип поля -->
          <div *ngIf="allowContactFieldEditing" class="contact__type">
            <tui-select
              tabindex="20"
              formControlName="contactTypeId"
              [valueContent]="contactTypes ? contactTypesStringify(contactTypes) : ''"
              [tuiTextfieldSize]="tuiElementLargeSize"
            >
              {{ 'components.userInfo.fields.contactType' | translate }}
              <tui-data-list *tuiDataList>
                <button *ngFor="let contactType of contactTypes" tuiOption [value]="contactType.id">
                  {{ contactType.description | translate }}
                </button>
              </tui-data-list>
            </tui-select>
          </div>

          <!-- Название поля -->
          <tui-input
            *ngIf="allowContactFieldEditing"
            class="contact__description"
            tabindex="21"
            formControlName="description"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          >
            {{ 'components.userInfo.fields.contactName' | translate }}
          </tui-input>

          <!-- Значение поля -->
          <div class="contact__value">
            <!-- Поле не является ни Прочим, ни файлом -->
            <tui-input
              *ngIf="isNotOtherAndNotFileContactLocal(contact.value.contactTypeId)"
              tabindex="22"
              formControlName="contact"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
            >
              {{
                allowContactFieldEditing
                  ? ('components.userInfo.fields.contactValue' | translate)
                  : contact.value.description
              }}
            </tui-input>

            <!-- Поле является Прочим -->
            <tui-textarea
              *ngIf="isOtherContactLocal(contact.value.contactTypeId)"
              class="field_height_min"
              tabindex="22"
              formControlName="contact"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
              [expandable]="true"
            >
              {{
                allowContactFieldEditing
                  ? ('components.userInfo.fields.contactValue' | translate)
                  : contact.value.description
              }}
            </tui-textarea>

            <!-- Поле является файлом -->
            <ng-container *ngIf="isFileContactLocal(contact.value.contactTypeId)">
              <app-label [label]="allowContactFieldEditing ? '' : contact.value.description">
                <tui-files *ngIf="!!getDocumentFormControl(contact, 'oldDocument').value">
                  <tui-file
                    [file]="getDocumentFormControl(contact, 'oldDocument').value"
                    (removed)="resetDocumentFormControl(contact, 'oldDocument')"
                    [class.tui-skeleton]="loading"
                    [class.tui-skeleton_text]="loading"
                  ></tui-file>
                </tui-files>
                <ng-container *ngIf="!getDocumentFormControl(contact, 'oldDocument').value">
                  <tui-input-files
                    *ngIf="!getDocumentFormControl(contact, 'newDocument').value"
                    class="size_{{ tuiElementLargeSize }}"
                    [link]="'common.fields.fileLink' | translate"
                    [label]="' '"
                    tabindex="22"
                    formControlName="newDocument"
                    [maxFileSize]="maxDocFileSize"
                    [tuiTextfieldSize]="tuiElementLargeSize"
                    [class.tui-skeleton]="loading"
                    [class.tui-skeleton_text]="loading"
                    [size]="tuiElementLargeSize"
                  ></tui-input-files>
                  <tui-files *ngIf="!!getDocumentFormControl(contact, 'newDocument').value">
                    <tui-file
                      [file]="getDocumentFormControl(contact, 'newDocument').value"
                      (removed)="resetDocumentFormControl(contact, 'newDocument')"
                      [class.tui-skeleton]="loading"
                      [class.tui-skeleton_text]="loading"
                    ></tui-file>
                  </tui-files>
                </ng-container>
              </app-label>
            </ng-container>
          </div>

          <!-- Номер для сортировки поля -->
          <tui-input-number
            *ngIf="allowContactFieldEditing"
            class="contact__sortOrder"
            tabindex="23"
            formControlName="sortOrder"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          >
            {{ 'components.userInfo.fields.contactSortOrder' | translate }}
          </tui-input-number>
        </div>
      </div>

      <button
        *ngIf="allowContactFieldDeleting"
        tuiButton
        type="button"
        appearance="flat"
        size="m"
        class="tui-island__footer-button"
        tabindex="24"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [disabled]="contact.disabled"
        (click)="removeContact(cIndex)"
      >
        {{ 'common.buttons.delete' | translate }}
      </button>
    </tui-island>
  </div>
  <button
    *ngIf="!formDisabled && allowContactFieldCreating"
    tuiButton
    type="button"
    appearance="primary"
    size="m"
    icon="tuiIconPlusCircleLarge"
    tabindex="25"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_text]="loading"
    (click)="addContact()"
  >
    {{ 'components.userInfo.buttons.addField' | translate }}
  </button>

  <div
    *ngIf="(authUser$ | async)?.id === data?.id"
    class="user-info-edit__delete-link"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_text]="loading"
  >
    <a tuiLink class="link" [pseudo]="true" mode="negative" (click)="onClickDeleteButton()">
      {{ 'components.userInfo.buttons.deleteAuthUser' | translate }}
    </a>
  </div>

  <div *ngIf="!loading" class="user-info-edit__buttons">
    <button
      tuiButton
      class="user-info-edit-button"
      tabindex="26"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickSaveButton()"
    >
      {{ 'common.buttons.save' | translate }}
    </button>

    <button
      tuiButton
      class="user-info-edit-button"
      tabindex="27"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickCancelButton()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
</form>
