import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { TuiDay } from '@taiga-ui/cdk';
import { GroupInfoService } from '@src/app/modules/group-info';
import {
  EventAdd,
  EventService as ApiEventService,
  EventWithParticipantsEdit,
  EventViewWithParticipants,
  Id,
} from '@src/api';
import { EventViewUI, EventUI } from '@src/models';
import { DECISION_TYPE_CODE } from '@src/constants';
import { TuiDayTimeTransformer } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';

import { AlertService, UserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy {
  event$: BehaviorSubject<EventUI | null>;
  events$: BehaviorSubject<EventViewUI[] | undefined>;
  archivalEvents$: BehaviorSubject<EventViewUI[] | null>;
  currentEvents$: BehaviorSubject<EventViewUI[] | null>;
  eventsForDay$: BehaviorSubject<EventViewUI[] | undefined>;
  eventsForSearch$: BehaviorSubject<EventViewUI[] | undefined>;
  eventsDaysForCalendar$: BehaviorSubject<TuiDay[] | undefined>;
  eventsForCommittee$: BehaviorSubject<EventViewUI[] | undefined>;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private eventService: ApiEventService,
    private userService: UserService,
    private groupInfoService: GroupInfoService,
    private alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    this.event$ = new BehaviorSubject<EventUI | null>(null);
    this.events$ = new BehaviorSubject<EventViewUI[] | undefined>(undefined);
    this.archivalEvents$ = new BehaviorSubject<EventViewUI[] | null>(null);
    this.currentEvents$ = new BehaviorSubject<EventViewUI[] | null>(null);
    this.eventsForDay$ = new BehaviorSubject<EventViewUI[] | undefined>(undefined);
    this.eventsForSearch$ = new BehaviorSubject<EventViewUI[] | undefined>(undefined);
    this.eventsDaysForCalendar$ = new BehaviorSubject<TuiDay[] | undefined>(undefined);
    this.eventsForCommittee$ = new BehaviorSubject<EventViewUI[] | undefined>(undefined);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getEventData(id: string): Observable<EventViewWithParticipants> {
    return this.eventService.getEvent(id).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(err);
      }),
    );
  }

  getEvent(id: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    // TODO: add params
    this.eventService
      .getEvent(id)
      .pipe(
        catchError(err => {
          this.alertService.error(
            this.translateService.instant('components.eventInfo.alerts.errors.eventByLinkNotFound'),
          );
          callback?.(false);
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(async (event: EventUI) => {
        if (event.contactPersonId) {
          const contactPerson = await lastValueFrom(this.userService.getUserData(event.contactPersonId));
          event.contactPersonFullName = contactPerson?.fullName;
        }

        this.event$.next(event);
        if (!!event) {
          this.checkGroupInviteCode(event); // TODO: temp method for old eventChat get groupInviteCode. ADDED 07/12/22. DELETE after 2 months
        }
        callback?.(false);
      });
  }

  getEvents(callback?: (value: boolean) => void): void {
    callback?.(true);

    // TODO: add params
    this.eventService
      .getEventList(1000, 0)
      .pipe(
        catchError(err => {
          callback?.(false);
          this.alertService.error(err);
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(events => {
        this.events$.next(events);

        const currentEvents: EventViewUI[] = [];
        const archivalEvents: EventViewUI[] = [];

        events.forEach(event => {
          if (
            (event.dateEnd ? new Date(event.dateEnd) < new Date() : true) ||
            event.decisionTypeCode === DECISION_TYPE_CODE.Rejected ||
            event.status === 5
          ) {
            return archivalEvents.push(event);
          }

          if (
            !!this.userService.authUser?.permissions?.includes('eventsAllViewing') ||
            event.createdBy === this.userService.authUser?.id ||
            event.decisionTypeCode === DECISION_TYPE_CODE.Accepted ||
            event.decisionTypeCode === DECISION_TYPE_CODE.Readed ||
            event.decisionTypeCode === DECISION_TYPE_CODE.Sended ||
            event.isVisibleToAll
          ) {
            return currentEvents.push(event);
          }

          return;
        });

        currentEvents.sort((a, b) => this.sortByDateStart(a, b, true));
        archivalEvents.sort((a, b) => this.sortByDateStart(a, b, false));

        this.currentEvents$.next(currentEvents.length ? currentEvents : null);
        this.archivalEvents$.next(archivalEvents.length ? archivalEvents : null);

        const eventsDaysForCalendar = this.getEventsDaysForCalendar(events);
        this.eventsDaysForCalendar$.next(eventsDaysForCalendar);

        callback?.(false);
      });
  }

  getEventsForDay(day: TuiDay | null): void {
    const events = this.events$.value;
    let eventsForDay: EventViewUI[] | undefined;
    if (day && events) {
      eventsForDay = events.filter(event => this.checkEventForDay(event, day));
    }
    this.eventsForDay$.next(eventsForDay);
  }

  getEventsForSearch(text: string): void {
    const events = this.events$.value;
    let eventsForSearch: EventViewUI[] | undefined;
    if (text && events) {
      eventsForSearch = events.filter(
        event => event.subject && event.subject.toLowerCase().indexOf(text.toLowerCase()) > -1,
      );
    }
    this.eventsForSearch$.next(eventsForSearch);
  }

  getEventsDaysForCalendar(events: EventViewUI[]): TuiDay[] {
    // TODO: after, replace with eventListByPeriod
    let eventsDaysForCalendar: TuiDay[] = [];
    if (events) {
      events.forEach(event => {
        const eventDays = this.getEventDays(event)?.map(date => TuiDayTimeTransformer.dateToTuiDay(date));
        if (eventDays) {
          eventsDaysForCalendar = [...new Set(eventsDaysForCalendar.concat(...eventDays))];
        }
      });
    }
    return eventsDaysForCalendar;
  }

  getEventsForCommittee(committeeId: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.eventService
      .eventListByCommitteeId(committeeId)
      .pipe(
        catchError(err => {
          callback?.(false);
          this.alertService.error(err);
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(events => {
        events.sort((a, b) => this.sortByDateStart(a, b));
        this.eventsForCommittee$.next(events);
        callback?.(false);
      });
  }

  getEventMembersFile(eventId: string, sendToBot?: boolean): Observable<Blob> {
    return this.eventService.eventParticipantsFile(eventId, sendToBot).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(err);
      }),
    );
  }

  createEvent(data: EventAdd): Observable<Id> {
    return this.eventService.eventAdd(data).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(err);
      }),
    );
  }

  editEvent(data: EventWithParticipantsEdit): Observable<Id> {
    return this.eventService.eventEdit(data).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(err);
      }),
    );
  }

  deleteEvent(eventId: string): Observable<void> {
    return this.eventService.deleteEvent(eventId).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(err);
      }),
    );
  }

  dectivateEvent(eventId: string): Observable<void> {
    return this.eventService.dectivateEvent(eventId).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(err);
      }),
    );
  }

  resendNotification(eventId: string, memberIds: string[]): Observable<void> {
    return this.eventService.resendNotification({ id: eventId, sendTo: memberIds }).pipe(
      catchError(err => {
        this.alertService.error(this.translateService.instant(err.message), {
          autoClose: 10 * 1000,
        });

        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
      tap(() => {
        this.alertService.success(
          this.translateService.instant('components.eventInfo.alerts.successes.resendNotification'),
          {
            autoClose: 10 * 1000,
          },
        );
      }),
    );
  }

  resetEvent(): void {
    this.event$.next(null);
  }

  resetEventsForDay(): void {
    this.eventsForDay$.next(undefined);
  }

  resetEventsForSearch(): void {
    this.eventsForSearch$.next(undefined);
  }

  resetEventsForCommittee(): void {
    this.eventsForCommittee$.next(undefined);
  }

  resetAll(): void {
    this.events$.next(undefined);
    this.archivalEvents$.next(null);
    this.currentEvents$.next(null);
    this.eventsForDay$.next(undefined);
    this.eventsForSearch$.next(undefined);
    this.eventsDaysForCalendar$.next(undefined);
  }

  acceptEvent(id: string) {
    return lastValueFrom(this.eventService.acceptEvent({ id })).then();
  }

  declineEvent(id: string) {
    return lastValueFrom(this.eventService.declineEvent({ id })).then();
  }

  addEventToCalendar(id: string, sendToBot?: boolean) {
    return this.eventService.calendar(id, sendToBot);
  }

  private checkEventForDay(event: EventViewUI, day: TuiDay): boolean {
    if (!event.dateStart || !event.dateEnd) return false;

    const startDate = new Date(event.dateStart);
    const endDate = new Date(event.dateEnd);

    const eventStartDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    const eventEndDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0);
    const calendarDay = new Date(day.year, day.month, day.day, 0, 0, 0, 0);

    if (eventStartDay <= calendarDay && calendarDay <= eventEndDay) return true;

    return false;
  }

  private getEventDays(event: EventViewUI): Date[] | undefined {
    if (!event.dateStart || !event.dateEnd) return;

    const startDate = new Date(event.dateStart);
    const endDate = new Date(event.dateEnd);
    const dateArray: Date[] = [];

    const currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  private sortByDateStart(a: EventUI, b: EventUI, asc: boolean = true) {
    const dateStartA = a.dateStart ? new Date(a.dateStart) : undefined;
    const dateStartB = b.dateStart ? new Date(b.dateStart) : undefined;

    if (!dateStartA || !dateStartB) return 0;

    return asc ? dateStartA.getTime() - dateStartB.getTime() : dateStartB.getTime() - dateStartA.getTime();
  }

  // TODO: temp method for old eventChat get groupInviteCode. ADDED 07/12/22. DELETE after 2 months
  private checkGroupInviteCode(event: EventUI) {
    if (!event.groupChatId || event.groupInviteCode) return;

    this.groupInfoService.getInviteLink(event.groupChatId).then(inviteLink => {
      if (!inviteLink) return;

      event.groupInviteCode = inviteLink;
      lastValueFrom(this.editEvent(event));
    });
  }
}
