import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { logger } from '@src/utils';
import { BaseSearchService } from '@src/core/services/base-search.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService<Service extends BaseSearchService<FilterType, ReturnType>, FilterType, ReturnType>
  implements OnDestroy
{
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor() {
    logger('SearchService constructor');
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  startSearch(service: Service, filter: FilterType | null): void {
    service.filter$.next(filter);
  }

  searchFilter(service: Service): Observable<ReturnType[] | null> {
    return service.filter$
      .pipe(takeUntil(this.destroyed$$))
      .pipe(
        filter(filter => !!filter),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(filter => {
          return service.search(filter!).pipe(
            catchError(err => {
              // TODO handle error
              return of(null);
            }),
          );
        }),
      )
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
}
