<div class="telegram-login">
  <button
    *ngIf="step !== 'phone' && step !== 'qrcode' && step !== 'loading'"
    tuiButton
    class="button-return"
    type="button"
    appearance="link"
    icon="tuiIconChevronLeftLarge"
    size="s"
    [title]="'common.buttons.return' | translate"
    (click)="gotoPhone()"
  >
    {{ 'common.buttons.return' | translate }}
  </button>

  <ng-container *ngIf="isBrand; else unionsLogo">
    <app-branding-logo *ngIf="step !== 'qrcode' && showLogo" class="telegram-login__logo"></app-branding-logo>
  </ng-container>

  <ng-template #unionsLogo>
    <div *ngIf="step !== 'qrcode' && showLogo" class="telegram-login__logo telegram-login__logo_unions logo">
      <app-branding-logo class="logo__image" [width]="40" [height]="40"></app-branding-logo>
      <span class="logo__label">Unions</span>
    </div>
  </ng-template>

  <tui-loader size="xxl" [showLoader]="step === 'loading'" [overlay]="true">
    <div class="telegram-login__form">
      <tui-loader
        *ngIf="step === 'qrcode'"
        class="telegram-login__qrcode"
        size="xxl"
        [showLoader]="!loginLink"
        [inheritColor]="true"
        [overlay]="true"
      >
        <canvas #qrCodeCanvas></canvas>
      </tui-loader>

      <!--Шаг, где отображаем информацию по статусу основного бота-->
      <ng-container *ngIf="step === 'unlock-bot' || step === 'unlock-bot-timeout'">
        <p class="telegram-login__description">
          {{ 'components.telegramLogin.labels.unlockBotPart1' | translate }}
          <strong>{{ telegramAuth.getMainBot().username }}</strong>
          {{ 'components.telegramLogin.labels.unlockBotPart2' | translate }}
          <br />
          {{ 'components.telegramLogin.labels.unlockBotPart3' | translate }}
        </p>

        <tui-loader *ngIf="step === 'unlock-bot'" size="l" [showLoader]="true" [overlay]="true"></tui-loader>

        <p *ngIf="step === 'unlock-bot-timeout'" class="telegram-login__description">
          {{ 'components.telegramLogin.labels.timeout' | translate }}
        </p>
      </ng-container>

      <!--Вход по номеру телефона-->
      <ng-container *ngIf="step === 'phone' || step === 'loading'">
        <div>
          <p *ngIf="alreadyAuthorized; else authDescription" class="telegram-login__title_autorized">
            {{ 'components.telegramLogin.labels.getPassword' | translate }}
          </p>
          <ng-template #authDescription>
            <p class="telegram-login__title">
              {{ title || ('components.telegramLogin.labels.enterPhone' | translate) }}
            </p>
          </ng-template>
        </div>

        <div>
          {{ 'components.telegramLogin.labels.phone' | translate }}
          <app-input-phone
            size="l"
            [(phoneValue)]="phoneNumber"
            [pseudoInvalid]="phoneInvalid"
            [phoneDisabled]="loading || disabledPhoneNumberInput"
            [labelOutside]="true"
            [autofocus]="true"
            (phoneValueChange)="onPhoneNumberChange($event)"
          >
            {{ 'components.telegramLogin.labels.phone' | translate }}
          </app-input-phone>
        </div>

        <tui-loader [showLoader]="loading" [inheritColor]="true" [overlay]="true">
          <button tuiButton size="l" type="button" appearance="primary" class="button-next" (click)="sendPhone()">
            {{ 'common.buttons.next' | translate }}
          </button>
        </tui-loader>

        <!-- <a
          *ngIf="!alreadyAuthorized && isSmallScreen"
          tuiLink
          style="text-align: center"
          (click)="gotoQRCode()"
          [class.disabled-link]="loading"
        >
          Вход по QR-коду Telegram
        </a> -->

        <ng-content></ng-content>
      </ng-container>

      <!--Вход по QR коду-->
      <ng-container *ngIf="step === 'qrcode'">
        <div class="telegram-login__header">
          <h2 class="telegram-login__title telegram-login__title_mobile">
            {{ 'components.telegramLogin.labels.qrCodeTitle' | translate }}
          </h2>
          <p class="telegram-login__description">
            {{ 'components.telegramLogin.labels.qrCodeDescriptionPart1' | translate }}
          </p>
          <p class="telegram-login__description">
            {{ 'components.telegramLogin.labels.qrCodeDescriptionPart2' | translate }}
          </p>
          <p class="telegram-login__description">
            {{ 'components.telegramLogin.labels.qrCodeDescriptionPart3' | translate }}
          </p>
        </div>

        <tui-loader [showLoader]="loading" [inheritColor]="true" [overlay]="true">
          <button
            tuiButton
            type="button"
            appearance="primary"
            class="button-next"
            [disabled]="!loginLink"
            (click)="gotoPhone()"
          >
            {{ 'components.telegramLogin.buttons.qrCodeEnterByPhone' | translate }}
          </button>
        </tui-loader>
      </ng-container>

      <!--Ввод кода, который должен прийти в Telegram-->
      <ng-container *ngIf="step === 'code'">
        <div class="telegram-login__title">{{ 'components.telegramLogin.labels.stepCodeTitle' | translate }}</div>

        <div class="telegram-login__description">
          {{ 'components.telegramLogin.labels.stepCodeDescriptionPart1' | translate }}
          <br />
          {{
            'components.telegramLogin.labels.stepCodeDescriptionPart2'
              | translate : { value: phoneNumber | formatPhone }
          }}
        </div>

        <app-input-code
          [codeLength]="5"
          [(code)]="code"
          [(codeInvalid)]="codeInvalid"
          (codeIsReady)="sendCode()"
        ></app-input-code>

        <tui-loader [showLoader]="loading" [inheritColor]="true" [overlay]="true">
          <button tuiButton type="button" size="l" appearance="primary" class="button-next" (click)="sendCode()">
            {{ 'common.buttons.next' | translate }}
          </button>
        </tui-loader>
      </ng-container>

      <!--Ввод пароля от Telegram-->
      <ng-container *ngIf="step === 'password'">
        <div class="telegram-login__title">{{ 'components.telegramLogin.labels.stepPasswordTitle' | translate }}</div>
        <div class="telegram-login__description">
          {{ 'components.telegramLogin.labels.stepPasswordDescriptionPart1' | translate }}
          <br />
          {{ 'components.telegramLogin.labels.stepPasswordDescriptionPart2' | translate }}
        </div>

        <tui-input-password
          tuiAutoFocus
          tuiTextfieldSize="l"
          [pseudoInvalid]="passwordInvalid"
          [disabled]="loading"
          [(ngModel)]="password"
          (ngModelChange)="onChangePassword()"
        >
          {{
            passwordInvalid
              ? ('components.telegramLogin.fields.wrongPassword' | translate)
              : ('components.telegramLogin.fields.password' | translate)
          }}
        </tui-input-password>

        <tui-loader [showLoader]="loading" [inheritColor]="true" [overlay]="true">
          <button tuiButton size="l" type="button" appearance="primary" class="button-next" (click)="sendPassword()">
            {{ 'common.buttons.next' | translate }}
          </button>
        </tui-loader>
      </ng-container>
    </div>
  </tui-loader>

  <button
    *ngIf="interruptTelegramSession || step === 'loading'"
    tuiButton
    type="button"
    appearance="secondary"
    size="m"
    class="button-destroy"
    (click)="interruptSession()"
  >
    {{ 'components.telegramLogin.buttons.logOut' | translate }}
  </button>
</div>
