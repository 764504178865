import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TuiSheetModule } from '@taiga-ui/addon-mobile';
import { TuiInputPasswordModule, TuiInputModule, TuiInputNumberModule, TuiPushModule } from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiFormatPhonePipeModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiNotificationModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TelegramModule } from '@src/app/modules/telegram';
import { SharedModule } from '@src/app/shared';
import { ComponentsModule } from '@src/app/components';
import { LogoModule } from '@src/app/modules/branding/modules';
import { CoreModule } from '@src/core';
import { DialogsModule } from '@src/app/shared/dialogs';
import { CaptchaModule } from '@src/app/modules/captcha';
import { InputCodeModule } from '@src/app/modules/input-code';
import { PhoneModule } from '@src/app/modules/phone';

import { LoginComponent } from './login.component';
import { EnterByCodeComponent } from './components/enter-by-code/enter-by-code.component';
import { DownloadLinksComponent } from './components/download-links/download-links.component';
import { PolicyComponent } from './components/policy/policy.component';

@NgModule({
  declarations: [LoginComponent, EnterByCodeComponent, DownloadLinksComponent, PolicyComponent],
  imports: [
    CommonModule,
    FormsModule,
    LogoModule,
    PhoneModule,
    RouterModule,
    TuiButtonModule,
    TuiInputModule,
    TuiInputNumberModule,
    TuiInputPasswordModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiScrollbarModule,
    TuiSheetModule,
    TuiTextfieldControllerModule,
    TuiPushModule,
    TelegramModule,
    TuiLinkModule,
    SharedModule,
    ComponentsModule,
    CoreModule,
    TuiFormatPhonePipeModule,
    DialogsModule,
    CaptchaModule,
    TuiNotificationModule,
    InputCodeModule,
  ],
  exports: [LoginComponent],
})
export class LoginModule {}
