import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { APP_CONFIG } from '@src/core';

const ROUTE_DEFINITION: Record<string, string> = {
  events: marker('common.constants.routeDefinition.events'),
  polls: marker('common.constants.routeDefinition.polls'),
} as const;

export type RouteDefinition = {
  /** Заголовок маршрута */
  title: string;

  /** Команды, чтобы произвести навигацию */
  commands: string[];
};

const parseLink = (url: string): string[] => {
  return url.slice(APP_CONFIG.baseUrl.length + 1).split('/');
};

/**
 * Утилита, определяющая, является ли заданная ссылка внутренней.
 * @param url абстрактная ссылка
 */
export const isInnerLink = (url: string): boolean => {
  if (url.startsWith(APP_CONFIG.baseUrl)) {
    const [route] = parseLink(url);
    return Object.keys(ROUTE_DEFINITION).includes(route);
  }

  return false;
};

/**
 * Утилита, возвращающая описание маршрута по заданной ссылке
 * @param url абстрактная ссылка
 */
export const getInnerRouteDefinitionFromLink = (url: string): RouteDefinition | null => {
  if (!isInnerLink(url)) {
    return null;
  }

  const commands = parseLink(url);
  const [route] = commands;

  return {
    title: ROUTE_DEFINITION[route] ?? 'unknown',
    commands: commands.map((item, index) => (index === 0 ? `/${item}` : item)),
  };
};
