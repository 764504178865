interface HeaderButton {
  label: string;
  icon?: string;
  action: () => void;
}

export interface HeaderOptions {
  title: string;
  buttons?: {
    left?: HeaderButton;
    right?: HeaderButton;
  };
}
