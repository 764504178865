<div *ngIf="mode === 'view'" class="field field_size_{{ size ? size : 'l' }}">
  <div class="field__name" [title]="fileName">{{ fileName }}</div>
  <div class="field__value">
    <ng-container *ngIf="fileId; else fileLoadingTmpl">
      <ng-container [ngSwitch]="fileType">
        <app-photo *ngSwitchCase="'photo'" [documentId]="fileId" height="400px" width="100%"></app-photo>

        <button *ngSwitchDefault class="button-link" (click)="downloadDocument()">
          {{ 'common.buttons.download' | translate }}
        </button>
      </ng-container>
    </ng-container>

    <ng-template #fileLoadingTmpl>
      <span class="field__loading">{{ 'common.labels.loading' | translate }}&hellip;</span>
    </ng-template>
  </div>
</div>
