<div class="message-formatted-text__wrapper">
  <ng-container *ngFor="let parsedText of parsedFormattedTexts">
    <ng-container [ngSwitch]="parsedText.mainType?._">
      <a
        *ngSwitchCase="'textEntityTypeBotCommand'"
        [class]="parsedText.joinedTypes"
        (click)="onClickCommand(parsedText.text)"
      >{{ parsedText.text }}</a>

      <ng-container *ngSwitchCase="'textEntityTypeUrl'" [ngTemplateOutlet]="linkTemplate"></ng-container>
      <ng-container *ngSwitchCase="'textEntityTypeTextUrl'" [ngTemplateOutlet]="linkTemplate"></ng-container>

      <ng-template #linkTemplate>
        <a
          app-render-link
          [class]="parsedText.joinedTypes"
          [displayableText]="parsedText.text"
          [url]="this.getFormattedTextUrl(parsedText.mainType) || parsedText.text"
        ></a>
      </ng-template>

      <a
        *ngSwitchCase="'textEntityTypeEmailAddress'"
        [class]="parsedText.joinedTypes"
        href="mailto:{{ parsedText.text }}"
      >
        {{ parsedText.text }}
      </a>

      <a *ngSwitchCase="'textEntityTypePhoneNumber'" [class]="parsedText.joinedTypes" href="tel:{{ parsedText.text }}">
        {{ parsedText.text }}
      </a>

      <code
        *ngSwitchCase="'textEntityTypeCode'"
        [class]="parsedText.joinedTypes"
        (click)="onClickCode(parsedText.text)"
      >
        {{ parsedText.text }}
      </code>

      <pre *ngSwitchCase="'textEntityTypePre'" [class]="parsedText.joinedTypes">{{ parsedText.text }}</pre>

      <ng-container *ngSwitchCase="'textEntityTypeMention'" [ngTemplateOutlet]="mentionTemplate"></ng-container>
      <ng-container *ngSwitchCase="'textEntityTypeMentionName'" [ngTemplateOutlet]="mentionTemplate"></ng-container>

      <ng-template #mentionTemplate>
        <a [class]="parsedText.joinedTypes" (click)="onClickMention(parsedText.text, parsedText.mainType)">
          {{ parsedText.text }}
        </a>
      </ng-template>

      <span *ngSwitchDefault [class]="parsedText.joinedTypes">{{ parsedText.text }}</span>
    </ng-container>
  </ng-container>
</div>
