import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AddCommittee,
  CommitteeContactsService,
  CommitteesService,
  CommitteeView,
  CommitteeViewWithUsersCount,
  Id,
  SearchCommitteesRequestParameters,
} from '@src/api';
import { CommitteeTreeItem, CommitteeUI } from '@src/models';
import { AlertService } from '@src/core/services';
import { TranslateService } from '@ngx-translate/core';
import { getFilter, preventSearchString } from '@src/utils';
import { CustomNamesService } from '@src/app/modules/custom-name-tabs';

export interface LoadCommitteeRequest {
  id: string;
  loadCommitteesTree: boolean;
  loadObjectMenu: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CommitteeService implements OnDestroy {
  committee$: BehaviorSubject<CommitteeUI | null>;
  committeeInfo$: BehaviorSubject<CommitteeUI | null>;
  getCommittee$: BehaviorSubject<LoadCommitteeRequest | null> = new BehaviorSubject<LoadCommitteeRequest | null>(null);
  getCommitteeInfo$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  committees$: BehaviorSubject<CommitteeUI[] | null>;
  subCommittees$: BehaviorSubject<CommitteeUI[] | null>;
  committeesForOrganisation$: BehaviorSubject<CommitteeUI[] | null>;
  committeesForUser$: BehaviorSubject<CommitteeUI[] | null>;
  committeesTree$: BehaviorSubject<CommitteeUI[] | null>;
  committeesForSearch$: BehaviorSubject<CommitteeUI[] | undefined>;
  loadCommittees$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  loadCommitteesForSearch$: BehaviorSubject<SearchCommitteesRequestParameters | null> =
    new BehaviorSubject<SearchCommitteesRequestParameters | null>(null);
  loadCommitteesTree$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  subCommitteesTree$ = new BehaviorSubject<CommitteeViewWithUsersCount[] | null>(null);
  loadSubCommitteesTree$ = new BehaviorSubject<string | null>(null);

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly committeesService: CommitteesService,
    private readonly customNamesService: CustomNamesService,
    private readonly committeeContactsService: CommitteeContactsService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    this.committee$ = new BehaviorSubject<CommitteeUI | null>(null);
    this.committeeInfo$ = new BehaviorSubject<CommitteeUI | null>(null);
    this.committees$ = new BehaviorSubject<CommitteeUI[] | null>(null);
    this.subCommittees$ = new BehaviorSubject<CommitteeUI[] | null>(null);
    this.committeesForOrganisation$ = new BehaviorSubject<CommitteeUI[] | null>(null);
    this.committeesForUser$ = new BehaviorSubject<CommitteeUI[] | null>(null);
    this.committeesTree$ = new BehaviorSubject<CommitteeUI[] | null>(null);
    this.committeesForSearch$ = new BehaviorSubject<CommitteeUI[] | undefined>(undefined);

    this.getCommittee().pipe(takeUntil(this.destroyed$$)).subscribe();
    this.getInfoCommittee().pipe(takeUntil(this.destroyed$$)).subscribe();

    this.loadCommittees().pipe(takeUntil(this.destroyed$$)).subscribe();
    this.loadCommitteesTree().pipe(takeUntil(this.destroyed$$)).subscribe();

    this.getSubCommitteesTree().pipe(takeUntil(this.destroyed$$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  /**
   * Чтение дерева комитетов "вверх".
   * @param id идентификатор подкомитета
   * */
  async getCommitteeTreeAsync(id: string): Promise<CommitteeTreeItem[]> {
    const tree: CommitteeTreeItem[] = [];

    let parentId: string | undefined | null = id;
    let committee: CommitteeView;

    do {
      committee = await this.getCommitteeAsync(parentId);

      tree.push({
        id: committee.id,
        parentId: committee.parentCommitteeId,
        title: committee.name,
        isRoot: !committee.parentCommitteeId,
      });

      parentId = committee.parentCommitteeId;
    } while (!!parentId);

    return tree.reverse();
  }

  getCommitteeAsync(id: string): Promise<CommitteeView> {
    return lastValueFrom(
      this.committeesService.getCommittees(id).pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
      ),
    );
  }

  loadCommittee(loadCommittee: LoadCommitteeRequest): void {
    this.getCommittee$.next(loadCommittee);
  }

  loadCommitteeInfo(committeeId: string): void {
    this.getCommitteeInfo$.next(committeeId);
  }

  getInfoCommittee(): Observable<boolean> {
    return this.getCommitteeInfo$
      .pipe(
        takeUntil(this.destroyed$$),
        filter(value => !!value),
        switchMap(committeeId => {
          return this.getCommitteeInfoCall(committeeId!);
        }),
      )
      .pipe(
        map(value => {
          return true;
        }),
      );
  }

  getCommittee(): Observable<boolean> {
    return this.getCommittee$
      .pipe(
        takeUntil(this.destroyed$$),
        filter(loadCommittee => !!loadCommittee),
        switchMap(loadCommittee => {
          this.resetCommittee();
          return this.getCommitteeCall(loadCommittee!.id as string).pipe(
            switchMap(committee => {
              if (!!committee && loadCommittee?.loadCommitteesTree) {
                return this.committeesService.associationCommitteesList().pipe(
                  catchError(err => {
                    // TODO show error notification
                    return of(null);
                  }),
                  map(committees => {
                    if (!!committees) {
                      committees = this.sortingCommittees(committees);
                      this.extendCommittees(committees);
                    }
                    this.committeesTree$.next(committees);
                    return committee;
                  }),
                );
              } else {
                return of(committee);
              }
            }),
            switchMap(committee => {
              if (!!committee && loadCommittee?.loadObjectMenu) {
                return this.customNamesService.getObjectMenuWCustom(loadCommittee!.id);
              } else {
                return of(committee);
              }
            }),
          );
        }),
      )
      .pipe(
        map(value => {
          return true;
        }),
      );
  }

  resetLoadCommittees(): void {
    this.loadCommittees$.next(null);
  }

  startGetCommitteesForSearch(searchRequest: SearchCommitteesRequestParameters): void {
    if (!!searchRequest && preventSearchString(searchRequest.name)) return;

    this.loadCommitteesForSearch$.next(getFilter(searchRequest));
  }

  loadCommitteesForSearch(): Observable<CommitteeUI[]> {
    return this.loadCommitteesForSearch$
      .pipe(takeUntil(this.destroyed$$))
      .pipe(debounceTime(250))
      .pipe(distinctUntilChanged())
      .pipe(filter(value => value !== null))
      .pipe(
        switchMap(searchRequest => {
          return this.loadCommitteesForSearchData(searchRequest || {});
        }),
      )
      .pipe(
        map(committees => {
          this.committeesForSearch$.next(committees);
          return committees;
        }),
      );
  }

  startGetCommittees(): void {
    this.loadCommittees$.next(new Date().getTime());
  }

  loadCommittees(): Observable<CommitteeUI[]> {
    return this.loadCommittees$
      .pipe(takeUntil(this.destroyed$$))
      .pipe(filter(value => value !== null))
      .pipe(
        switchMap(() => {
          return this.loadCommitteesData();
        }),
      )
      .pipe(
        map(committees => {
          this.committees$.next(committees);
          return committees;
        }),
      );
  }

  loadCommitteesData(): Observable<CommitteeUI[]> {
    return this.committeesService.userCommitteesList().pipe(
      catchError(err => {
        this.alertService.error(err);
        return of([] as CommitteeView[]);
      }),
      map(committees => this.sortingCommittees(committees)),
    );
  }

  loadCommitteesForSearchData(searchRequest: SearchCommitteesRequestParameters): Observable<CommitteeUI[]> {
    return this.committeesService
      .searchCommittees(searchRequest, 10000, 0) // TODO: добавить постраничную загрузку, пример в другой ветке, сделать по аналогии
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return of([] as CommitteeView[]);
        }),
        map(committees => this.sortingCommittees(committees)),
      );
  }

  startGetCommitteesTree(): void {
    this.loadCommitteesTree$.next(new Date().getTime());
  }

  resetLoadCommitteesTree(): void {
    this.loadCommitteesTree$.next(null);
  }

  loadCommitteesTree(): Observable<CommitteeUI[]> {
    return this.loadCommitteesTree$.pipe(takeUntil(this.destroyed$$)).pipe(
      switchMap(() => {
        return this.loadCommitteesTreeData();
      }),
    );
  }

  loadCommitteesTreeData(): Observable<CommitteeUI[]> {
    return this.committeesService
      .associationCommitteesList()
      .pipe(
        catchError(err => {
          this.alertService.error('Ошибка загрузки данных.');
          return of([] as CommitteeView[]);
        }),
        map(committees => this.sortingCommittees(committees)),
      )
      .pipe(
        map(committees => {
          this.extendCommittees(committees);
          this.committeesTree$.next(committees);
          return committees;
        }),
      );
  }

  loadSubCommitteesTree(committeeId: string): void {
    this.loadSubCommitteesTree$.next(committeeId);
  }

  resetLoadSubCommitteesTree(): void {
    this.loadSubCommitteesTree$.next(null);
  }

  getSubCommitteesTree(): Observable<CommitteeUI[] | null> {
    return this.loadSubCommitteesTree$.pipe(
      takeUntil(this.destroyed$$),
      switchMap(committeeId => {
        if (!!committeeId) {
          return this.getSubCommitteesTreeData(committeeId);
        } else {
          return of(null);
        }
      }),
    );
  }

  getSubCommitteesTreeData(parentCommitteeId: string): Observable<CommitteeUI[]> {
    return this.committeesService.committeesTree(parentCommitteeId).pipe(
      catchError(() => {
        this.alertService.error('Ошибка загрузки данных.');
        return of([] as CommitteeView[]);
      }),
      map(subCommitteesTree => this.sortingCommittees(subCommitteesTree)),
      map(subCommitteesTree => {
        this.subCommitteesTree$.next(subCommitteesTree);
        return subCommitteesTree;
      }),
    );
  }

  extendCommittees(committees: CommitteeUI[]) {
    committees.forEach(committee => {
      committee.parentId = committee.parentCommitteeId;
      committee.children = committee.subCommitties;
      if (!!committee.children) {
        this.extendCommittees(committee.children);
      }
    });
  }

  getSubCommittees(parentCommitteeId: string, callback?: (loading: boolean) => void): void {
    callback?.(true);

    this.committeesService
      .userCommitteesList(parentCommitteeId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(committees => this.sortingCommittees(committees)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(committees => {
        this.subCommittees$.next(committees);
        callback?.(false);
      });
  }

  getCommitteesByOrganisationId(organisationId: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.committeesService
      .getOrganisationCommittees(organisationId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(committees => this.sortingCommittees(committees)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(committees => {
        this.committeesForOrganisation$.next(committees);
        callback?.(false);
      });
  }

  getCommitteesByUserId(userId: string): void {
    this.committeesService
      .committeesListByUserId(userId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(committees => this.sortingCommittees(committees)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(committees => this.committeesForUser$.next(committees));
  }

  getSubCommitteesObservable(parentCommitteeId: string): Observable<CommitteeUI[]> {
    return this.committeesService.userCommitteesList(parentCommitteeId).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      map(committees => this.sortingCommittees(committees)),
      takeUntil(this.destroyed$$),
    );
  }

  createCommittee(data: AddCommittee): Observable<Id> {
    return this.committeesService.addCommittee(data).pipe(
      catchError(err => {
        // TODO handle error
        return throwError(err);
      }),
    );
  }

  editCommittee(data: CommitteeUI): Observable<Id> {
    const comObs = this.committeesService.editCommittee(data).pipe(
      catchError(err => {
        // TODO handle error
        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
    return comObs;
  }

  deleteCommittee(committeeId: string): Observable<void> {
    return this.committeesService.deleteCommittee(committeeId).pipe(
      catchError(err => {
        this.alertService.error(err.error);
        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
      tap(() => {
        this.resetCommittee();
        this.resetCommitteeInfo();
        this.startGetCommittees();
        this.startGetCommitteesTree();
      }),
    );
  }

  addCommitteeUsers(committeeId: string, sendTo: string[]): Promise<number> {
    return lastValueFrom(
      this.committeesService.addCommitteeUsers({ committeeId, sendTo }).pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
      ),
    );
  }

  deleteCommitteeUser(committeeId: string, userId: string): Promise<Id> {
    return lastValueFrom(
      this.committeesService.excludeUser(committeeId, userId).pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
      ),
    );
  }

  getCommitteesTree(callback?: (loading: boolean) => void): void {
    callback?.(true);

    this.committeesService
      .associationCommitteesList()
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(committees => this.sortingCommittees(committees)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(committees => {
        this.extendCommittees(committees);
        this.committeesTree$.next(committees);
        callback?.(false);
      });
  }

  resetCommitteesTree(): void {
    this.committeesTree$.next(null);
  }

  resetLoadCommitteesForSearch(): void {
    this.loadCommitteesForSearch$.next(null);
  }

  resetEventsForSearch(): void {
    this.committeesForSearch$.next(undefined);
  }

  resetCommittee(): void {
    this.committee$.next(null);
  }

  resetCommitteeInfo(): void {
    this.committeeInfo$.next(null);
  }

  resetCommittees(): void {
    this.committees$.next(null);
  }

  resetSubCommittees(): void {
    this.subCommittees$.next(null);
  }

  resetCommitteesForOrganisation(): void {
    this.committeesForOrganisation$.next(null);
  }

  resetCommitteesForUser(): void {
    this.committeesForUser$.next(null);
  }

  resetAll(): void {
    this.resetCommittee();
    this.resetCommittees();
    this.resetSubCommittees();
  }

  private sortingCommittees(committees: CommitteeUI[]): CommitteeUI[] {
    committees.sort((committee1, committee2) => {
      return (committee1.name || '').localeCompare(committee2.name || '');
    });

    committees.forEach(committee => {
      if (!!committee.subCommitties) {
        committee.subCommitties = this.sortingCommittees(committee.subCommitties);
      }
    });

    return committees;
  }

  private getCommitteeInfoCall(committeeId: string): Observable<CommitteeView | null> {
    return this.committeesService.getCommittees(committeeId).pipe(
      catchError(err => {
        this.alertService.error(this.translateService.instant('components.committee.alerts.errors.committeeNotFound'));
        return of(null);
      }),
      map(committee => {
        if (!!committee) {
          committee?.contacts
            ?.sort((a, b) => a.sortOrder! - b.sortOrder!)
            .map((contact, index) => {
              contact.sortOrder = (index + 1) * 10;
              return contact;
            });
        }
        this.committeeInfo$.next(committee);

        return committee;
      }),
    );
  }

  private getCommitteeCall(committeeId: string): Observable<CommitteeView | null> {
    return this.committeesService.getCommittees(committeeId).pipe(
      catchError(err => {
        this.alertService.error(this.translateService.instant('components.committee.alerts.errors.committeeNotFound'));
        return of(null);
      }),
      map(committee => {
        if (!!committee) {
          committee?.contacts
            ?.sort((a, b) => a.sortOrder! - b.sortOrder!)
            .map((contact, index) => {
              contact.sortOrder = (index + 1) * 10;
              return contact;
            });
        }
        this.committee$.next(committee);

        return committee;
      }),
    );
  }

  getCommitteeContacts(committeeId: string) {
    return this.committeeContactsService.getCommitteeContactsList(committeeId);
  }
}
