import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';

import { InformationComponent } from './information.component';

@NgModule({
  declarations: [InformationComponent],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    NgxPermissionsModule,
    SharedModule,
  ],
})
export class InformationModule {}
