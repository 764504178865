<div class="parse-video-link">
  <iframe
    *ngIf="videoSource; else photoLink"
    width="100%"
    height="400"
    frameBorder="0"
    webkitAllowFullScreen
    mozallowfullscreen
    allowFullScreen
    [src]="videoSource | safe : 'resourceUrl'"
  ></iframe>
</div>

<ng-template #photoLink>
  <app-photo *ngIf="photoSource; else defaultLink" [photoSrc]="photoSource" height="400px" width="100%"></app-photo>
</ng-template>

<ng-template #defaultLink>
  <app-link [href]="value"></app-link>
</ng-template>
