import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { lastValueFrom, Observable, Observer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  BasicGroupFullInfoUnion,
  ChatMemberStatusUnion,
  ChatPermissionsUnion,
  SupergroupFullInfoUnion,
  UserUnion,
} from '@airgram/web';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent, PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { ChatModel, UserUI } from '@src/models';
import { EventView, PollFullView } from '@src/api';
import { BreakpointObserverHelperService, EventService, PollService, UserService } from '@src/core/services';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { getImageSrc } from '@src/utils';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TextFormatterHtml } from '@src/app/modules/message/message-formatted-text/utils/text-formatter-html';

import { ChatMemberUI, ActionMenuItem } from './types';

@Component({
  selector: 'telegram-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent extends ResizableBaseComponent implements OnChanges {
  @Input() chat?: ChatModel;
  @Input() type?: 'chat' | 'event' | 'poll' = 'chat';
  @Input() sendId?: string | null;
  @Output() forward: EventEmitter<number[]> = new EventEmitter();
  @Output() subscribe: EventEmitter<string> = new EventEmitter();
  @Output() leave: EventEmitter<number> = new EventEmitter();
  @Output() chatDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<void> = new EventEmitter();
  @ViewChild('confirmDeleteDialogTemplate') confirmDeleteDialogTemplate?: PolymorpheusContent<TuiDialogContext>;

  newMessage: string = '';
  scrollToMessageId = { id: 0 };
  replyToMessageId: number = 0;
  editMessageId: number = 0;
  detailsVisible: boolean = false;
  editing: boolean = false;
  actionMenuOpened: boolean = false;
  deleteFromTelegram: boolean = false;

  currentUserChatStatus?: ChatMemberStatusUnion;
  authUser?: UserUI;
  user?: UserUnion;

  members: ChatMemberUI[] = [];
  chatPermissions?: ChatPermissionsUnion;
  superGroup?: SupergroupFullInfoUnion;
  basicGroup?: BasicGroupFullInfoUnion;
  groupId?: number;
  isChannel: boolean = false;

  bot?: UserUnion;
  event?: EventView;
  poll?: PollFullView;
  allowEditing$?: Observable<boolean | undefined>;

  isChatPage: boolean = false;
  actionMenuItems: ActionMenuItem[];

  private readonly confirmLeaveDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.isChannel
        ? this.translateService.instant('components.telegramChat.dialogs.channelLeaveHeader')
        : this.translateService.instant('components.telegramChat.dialogs.groupLeaveHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private messengerService: TelegramMessengerService,
    private eventService: EventService,
    private pollService: PollService,
    private userService: UserService,
    private ngxPermissionsService: NgxPermissionsService,
    private router: Router,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.userService.getAuthorizedUser().then(user => {
      this.authUser = user;
      this.getUserChatPermissions();
    });

    this.actionMenuItems = [
      {
        name: 'mute',
        title: this.translateService.instant('components.telegramChat.buttons.actionMenuMute'),
        iconName: 'tuiIconBellOffLarge',
        visible: () => {
          return !!this.chat && this.chat.notificationSettings.muteFor === 0;
        },
        action: () => this.onChangeNotificationsToggleValue(),
      },
      {
        name: 'unmute',
        title: this.translateService.instant('components.telegramChat.buttons.actionMenuUnmute'),
        iconName: 'tuiIconBellLarge',
        visible: () => {
          return !!this.chat && this.chat.notificationSettings.muteFor !== 0;
        },
        action: () => this.onChangeNotificationsToggleValue(),
      },
      {
        // TODO: change on footer button
        name: 'subscribe',
        title: this.translateService.instant('components.telegramChat.buttons.actionMenuSubscribe'),
        iconName: 'tuiIconLogInLarge',
        visible: () => {
          return (
            (!!this.superGroup?.inviteLink?.inviteLink || !!this.basicGroup?.inviteLink?.inviteLink) &&
            !!this.currentUserChatStatus &&
            this.currentUserChatStatus._ !== 'chatMemberStatusBanned' &&
            this.currentUserChatStatus._ !== 'chatMemberStatusCreator' &&
            this.currentUserChatStatus._ !== 'chatMemberStatusAdministrator' &&
            this.currentUserChatStatus._ !== 'chatMemberStatusMember'
          );
        },
        action: () => this.onClickSubscribeButton(),
      },
      {
        name: 'leave',
        title: this.translateService.instant('components.telegramChat.buttons.actionMenuLeave'),
        iconName: 'tuiIconLogOutLarge',
        visible: () => {
          return (
            (this.chat?.type._ === 'chatTypeBasicGroup' || this.chat?.type._ === 'chatTypeSupergroup') &&
            !!this.currentUserChatStatus &&
            this.currentUserChatStatus._ !== 'chatMemberStatusLeft' &&
            this.currentUserChatStatus._ !== 'chatMemberStatusCreator'
          );
        },
        action: () => this.onClickLeaveButton(),
      },
      {
        name: 'delete',
        title: this.translateService.instant('components.telegramChat.buttons.actionMenuDelete'),
        iconName: 'tuiIconTrashLarge',
        visible: () => {
          return this.currentUserChatStatus?._ === 'chatMemberStatusCreator';
        },
        action: () => this.openConfirmDeleteDialog(),
      },
    ];
  }

  get showFullPage(): boolean {
    return (!this.detailsVisible && !this.editing) || this.isLargeScreen;
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (this.router.url.indexOf('/chats') > -1) {
      this.isChatPage = true;
    }

    if (this.chat) {
      await this.messengerService.api.openChat(this.chat.id);
    }

    this.messengerService.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(async update => {
      switch (update._) {
        case 'updateChatReplyMarkup':
          const { chatId, replyMarkupMessageId } = update;
          if (chatId === this.chat?.id) {
            this.replyToMessageId = replyMarkupMessageId;
          }
          break;

        case 'updateUserStatus':
          if (!this.user || update.userId !== this.chat?.id) break;

          this.user.status = update.status;
          break;

        case 'updateBasicGroupFullInfo':
          if (!this.basicGroup || update.basicGroupId !== this.groupId) break;

          this.basicGroup = update.basicGroupFullInfo;
          break;

        case 'updateSupergroupFullInfo':
          if (!this.superGroup || update.supergroupId !== this.groupId) break;

          this.superGroup = update.supergroupFullInfo;
          break;

        default:
          break;
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.sendId) {
      this.event = undefined;
      this.poll = undefined;
      this.editing = false;
      this.replyToMessageId = 0;

      if (this.sendId) {
        if (this.type === 'event') {
          await this.getEvent(this.sendId);
          this.allowEditing$ = this.userService.authUser$.pipe(
            map(user => {
              if (!user || !this.event) return false;

              const permissions = this.ngxPermissionsService.getPermissions();

              return (
                'eventEditing' in permissions ||
                ('onlyYourEventEditing' in permissions && user.id === this.event.createdBy)
              );
            }),
          );
        }
        if (this.type === 'poll') {
          await this.getPoll(this.sendId);
          this.allowEditing$ = this.userService.authUser$.pipe(
            map(user => {
              if (!user || !this.poll) return false;

              const permissions = this.ngxPermissionsService.getPermissions();

              return (
                'pollEditing' in permissions ||
                ('onlyYourPollEditing' in permissions && user.id === this.poll.createdBy)
              );
            }),
          );
        }
      }
      this.cdr.markForCheck();
    }

    if (changes.chat) {
      this.bot = undefined;
      this.user = undefined;
      this.superGroup = undefined;
      this.basicGroup = undefined;
      this.chatPermissions = undefined;
      this.currentUserChatStatus = undefined;
      this.members = [];
      this.replyToMessageId = 0;

      if (changes.chat.previousValue && changes.chat.previousValue.id) {
        this.messengerService.api.closeChat(changes.chat.previousValue.id);
      }
      if (this.chat) {
        await this.messengerService.api.openChat(this.chat.id);
      }

      this.getUserChatPermissions();

      if (this.chat?.id !== this.authUser?.telegramId) {
        if (this.chat?.type._ === 'chatTypePrivate') {
          const user = await this.messengerService.api.getUser(this.chat.type.userId);
          if (user.type._ === 'userTypeBot') {
            if (this.type === 'chat') {
              this.bot = user;
            }
            if (this.type === 'event') {
              this.chat.title = this.event?.subject ?? '';
            }
            if (this.type === 'poll') {
              this.chat.title = this.poll?.titleText ?? '';
            }
          } else {
            this.user = user;
          }

          this.members.push({
            id: this.chat?.id,
            name: this.chat?.title,
          });
        } else if (this.chat?.type._ === 'chatTypeBasicGroup') {
          this.basicGroup = await this.messengerService.api.getBasicGroupFullInfo(this.chat.type.basicGroupId);
          this.groupId = this.chat.type.basicGroupId;

          // TODO: refactoring
          for (const member of this.basicGroup.members) {
            const memberId =
              member.memberId._ === 'messageSenderUser' ? member.memberId.userId : member.memberId.chatId;
            const memberName = await this.messengerService.getUserFullName(memberId);
            this.members.push({
              id: memberId,
              name: memberName,
            });
          }
        } else if (this.chat?.type._ === 'chatTypeSupergroup') {
          this.superGroup = await this.messengerService.api.getSupergroupFullInfo(this.chat.type.supergroupId);
          this.groupId = this.chat.type.supergroupId;
          this.isChannel = this.chat.type.isChannel;
          this.cdr.markForCheck();

          if (this.chat?.type.isChannel) {
            this.members.push({
              id: this.chat?.id,
              name: this.chat?.title,
            });
          } else {
            // TODO: refactoring
            const superGroupMembers = await this.messengerService.api.getSupergroupMembers(this.chat.type.supergroupId);
            for (const member of superGroupMembers.members) {
              const memberId =
                member.memberId._ === 'messageSenderUser' ? member.memberId.userId : member.memberId.chatId;
              const memberName = await this.messengerService.getUserFullName(memberId);
              this.members.push({
                id: memberId,
                name: memberName,
              });
            }

            const limit = 200;
            if (this.superGroup.memberCount > limit) {
              for (let i = 1; i < Math.ceil(this.superGroup.memberCount / limit); i++) {
                const updatedSupergroupMembers = await this.messengerService.api.getSupergroupMembers(
                  this.chat.type.supergroupId,
                  limit * i,
                  limit,
                );
                for (const member of updatedSupergroupMembers.members) {
                  const memberId =
                    member.memberId._ === 'messageSenderUser' ? member.memberId.userId : member.memberId.chatId;
                  const memberName = await this.messengerService.getUserFullName(memberId);
                  this.members.push({
                    id: memberId,
                    name: memberName,
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.chat) {
      this.messengerService.api.closeChat(this.chat.id);
    }

    super.ngOnDestroy();
  }

  scrollToMessage(messageId: number) {
    this.scrollToMessageId = { id: messageId };
  }

  async editMessageIdChange(id: number): Promise<void> {
    if (!this.chat?.id || id === 0) {
      return;
    }

    const message = await this.messengerService.api.getMessage(this.chat?.id, id);
    if (message.content._ === 'messageText') {
      this.newMessage = TextFormatterHtml.parse(message.content.text);
    }
  }

  onClickEditButton(): void {
    this.editing = true;
    this.detailsVisible = false;
    this.cdr.detectChanges();
  }

  onCancelEditing(): void {
    this.editing = false;
  }

  async onSaved(id: string): Promise<void> {
    this.editing = false;

    if (this.type === 'event') {
      this.eventService.getEvents();
      await this.getEvent(id);
    }

    if (this.type === 'poll') {
      this.pollService.getPolls();
      await this.getPoll(id);
    }

    this.cdr.markForCheck();
  }

  onDeletedEvent(): void {
    this.editing = false;
    this.eventDeleted.emit();
  }

  onClickSubscribeButton() {
    const inviteLink = this.basicGroup
      ? this.basicGroup.inviteLink?.inviteLink
      : this.superGroup?.inviteLink?.inviteLink;
    if (inviteLink) this.subscribe.emit(inviteLink);
  }

  onClickLeaveButton() {
    this.confirmLeaveDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res && this.chat?.id) {
          this.leave.emit(this.chat.id);
        }
      },
    });
  }

  applyChatDelete(observer: Observer<void>): void {
    observer.complete();

    this.chatDelete.emit(this.deleteFromTelegram);
  }

  openConfirmDeleteDialog(): void {
    if (!this.confirmDeleteDialogTemplate) return;

    this.deleteFromTelegram = false;

    this.dialogService
      .open(this.confirmDeleteDialogTemplate, {
        label: this.isChannel
          ? this.translateService.instant('components.telegramChat.dialogs.channelDeleteHeader')
          : this.translateService.instant('components.telegramChat.dialogs.groupDeleteHeader'),
        size: 's',
        closeable: false,
        dismissible: false,
      })
      .subscribe();
  }

  onClickDetailButton(): void {
    this.detailsVisible = !this.detailsVisible;
    this.editing = false;
  }

  onChangeNotificationsToggleValue(): void {
    if (this.chat?.id) {
      this.messengerService.api
        .setChatNotificationSettings(this.chat.id, this.chat.notificationSettings.muteFor !== 0)
        .then(() => this.cdr.markForCheck());
    }
  }

  forwardToChat(forwardMessageIds: number[]) {
    if (this.chat) {
      this.forward.emit(forwardMessageIds);
    }
  }

  private async getEvent(id: string): Promise<void> {
    this.event = await lastValueFrom(this.eventService.getEventData(id)).then();
    if (this.chat) {
      this.chat.title = this.event?.subject ?? '';
      this.chat.userPhotoPath = getImageSrc(this.event?.photoId);
    }
  }

  private async getPoll(id: string): Promise<void> {
    this.poll = await lastValueFrom(this.pollService.getPollData(id)).then();
    if (this.chat) {
      this.chat.title = this.poll?.titleText ?? '';
      this.chat.userPhotoPath = getImageSrc(this.poll?.photoId);
    }
  }

  private getUserChatPermissions(): void {
    if (!this.chat?.id || !this.authUser?.telegramId) return;

    this.chatPermissions = this.chat.permissions;
    this.messengerService.api
      .getChatMember(this.chat.id, {
        _: 'messageSenderUser',
        userId: this.authUser.telegramId,
      })
      .then(chatMember => {
        this.currentUserChatStatus = chatMember.status;
        this.cdr.markForCheck();
      });
  }
}
