import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TuiInputModule, TuiInputDateModule, TuiInputNumberModule, TuiIslandModule, TuiMultiSelectModule, TuiSelectModule, TuiTextareaModule, TuiInputFilesModule } from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiAutoFocusModule, TuiLetModule } from '@taiga-ui/cdk';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FieldModule } from '@src/app/shared/field';
import { SharedModule } from '@src/app/shared';
import { PhotoModule } from '@src/app/modules/photo';

import { OrganisationInfoComponent } from './organisation-info.component';
import { OrganisationInfoViewComponent } from './organisation-info-view/organisation-info-view.component';
import { OrganisationInfoEditComponent } from './organisation-info-edit/organisation-info-edit.component';

@NgModule({
  declarations: [OrganisationInfoComponent, OrganisationInfoViewComponent, OrganisationInfoEditComponent],
  imports: [
    CommonModule,
    FieldModule,
    FormsModule,
    NgxPermissionsModule,
    PhotoModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TuiAutoFocusModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiInputModule,
    TuiInputDateModule,
    TuiInputFilesModule,
    TuiInputNumberModule,
    TuiIslandModule,
    TuiLetModule,
    TuiLinkModule,
    TuiLoaderModule,
    TuiMultiSelectModule,
    TuiSelectModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
  ],
  exports: [OrganisationInfoComponent],
})
export class OrganisationInfoModule {}
