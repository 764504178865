import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OrganisationSettingsService, OrganisationSettingView } from '@src/api';
import { takeUntil } from 'rxjs/operators';
import { AlertService, BreakpointObserverHelperService, PreferencesService } from '@src/core/services';
import { AuthUserService } from '@src/app/modules/auth';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TranslateService } from '@ngx-translate/core';

import { FormData, FormDataControls } from './types';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreferencesComponent extends ResizableBaseComponent {
  form = new UntypedFormGroup(<FormDataControls>{
    showParentOrgUsersInLists: new UntypedFormControl(false),
    showOnlyChannelsInNewsFeed: new UntypedFormControl(false),
  });

  settings?: OrganisationSettingView;

  loading = false;

  saving = false;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly api: OrganisationSettingsService,
    private readonly alertService: AlertService,
    private readonly preferencesService: PreferencesService,
    private readonly authUserService: AuthUserService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.form.valueChanges.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.save();
    });

    await this.getData();
  }

  save(): void {
    if (!this.form.dirty || this.form.disabled || this.saving) {
      return;
    }

    this.saving = true;
    this.form.disable();

    const formData = this.form.value as FormData;

    const observable = this.settings?.id
      ? this.api.editOrganisationSettings({
          ...this.settings,
          ...formData,
          organisationId: this.settings.organisationId ?? this.authUserService.user?.organisationId,
        })
      : this.api.addOrganisationSettings({
          ...formData,
          organisationId: this.authUserService.user?.organisationId,
        });

    observable.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: async () => {
        this.alertService.success(this.translateService.instant('components.preferences.alerts.successes.saveData'));

        await this.getData();

        this.form.enable();
        this.saving = false;
      },
      error: err => {
        this.alertService.error(err);

        this.form.enable();
        this.saving = false;
      },
    });
  }

  private async getData() {
    try {
      this.loading = true;

      this.settings = await this.preferencesService.refresh();
      const value = {
        showParentOrgUsersInLists: this.settings.showParentOrgUsersInLists,
        showOnlyChannelsInNewsFeed: this.settings.showOnlyChannelsInNewsFeed,
      };
      this.form.reset(value);
    } finally {
      this.loading = false;
      this.cdr.markForCheck();
    }
  }
}
