/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Статистика за месяц.
 */
export interface ProductStatistics { 
    /**
     * ID продукта.
     */
    id?: string;
    /**
     * ID продукта.
     */
    name?: string;
    /**
     * Дата публикации продукта.
     */
    datePublished?: string;
    /**
     * Кол-во записей с action = \"list\".
     */
    listCount?: number;
    /**
     * Кол-во записей с action = \"list\" с уникальными userId.
     */
    uniqueListCount?: number;
    /**
     * Кол-во записей с action = \"read\".
     */
    readCount?: number;
    /**
     * Кол-во записей с action = \"read\" с уникальными userId.
     */
    uniqueReadCount?: number;
    /**
     * Кол-во записей для выборок activityType = \"discount\".
     */
    discountsUsedCount?: number;
    /**
     * Кол-во записей с action = \"link\".
     */
    linksVisitedCount?: number;
}

