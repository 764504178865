import { UserUI } from '@src/models';
import { OrganisationRolesJobTitles } from '@src/api';

import { normalizeJobTitles } from './normalizeJobTitles';

export const normalizeUserDataForUI = (data: UserUI): UserUI => {
  if (!data) return data; // TODO: костыль. нужно привести в порядок типы

  data.phone = `+${data?.phone}`;
  data.fullName = [data.lastName, data.firstName, data.middleName].join(' ');

  if (!data.organisationId) {
    if (data.organisationJobTitles?.length) {
      data.organisationId = data.organisationJobTitles?.[0]?.organisationId;
      data.organisationName = data.organisationJobTitles?.[0]?.organisationName;
    }
    if (data.jobTitlesInOrganisation?.length) {
      data.organisationId = data.jobTitlesInOrganisation?.[0]?.organisationId;
      data.organisationName = data.jobTitlesInOrganisation?.[0]?.organisationName;
    }
  }

  const organisationJobTitlesUI = normalizeJobTitles(data.organisationJobTitles);

  data.organisations = organisationJobTitlesUI
    .map(jobTitle => {
      const organisationRolesJobTitles: OrganisationRolesJobTitles = {
        organisationId: jobTitle.organisationId,
        jobTitles: jobTitle.jobTitlesFull,
        isDefault: jobTitle.organisationId === data.organisationId,
      };

      return organisationRolesJobTitles;
    })
    .sort((a, b) => {
      if (a.isDefault) return -1;
      if (b.isDefault) return 1;

      return 0;
    });

  return data;
};
