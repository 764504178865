<div *ngIf="mode === 'view'" class="field field_size_{{ size ? size : 'l' }}">
  <div class="field__name" [title]="'components.field.constants.fieldTypes.organisation' | translate">
    {{ 'components.field.constants.fieldTypes.organisation' | translate }} &nbsp;
  </div>
  <div class="field__value">
    <a *ngIf="organisation$ | async as organisation; else loading" [routerLink]="'/organisation/' + organisation.id">
      {{ organisation.shortName }}
    </a>
    <ng-template #loading>
      <span>{{ 'common.labels.loading' | translate }}...</span>
    </ng-template>
  </div>
</div>
