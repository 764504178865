<form *ngIf="!!form" class="form" [formGroup]="form">
  <ng-container *ngFor="let formGroupName of formGroupsNames">
    <div class="notification">
      <div class="fields">
        <div class="field title">{{getFormControl(formGroupName, 'title').value}}</div>
        <div class="field description">{{getFormControl(formGroupName, 'description').value}}</div>

        <ng-container *ngFor="let sendByNotification of SEND_BY_NOTIFICATIONS">
          <ng-container *ngIf="!sendByNotification.hide">
            <div class="field toggle" [class.border]="!sendByNotification.default">
              {{ sendByNotification.title | translate }}
              <tui-toggle
                [formControl]="getFormControl(formGroupName, sendByNotification.code)"
                [showIcons]="true"
                [tuiTextfieldSize]="'m'"
              ></tui-toggle>
              <div *ngIf="!!sendByNotification.description" class="description">{{ sendByNotification.description | translate }}</div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</form>
