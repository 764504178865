<a class="logo__wrapper" routerLink="/">
  <div class="logo__layout">
    <app-branding-logo class="logo__image" [width]="40" [height]="40"></app-branding-logo>
    <span *ngIf="isOpen && !isBrand" class="logo__label">Unions</span>
  </div>
</a>

<tui-scrollbar class="navigation__wrap" [class.navigation__wrap_min]="!isOpen">
  <nav class="navigation">
    <ng-container *ngFor="let item of items">
      <ng-container *ngxPermissionsOnly="item.permissions || []">
        <ng-container *tuiLet="item.badge | async as badgeValue">
          <tui-badged-content
            [colorTop]="!isOpen && badgeValue ? '#42befea6' : ''"
            size="s"
            [contentTop]="!isOpen ? badgeValue : ''"
          >
            <a
              class="navigation-item navigation-item_active"
              [routerLink]="item.link"
              [class.navigation-item_active]="item.selectedItem"
              tuiHintDescribe="navigation"
              tuiHintDirection="right"
              [tuiHint]="isOpen ? '' : (item.title | translate)"
              [tuiHintShowDelay]="300"
              [tuiHintHideDelay]="0"
              (click)="item.action && item.action()"
            >
              <lucide-icon *ngIf="item.icon" [name]="item.icon"></lucide-icon>

              <ng-container *ngIf="isOpen">
                <span class="navigation-item__title">
                  {{ item.title | translate }}
                </span>
                <tui-badge
                  *ngIf="badgeValue"
                  class="navigation-item__badge"
                  size="s"
                  status="custom"
                  [value]="badgeValue"
                ></tui-badge>
              </ng-container>
            </a>
          </tui-badged-content>
        </ng-container>
      </ng-container>
    </ng-container>
  </nav>
</tui-scrollbar>

<div
  *ngIf="currentParentOrganisation && membershipStatus"
  class="message"
  [class.message_min]="!isOpen"
  [ngClass]="isMembershipPaid ? 'message_info' : 'message_warning'"
  tuiHintDescribe="message"
  tuiHintDirection="right"
  [tuiHint]="isOpen ? '' : membershipStatus"
  [tuiHintShowDelay]="300"
  [tuiHintHideDelay]="0"
>
  <lucide-icon class="message__icon" name="alert-circle"></lucide-icon>
  <span *ngIf="isOpen" class="message__title">{{ membershipStatus }}</span>
</div>

<tui-hosted-dropdown
  *ngIf="!isBrand && currentParentOrganisation"
  class="association-menu__wrap"
  [content]="associationMenuTmpl"
  [sided]="true"
>
  <div class="association-menu">
    <tui-avatar
      class="association-menu__icon"
      size="m"
      [autoColor]="!currentParentOrganisation.photoId"
      [avatarUrl]="currentParentOrganisation.photoId | getImageSrc"
      [text]="currentParentOrganisation.shortName || ''"
    ></tui-avatar>
    <div *ngIf="isOpen" class="association-menu__text-box">
      <div *ngIf="currentParentOrganisation.shortName" class="association-menu__title">
        {{ currentParentOrganisation.shortName }}
      </div>
      <div *ngIf="currentParentOrganisation.fullName" class="association-menu__subtitle">
        {{ currentParentOrganisation.fullName }}
      </div>
    </div>
  </div>
</tui-hosted-dropdown>

<a class="version-link" routerLink="/settings/changelog">{{ appVersion }}</a>

<ng-template #associationMenuTmpl>
  <tui-data-list *ngIf="parentOrganisations" class="association-menu-list">
    <tui-opt-group [label]="'components.navigation.labels.associationMenu' | translate">
      <a
        *ngFor="let parentOrganisation of parentOrganisations"
        tuiOption
        class="association-menu-item"
        (click)="emitCurrentParentOrganisation(parentOrganisation)"
      >
        <tui-badged-content
          size="xs"
          [colorTop]="parentOrganisation.id === currentParentOrganisation?.id ? 'var(--tui-success-fill)' : ''"
        >
          <tui-avatar
            class="association-menu-item__icon"
            size="xs"
            [autoColor]="!parentOrganisation.photoId"
            [avatarUrl]="parentOrganisation.photoId | getImageSrc"
            [text]="parentOrganisation.shortName || ''"
          ></tui-avatar>
        </tui-badged-content>
        <span class="association-menu-item__title">
          {{ parentOrganisation.shortName || '' }}
        </span>
      </a>
    </tui-opt-group>
  </tui-data-list>
</ng-template>
