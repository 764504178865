import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiMultiSelectModule } from '@taiga-ui/kit';
import { TuiDropdownModule, TuiLoaderModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import { AccordionLevelsModule } from '@src/app/modules/accordion-levels';
import { SharedModule } from '@src/app/shared';

import { CustomNamesTabsModule } from '../custom-name-tabs';

import { CommitteeFieldComponent } from './committee-field.component';

@NgModule({
  declarations: [CommitteeFieldComponent],
  imports: [
    AccordionLevelsModule,
    CommonModule,
    FormsModule,
    SharedModule,
    TuiActiveZoneModule,
    TuiDropdownModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiMultiSelectModule,
    TuiTextfieldControllerModule,
    CustomNamesTabsModule,
  ],
  exports: [CommitteeFieldComponent],
})
export class CommitteeFieldModule {}
