import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseSearchService<FilterType, ReturnType> {
  filter$: BehaviorSubject<FilterType | null> = new BehaviorSubject<FilterType | null>(null);
  abstract search(filter: FilterType): Observable<ReturnType[]>;
}
