import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { NameValuePair, OrganisationView, UsersSearchParameters } from '@src/api';
import { BusinessTypeUI, JobTitleUI } from '@src/models';
import { BusinessTypesService, JobTitleService, OrganisationService } from '@src/core/services';
import { DictionaryService } from '@src/app/modules/dictionaries';

import { FieldsOptions } from './filter.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnInit, OnChanges, OnDestroy {
  filterLocal: UsersSearchParameters = {};
  @Input() set filter(filter: UsersSearchParameters) {
    // создаем копию
    this.filterLocal = JSON.parse(JSON.stringify(filter));
  }
  @Input() includeFields: FieldsOptions[] = [];
  @Input() excludeFields: FieldsOptions[] = [];
  @Input() size: TuiSizeL | TuiSizeS = 'm';
  @Output() filterChange: EventEmitter<UsersSearchParameters> = new EventEmitter();
  @Output() applyFilter: EventEmitter<void> = new EventEmitter();
  @Output() cancelFilter: EventEmitter<void> = new EventEmitter();

  fields: FieldsOptions[] = ['committee', 'jobTitle', 'organisation', 'businessType'];

  selectedJobTitles: JobTitleUI[] = [];
  jobTitles$: BehaviorSubject<JobTitleUI[]> = this.jobTitleService.jobTitles$;
  allJobTitlesSelected: boolean = false;

  selectedCommitteeIds: string[] = [];

  selectedOrganisations?: OrganisationView[] = [];
  organisations$: BehaviorSubject<OrganisationView[] | null> = this.organisationService.organisationList$;

  selectedBusinessTypes?: BusinessTypeUI[] = [];
  businessTypes$: BehaviorSubject<BusinessTypeUI[] | null> = this.businessTypesService.businessTypes$;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private businessTypesService: BusinessTypesService,
    private jobTitleService: JobTitleService,
    private organisationService: OrganisationService,
    private dictionaryService: DictionaryService,
  ) {}

  ngOnInit(): void {
    this.jobTitles$.pipe(takeUntil(this.destroyed$$)).subscribe(jobTitles => {
      this.selectedJobTitles = jobTitles.filter(jobTitle => {
        return (
          this.filterLocal &&
          this.filterLocal.jobTitleIds &&
          jobTitle.jobTitleId &&
          this.filterLocal.jobTitleIds.includes(jobTitle.jobTitleId)
        );
      });

      this.onChangeSelectedJobTitle();

      this.cdr.markForCheck();
    });
    this.jobTitleService.getJobTitles();

    this.organisations$.pipe(takeUntil(this.destroyed$$)).subscribe(organisations => {
      this.selectedOrganisations = organisations?.filter(organisation => {
        return (
          this.filterLocal &&
          this.filterLocal.organisationIds &&
          organisation.id &&
          this.filterLocal.organisationIds.includes(organisation.id)
        );
      });
      this.cdr.markForCheck();
    });
    this.organisationService.getOrganisationList();

    this.businessTypes$.pipe(takeUntil(this.destroyed$$)).subscribe(businessTypes => {
      this.selectedBusinessTypes = businessTypes?.filter(businessType => {
        return (
          this.filterLocal &&
          this.filterLocal.businessTypeIds &&
          businessType.businessTypeId &&
          this.filterLocal.businessTypeIds.includes(businessType.businessTypeId)
        );
      });

      this.onChangeSelectedBusinessType();

      this.cdr.markForCheck();
    });
    this.businessTypesService.getBusinessTypes();

    this.selectedCommitteeIds = this.filterLocal.committeeIds ?? [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.includeFields && this.includeFields.length) {
      this.fields = this.fields.filter(field => this.includeFields.includes(field));
    }

    if (changes.excludeFields && this.excludeFields.length) {
      this.fields = this.fields.filter(field => !this.excludeFields.includes(field));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  get contacts(): Array<NameValuePair> {
    return this.filterLocal.contacts || [];
  }

  onChangeSelectedJobTitle() {
    if (!this.filterLocal) return;

    this.allJobTitlesSelected = this.selectedJobTitles.length === this.jobTitles$.value.length;

    const jobTitleIds: string[] = [];
    this.selectedJobTitles.forEach(jobTitle => {
      if (jobTitle.jobTitleId) jobTitleIds.push(jobTitle.jobTitleId);
    });

    this.emitFilterChange({ jobTitleIds });
  }

  onChangeSelectedOrganisation() {
    if (!this.filterLocal) return;

    const organisationIds: string[] = [];
    this.selectedOrganisations?.forEach(organisation => {
      if (organisation.id) organisationIds.push(organisation.id);
    });

    this.emitFilterChange({ organisationIds });
  }

  onChangeSelectedBusinessType() {
    if (!this.filterLocal) return;

    const businessTypeIds: string[] = [];
    this.selectedBusinessTypes?.forEach(businessType => {
      if (businessType.businessTypeId) businessTypeIds.push(businessType.businessTypeId);
    });

    this.emitFilterChange({ businessTypeIds });
  }

  onChangeSelectedCommitteeIds(committeeIds: string[]) {
    this.emitFilterChange({ committeeIds });
  }

  onChangeSelectAllJobTitles() {
    this.selectedJobTitles = this.allJobTitlesSelected ? this.jobTitles$.value : [];

    this.onChangeSelectedJobTitle();
  }

  readonly stringifyJobTitle: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return 'jobTitleName' in item ? item?.jobTitleName : item?.$implicit.jobTitleName;
  };
  readonly stringifyOrganisation: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return 'shortName' in item ? item.shortName : item.$implicit.shortName;
  };
  readonly stringifyBusinessType: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return 'businessTypeName' in item ? item?.businessTypeName : item?.$implicit.businessTypeName;
  };

  showBusinessTypesDictionary(): void {
    this.dictionaryService.showBusinessTypes().pipe(takeUntil(this.destroyed$$)).subscribe();
  }

  showJobTitlesDictionary(): void {
    this.dictionaryService.showJobTitles().pipe(takeUntil(this.destroyed$$)).subscribe();
  }

  onApplyFilter() {
    this.filterChange.emit(this.filterLocal);
    this.applyFilter.emit();
  }

  onCancelFilter() {
    this.cancelFilter.emit();
  }

  private emitFilterChange(filter: UsersSearchParameters) {
    // TODO: заменить на метод без параметров. все данные брать из переменных с двухсторонней связкой
    this.filterLocal = {
      ...this.filterLocal,
      ...filter,
    };
  }
}
