import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  TuiAvatarModule,
  TuiInputModule,
  TuiMultiSelectModule,
  TuiDataListWrapperModule,
  TuiCheckboxBlockModule,
  TuiInputFilesModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { DictionariesModule } from '@src/app/modules/dictionaries';
import { CommitteeFieldModule } from '@src/app/modules/committee-field';
import { CustomNamesTabsModule } from '@src/app/modules/custom-name-tabs';

import { SharedModule } from '../shared.module';

import { FilterComponent } from './filter.component';

@NgModule({
  declarations: [FilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TuiAvatarModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiDropdownModule,
    TuiInputFilesModule,
    TuiInputModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiMultiSelectModule,
    TuiTextfieldControllerModule,
    DictionariesModule,
    CommitteeFieldModule,
    TuiCheckboxBlockModule,
    TuiScrollbarModule,
    TuiButtonModule,
    CustomNamesTabsModule,
  ],
  exports: [FilterComponent],
})
export class FilterModule {}
