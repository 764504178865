import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TuiAvatarModule, TuiInputModule, TuiMultiSelectModule, TuiDataListWrapperModule, TuiInputFilesModule } from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiActiveZoneModule, TuiAutoFocusModule, TuiLetModule } from '@taiga-ui/cdk';

import { SharedModule } from '../shared.module';
import { FilterModule } from '../filter';

import { SearchUsersComponent } from './search-users.component';

@NgModule({
  declarations: [SearchUsersComponent],
  imports: [
    CommonModule,
    FilterModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatGridListModule,
    MatListModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    RouterModule,
    TuiActiveZoneModule,
    TuiAutoFocusModule,
    TuiAvatarModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiDropdownModule,
    TuiInputFilesModule,
    TuiInputModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiMultiSelectModule,
    TuiScrollbarModule,
    TuiTextfieldControllerModule,
    SharedModule,
  ],
  exports: [SearchUsersComponent],
})
export class SearchUsersModule {}
