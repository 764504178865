import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Injector,
  Input,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent, PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { EnvService } from '@src/app/modules/env';
import { AlertService, DocumentService, PhotoService, PollService, UserService } from '@src/core/services';
import { PollUI, TuiFileLikeUI, UserUI, ViewMode } from '@src/models';
import {
  DocumentView,
  EditAnswerOption,
  EditQuestion,
  PollEditWithParticipants,
  PollFullView,
  QuestionView,
} from '@src/api';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { normalizeFileName } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-poll-info',
  templateUrl: './poll-info.component.html',
  styleUrls: ['./poll-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PollInfoComponent implements OnChanges, OnDestroy {
  @Input() mode: ViewMode = 'view';
  @Input() pollId?: string;
  @Input() defaultData?: PollFullView | null;
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() saved: EventEmitter<string> = new EventEmitter();
  @Output() deleted: EventEmitter<void> = new EventEmitter();
  @ViewChild('confirmEditDialogTemplate') confirmEditDialogTemplate?: PolymorpheusContent<TuiDialogContext>;

  data$: BehaviorSubject<PollFullView | null> = this.pollService.poll$;
  tuiFiles$: BehaviorSubject<TuiFileLikeUI[] | null> = this.documentService.tuiFiles$;
  documents$: BehaviorSubject<DocumentView[] | null> = this.documentService.documents$;
  membersList$: BehaviorSubject<UserUI[] | null> = this.userService.membersOfPoll$;
  readonly organisationId?: string;
  allowEditing$?: Observable<boolean | undefined>;
  allowMembersViewing$?: Observable<boolean | undefined>;
  allowStatisticsViewing$?: Observable<boolean | undefined>;
  pollQuestions?: QuestionView[];
  notifyEveryone: boolean = false;
  loading = false;
  recallButtonLoading = false;

  private allowStatisticsViewing?: boolean;
  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmCancelEditingDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.undoEditHeader'),
      size: 's',
      closeable: false,
    },
  );
  private readonly confirmPollDeletingDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.pollInfo.dialogs.pollDeleteHeader'),
      size: 's',
      closeable: false,
    },
  );
  private readonly confirmPollDecliningDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.pollInfo.dialogs.pollDeclineHeader'),
      size: 's',
      closeable: false,
    },
  );
  private readonly confirmRecallNotificationsDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.pollInfo.dialogs.resendNotificationsHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private pollService: PollService,
    private photoService: PhotoService,
    private userService: UserService,
    private documentService: DocumentService,
    private ngxPermissionsService: NgxPermissionsService,
    private env: EnvService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    this.organisationId = this.userService.authUser?.organisationId;
    this.data$.pipe(takeUntil(this.destroyed$$)).subscribe(poll => {
      this.allowEditing$ = this.userService.authUser$.pipe(
        map(user => {
          if (!user || !poll) return false;

          const permissions = this.ngxPermissionsService.getPermissions();

          return 'pollEditing' in permissions || ('onlyYourPollEditing' in permissions && user.id === poll.createdBy);
        }),
      );

      this.allowMembersViewing$ = this.userService.authUser$.pipe(
        map(user => {
          if (!user || !poll) return false;

          const permissions = this.ngxPermissionsService.getPermissions();

          return 'pollMembersViewing' in permissions;
        }),
      );

      this.allowStatisticsViewing$ = this.userService.authUser$.pipe(
        map(user => {
          if (!user || !poll) return false;

          const permissions = this.ngxPermissionsService.getPermissions();

          this.allowStatisticsViewing =
            'pollStatisticsViewing' in permissions ||
            ('onlyYourPollStatisticsViewing' in permissions && user.id === poll.createdBy);

          return this.allowStatisticsViewing;
        }),
      );
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.pollId) {
      this.pollService.resetPoll();
      if (this.pollId) {
        this.mode = 'view';
        this.getAllData(this.pollId);
      }
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.prepareDataForCreateMode();

        if (this.defaultData) {
          this.data$.next(this.defaultData);
        }
      }
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.pollService.resetPoll();
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onStartEditing() {
    this.mode = 'edit';
  }

  onSaveData(data: PollUI | null): void {
    if (!data || !this.organisationId) return;

    this.loading = true;

    data.organisationId = this.organisationId;
    if (this.mode === 'edit') {
      if (!this.confirmEditDialogTemplate) return;
      this.notifyEveryone = false;

      this.dialogService
        .open(this.confirmEditDialogTemplate, {
          size: 's',
          closeable: false,
          dismissible: false,
        })
        .pipe(takeUntil(this.destroyed$$))
        .subscribe({
          complete: () => {
            data.notifyEveryone = this.notifyEveryone;
            this.editPoll(data);
          },
        });
    } else if (this.mode === 'create') {
      this.createPoll(data);
    }
  }

  onCancel(): void {
    this.confirmCancelEditingDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.canceled.emit();

          if (this.pollId) {
            this.pollService.resetPoll();
            this.getAllData(this.pollId);
          }

          this.mode = 'view';
        }
      },
    });
  }

  startPoll(): void {
    if (this.pollId) {
      this.pollService
        .allQuestions(this.pollId)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(questions => {
          this.pollQuestions = questions;
          this.mode = 'execute';
          this.cdr.markForCheck();
        });
    }
  }

  async onCopyPoll() {
    if (!this.pollId) {
      return;
    }

    const currentPollData = this.data$.value;

    const initData: Pick<PollFullView, 'titleText' | 'descriptionText' | 'committeeId'> = {
      titleText: currentPollData?.titleText,
      descriptionText: currentPollData?.descriptionText,
      committeeId: currentPollData?.committeeId,
    };

    this.mode = 'create';
    this.prepareDataForCreateMode();

    try {
      let question: QuestionView[] = [];

      try {
        question = (await this.pollService.allQuestionsAsync(this.pollId)) ?? [];
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      this.data$.next({
        ...initData,
        questions: question.map<EditQuestion>(question => {
          return {
            answerTypeId: question.answerTypeId,
            sortOrder: question.sortOrder,
            text: question.text,
            answers: question.answers
              ?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
              .map<EditAnswerOption>(answer => {
                return {
                  textValue: answer.textValue,
                  sortOrder: answer.sortOrder,
                  isAlternative: answer.isAlternative,
                };
              }),
          };
        }),
      });
    } finally {
      this.cdr.detectChanges();
    }
  }

  stopPoll(): void {
    if (this.pollId) {
      this.pollService.resetPoll();
      this.getAllData(this.pollId);
      this.pollService.getPolls();
    }
    this.mode = 'view';
    this.cdr.markForCheck();
  }

  getStatistics() {
    if (this.pollId) {
      if (this.allowStatisticsViewing) {
        this.pollService
          .getAdminStatistics(this.pollId, this.env.isBot)
          .pipe(takeUntil(this.destroyed$$))
          .subscribe(doc => {
            this.downloadFile(
              doc,
              `${this.translateService.instant('components.pollInfo.labels.statisticsFileName', {
                pollName: normalizeFileName(this.data$.value?.titleText),
              })}.zip`,
            ); // TODO: автоматически определять расширение по doc.type
          });
      } else {
        this.pollService
          .getStatistics(this.pollId, this.env.isBot)
          .pipe(takeUntil(this.destroyed$$))
          .subscribe(doc => {
            this.downloadFile(
              doc,
              `${this.translateService.instant('components.pollInfo.labels.statisticsFileName', {
                pollName: normalizeFileName(this.data$.value?.titleText),
              })}.xlsx`,
            ); // TODO: автоматически определять расширение по doc.type
          });
      }
    }
  }

  async declinePoll(): Promise<void> {
    this.confirmPollDecliningDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: async res => {
        if (res) {
          if (this.pollId) {
            await lastValueFrom(this.pollService.declinePoll(this.pollId));
            this.getAllData(this.pollId);
            await this.pollService.getPolls();
            this.alertService.success(
              this.translateService.instant('components.pollInfo.alerts.successes.declinePoll'),
            );
          }
        }
      },
    });
  }

  deletePoll(pollId: string) {
    this.confirmPollDeletingDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          lastValueFrom(this.pollService.deletePoll(pollId)).then(() => {
            this.pollService.getPolls();
            this.pollService.resetPoll();
            this.deleted.emit();
          });
        }
      },
    });
  }

  resendNotifications(memberIds: string[]) {
    if (!memberIds.length) {
      return;
    }

    this.confirmRecallNotificationsDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          if (!this.pollId) {
            this.alertService.error(this.translateService.instant('components.pollInfo.alerts.errors.pollNotFound'), {
              autoClose: 10 * 1000,
            });
            return;
          }

          this.recallButtonLoading = true;
          lastValueFrom(this.pollService.resendNotifications(this.pollId, memberIds)).finally(() => {
            this.recallButtonLoading = false;
            this.cdr.markForCheck();
          });
        }
      },
    });
  }

  private createPoll(data: PollUI): void {
    this.loading = true;

    this.pollService
      .createPoll(data)
      .pipe(
        catchError(async () => {
          this.loading = false;
          this.pollService.resetPoll();
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(res => {
        if (!res?.id) return;

        this.mode = 'view';
        this.pollService.resetPoll();

        data.id = res.id;
        this.startUploading(data);
      });
  }

  private editPoll(data: PollUI): void {
    if (!data.id) return;

    this.pollService
      .editPoll(data as PollEditWithParticipants)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(() => {
        this.mode = 'view';
        this.pollService.resetPoll();

        this.startUploading(data);
      });
  }

  private startUploading(data: PollUI) {
    const uploadObs = [];
    if (data.photo && data.id) {
      uploadObs.push(this.uploadPhoto(data.photo, data.id, true));
    }
    if (data.deleteDocuments?.length) {
      uploadObs.push(...this.deleteDocuments(data.deleteDocuments));
    }
    if (data.newDocuments?.length) {
      uploadObs.push(this.uploadDocuments(data.newDocuments, data.id));
    }

    if (uploadObs.length) {
      forkJoin(uploadObs)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(() => {
          if (!data.id) return;

          this.getAllData(data.id);
          this.saved.emit(data.id);
          this.cdr.markForCheck();
        });
    } else {
      if (!data.id) return;

      this.getAllData(data.id);
      this.saved.emit(data.id);
      this.cdr.markForCheck();
    }
  }

  private uploadPhoto(photo: File, attachId: string, isCover: boolean) {
    if (!photo) return;

    return this.photoService.uploadPhoto(photo, attachId, isCover);
  }

  private uploadDocuments(documents?: File[], attachId?: string) {
    if (!documents?.length || !attachId) return;

    return this.documentService.addDocuments(documents, attachId, 13);
  }

  private deleteDocuments(documents?: TuiFileLikeUI[]) {
    if (!documents?.length) {
      return [];
    }

    const ids = documents.map(document => document.id!);
    return this.documentService.deleteDocuments(ids);
  }

  private downloadFile(doc: Blob, fileName: string) {
    if (this.env.isBot) {
      this.env.sendDownloadNotification();
    } else {
      this.documentService.downloadDocument(doc, fileName);
    }
  }

  private getAllData(pollId: string) {
    this.pollService.getPoll(pollId, value => {
      this.loading = value;
      this.cdr.markForCheck();
    });
    this.documentService.getDocuments(pollId);

    const permissions = this.ngxPermissionsService.getPermissions();

    if ('pollMembersViewing' in permissions) {
      this.userService.getUsersByPollId(pollId);
    }
  }

  private prepareDataForCreateMode() {
    this.pollService.resetPoll();
    this.documentService.resetDocuments();
    this.userService.resetMembersOfPoll();
  }
}
