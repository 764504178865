/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Сводная статистика для объявлений.
 */
export interface LoyaltyStatisticsSummary { 
    /**
     * Дата начала выборки статистики.
     */
    dateStart?: string;
    /**
     * Дата конца  выборки статистики.
     */
    dateEnd?: string;
    /**
     * ID объединения.
     */
    parentOrganisationId?: string;
    /**
     * Кол-во продуктов ПЛ.
     */
    productsCount?: number;
    /**
     * Кол-во записей с action = \"list\".
     */
    listCount?: number;
    /**
     * Кол-во записей с action = \"list\" с уникальными userId.
     */
    uniqueListCount?: number;
    /**
     * Кол-во записей с action = \"read\".
     */
    readCount?: number;
    /**
     * Кол-во записей с action = \"read\" с уникальными userId.
     */
    uniqueReadCount?: number;
    /**
     * Кол-во записей с action = \"discount\".
     */
    discountsUsedCount?: number;
    /**
     * Кол-во записей с action = \"discount\" с уникальными userId.
     */
    uniqueDiscountsUsedCount?: number;
    /**
     * Кол-во записей с action = \"link\".
     */
    linksVisitedCount?: number;
    /**
     * Показатели для записей, созданных в текущий календарный день.
     */
    todayListCount?: number;
    /**
     * Показатели для записей, созданных в текущий календарный день, с уникальными userId.
     */
    todayUniqueListCount?: number;
    /**
     * Показатели для записей, созданных в текущий календарный день.
     */
    todayReadCount?: number;
    /**
     * Показатели для записей, созданных в текущий календарный день, с уникальными userId.
     */
    todayUniqueReadCount?: number;
    /**
     * Кол-во записей с action = \"discount\", созданных в текущий календарный день.
     */
    todayDiscountsUsedCount?: number;
    /**
     * Кол-во записей с action = \"link\", созданных в текущий календарный день.
     */
    todayLinksVisitedCount?: number;
}

