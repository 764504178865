import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import {
  CCService,
  Id,
  InsertTypeOfObjectDTO,
  TextMessage,
  TypeOfObjectTree,
  TypeOfObjectWithGroupsFieldsDTO,
} from '@src/api';
import { AlertService } from '@src/core/services';

export interface LoadConfigureDictionaryDataRequest {
  dictionaries: number;
  dictionaryId: string | null;
}

export interface LoadConfigureDictionaryDataResponse {
  dictionaries: TypeOfObjectTree[] | null;
  dictionary: TypeOfObjectWithGroupsFieldsDTO | null;
}

@Injectable({
  providedIn: 'root',
})
export class CooperationChainsService implements OnDestroy {
  loadDictionaries$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  loadDictionary$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  loadConfigureDictionaryData$: BehaviorSubject<LoadConfigureDictionaryDataRequest | null> =
    new BehaviorSubject<LoadConfigureDictionaryDataRequest | null>(null);
  startCreateDictionary$: BehaviorSubject<InsertTypeOfObjectDTO | null> =
    new BehaviorSubject<InsertTypeOfObjectDTO | null>(null);
  startDeleteDictionary$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private readonly alertService: AlertService, private readonly cCService: CCService) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  startGetDictionaries(request: number | null): void {
    this.loadDictionaries$.next(request);
  }

  startGetConfigureDictionaryData(loadDictionaryId: string | null | undefined): void {
    const request: LoadConfigureDictionaryDataRequest | null =
      loadDictionaryId === null
        ? null
        : {
            dictionaries: new Date().getTime(),
            dictionaryId: loadDictionaryId || null,
          };
    this.loadConfigureDictionaryData$.next(request);
  }

  getConfigureDictionaryData(): Observable<LoadConfigureDictionaryDataResponse> {
    return this.loadConfigureDictionaryData$
      .pipe(takeUntil(this.destroyed$$))
      .pipe(
        filter(loadConfigureDictionaryData => loadConfigureDictionaryData !== null),
        switchMap(loadConfigureDictionaryData => {
          return forkJoin([
            this.getDictionariesData(),
            this.getDictionaryData(loadConfigureDictionaryData?.dictionaryId || null),
          ]);
        }),
      )
      .pipe(
        map(([dictionaries, dictionary]) => {
          return { dictionaries: dictionaries, dictionary: dictionary };
        }),
      );
  }

  getDictionaries(): Observable<TypeOfObjectTree[] | null> {
    return this.loadDictionaries$.pipe(
      takeUntil(this.destroyed$$),
      filter(loadDictionaries => loadDictionaries !== null),
      switchMap(loadDictionaries => {
        return this.getDictionariesData();
      }),
    );
  }

  getDictionariesData(): Observable<TypeOfObjectTree[] | null> {
    return this.cCService
      .getTypeOfObjectTree()
      .pipe(
        map(response => {
          return response;
        }),
      )
      .pipe(
        catchError(err => {
          // TODO show error notification
          this.alertService.error('Ошибка загрузки справочников');
          return of(null);
        }),
      );
  }

  startGetDictionary(loadDictionaryId: string | null): void {
    this.loadDictionary$.next(loadDictionaryId);
  }

  getDictionary(): Observable<TypeOfObjectWithGroupsFieldsDTO | null> {
    return this.loadDictionary$.pipe(
      takeUntil(this.destroyed$$),
      filter(loadDictionaryId => loadDictionaryId !== null),
      switchMap(loadDictionaryId => {
        return this.getDictionaryData(loadDictionaryId);
      }),
    );
  }

  getDictionaryData(dictionaryId: string | null): Observable<TypeOfObjectWithGroupsFieldsDTO | null> {
    if (dictionaryId) {
      return this.cCService.getTypeOfObjectById(dictionaryId).pipe(
        catchError(err => {
          // TODO show error notification
          this.alertService.error('Ошибка загрузки справочников');
          return of(null);
        }),
      );
    } else {
      return of(null);
    }
  }

  startCreateDictionary(insertTypeOfObjectDTO: InsertTypeOfObjectDTO | null): void {
    this.startCreateDictionary$.next(insertTypeOfObjectDTO);
  }

  createDictionary(): Observable<Id | null> {
    return this.startCreateDictionary$.pipe(
      takeUntil(this.destroyed$$),
      filter(insertTypeOfObjectDTO => insertTypeOfObjectDTO !== null),
      switchMap(insertTypeOfObjectDTO => {
        return this.cCService.insertTypeOfObject(insertTypeOfObjectDTO!).pipe(
          catchError(err => {
            // TODO handle error
            this.alertService.error('Ошибка сохранения справочника');
            return of(null);
          }),
        );
      }),
    );
  }

  deleteDictionary(): Observable<TextMessage | null> {
    return this.startDeleteDictionary$.pipe(
      takeUntil(this.destroyed$$),
      filter(dictionaryId => dictionaryId !== null),
      switchMap(dictionaryId => {
        return this.cCService.deleteTypeOfObject(dictionaryId!).pipe(
          catchError(err => {
            // TODO handle error
            this.alertService.error('Ошибка удаления справочника');
            return of(null);
          }),
        );
      }),
    );
  }

  startDeleteDictionary(dictionaryId: string | null): void {
    this.startDeleteDictionary$.next(dictionaryId);
  }

  getDictionaryItem(
    parentItem: TypeOfObjectTree | undefined,
    selectedItemId: string,
    dictionaries: TypeOfObjectTree[],
  ): { item: TypeOfObjectTree; parentItem: TypeOfObjectTree | undefined } | undefined {
    let selectedItem: { item: TypeOfObjectTree; parentItem: TypeOfObjectTree | undefined } | undefined;
    selectedItem = this.getSelectedItem(parentItem, selectedItemId, dictionaries);
    return selectedItem;
  }

  getSelectedItem(
    parentItem: TypeOfObjectTree | undefined,
    selectedItemId: string,
    items: TypeOfObjectTree[],
  ): { item: TypeOfObjectTree; parentItem: TypeOfObjectTree | undefined } | undefined {
    let selectedItem: { item: TypeOfObjectTree; parentItem: TypeOfObjectTree | undefined } | undefined;
    for (const item of items) {
      if (item.id === selectedItemId) {
        return { item: item, parentItem: parentItem };
      } else {
        if (!!item.children) {
          selectedItem = this.getSelectedItem(item, selectedItemId, item.children);
          if (!!selectedItem) {
            return selectedItem;
          }
        }
      }
      if (!!selectedItem) {
        break;
      }
    }
    return selectedItem;
  }
}
