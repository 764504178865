import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  Inject,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observer } from 'rxjs';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { EnvService } from '@src/app/modules/env';
import { isValidEmail, isValidUrl } from '@src/utils';

import { HTMLStructure, ParsedTextType } from './types';

@Component({
  selector: 'app-parse-links',
  templateUrl: './parse-links.component.html',
  styleUrls: ['./parse-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParseLinksComponent implements OnDestroy {
  @ViewChild('root') rootElement?: ElementRef;

  @ViewChild('tmpRedirectToUrl') redirectToUrlContent?: PolymorpheusContent<TuiDialogContext>;

  @Input() showBorder = false;

  @Input() set value(text: string | null) {
    this.preparedHTMLStructure = (text ?? '').split('\n').reduce<HTMLStructure[]>((acc, line, _, endOfLineArray) => {
      line.split(' ').forEach((value, _, spaceArray) => {
        let type: ParsedTextType = 'text';
        let url = '';

        if (isValidEmail(value)) {
          type = 'email';
        } else {
          url = isValidUrl(value);
          if (url) {
            type = 'url';
          }
        }

        acc.push({ type, value, url });

        if (spaceArray.length > 1) {
          acc.push({ type: 'space' });
        }
      });

      if (endOfLineArray.length > 1) {
        acc.push({ type: 'new-line' });
      }

      return acc;
    }, []);
  }

  @Output() externalLink: EventEmitter<string> = new EventEmitter<string>();

  preparedHTMLStructure: HTMLStructure[] = [];

  href = '';

  constructor(
    private readonly env: EnvService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {
    this.onClick = this.onClick.bind(this);
  }

  ngOnDestroy(): void {
    this.rootElement?.nativeElement.removeEventListener('click', this.onClick);
  }

  private onClick(event: MouseEvent) {
    if (this.env.isBot) {
      // Под ботом не обрабатываем нажатие по ссылке, т.к. Telegram сам спрашивает подтверждение перехода
      return;
    }

    if (event.target && 'href' in event.target) {
      const href: string = event.target['href'] ?? '';

      if (href && !href.startsWith('mailto:')) {
        this.href = event.target['title'] ?? href;

        event.preventDefault();
        event.stopPropagation();

        this.showPrompt();
      }
    }
  }

  ngAfterViewInit() {
    this.rootElement?.nativeElement.addEventListener('click', this.onClick);
  }

  private showPrompt() {
    if (!this.redirectToUrlContent) {
      return;
    }

    this.dialogService
      .open(this.redirectToUrlContent, {
        closeable: false,
        dismissible: true,
      })
      .subscribe();
  }

  gotoExternalLink(observer: Observer<void>) {
    observer.complete();
    this.externalLink.emit(this.href);

    setTimeout(() => {
      this.env.openLink(this.href);
    }, 300);
  }
}
