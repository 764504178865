import {
  GroupUI,
  ChatMemberModel,
  ChatModel,
  EventViewUI,
  PollUI,
  UserUI,
  CommitteeUI,
  OrganisationUI,
} from '@src/models';

export type ListTypes = 'channels' | 'chats' | 'committees' | 'events' | 'organisations' | 'polls' | 'users';
export type ItemType =
  | GroupUI
  | ChatMemberModel
  | ChatModel
  | CommitteeUI
  | EventViewUI
  | OrganisationUI
  | PollUI
  | UserUI
  | null;
export type ActionIconTypes = 'tuiIconXLarge' | 'tuiIconMessageSquareLarge';
export type ActionTypes = 'delete' | 'subscribe' | 'unsubscribe' | 'send' | 'link';

export interface ItemAction {
  type: ActionTypes;
  icon?: ActionIconTypes;
  title?: string;
  isVisible?: (item: ItemType) => boolean;
  clickCallback: (item?: ItemType) => void;
}
