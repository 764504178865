<tui-scrollbar class="scrollbar">
  <div class="filter">
    <app-with-dictionary-button-wrapper (showDictionary)="showJobTitlesDictionary()">
      <tui-multi-select
        *ngIf="fields.includes('jobTitle')"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="true"
        [expandable]="false"
        [editable]="false"
        [tuiTextfieldCleaner]="true"
        [(ngModel)]="selectedJobTitles"
        [stringify]="stringifyJobTitle"
        (ngModelChange)="onChangeSelectedJobTitle()"
      >
        {{ 'field.jobTitle' | customNames }}
        <tui-data-list *tuiDataList tuiMultiSelectGroup>
          <tui-loader *tuiLet="jobTitles$ | async as jobTitles" [inheritColor]="true" [showLoader]="!jobTitles">
            <div class="list-header">
              <tui-checkbox-block
                class="select-all"
                size="m"
                contentAlign="left"
                [(ngModel)]="allJobTitlesSelected"
                (ngModelChange)="onChangeSelectAllJobTitles()"
              >
                {{
                  allJobTitlesSelected
                    ? ('components.filter.labels.reset' | translate)
                    : ('components.filter.labels.chooseAll' | translate)
                }}
              </tui-checkbox-block>
            </div>
            <button *ngFor="let jobTitle of jobTitles" tuiOption class="list-item" [value]="jobTitle">
              <div>
                <div class="list-item__name">{{ jobTitle.jobTitleName }}</div>
              </div>
            </button>
          </tui-loader>
        </tui-data-list>
      </tui-multi-select>
    </app-with-dictionary-button-wrapper>

    <app-with-dictionary-button-wrapper (showDictionary)="showBusinessTypesDictionary()">
      <tui-multi-select
        *ngIf="fields.includes('businessType')"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="true"
        [expandable]="false"
        [editable]="false"
        [tuiTextfieldCleaner]="true"
        [(ngModel)]="selectedBusinessTypes"
        (ngModelChange)="onChangeSelectedBusinessType()"
        [stringify]="stringifyBusinessType"
      >
        {{ 'components.filter.fields.businessTypes' | translate }}
        <tui-data-list *tuiDataList tuiMultiSelectGroup>
          <tui-loader
            *tuiLet="businessTypes$ | async as businessTypes"
            [inheritColor]="true"
            [showLoader]="!businessTypes"
          >
            <button *ngFor="let businessType of businessTypes" class="list-item" tuiOption [value]="businessType">
              <div>
                <div class="list-item__name">{{ businessType.businessTypeName }}</div>
              </div>
            </button>
          </tui-loader>
        </tui-data-list>
      </tui-multi-select>
    </app-with-dictionary-button-wrapper>

    <tui-multi-select
      *ngIf="fields.includes('organisation')"
      [tuiTextfieldSize]="size"
      [tuiTextfieldLabelOutside]="true"
      [expandable]="false"
      [editable]="false"
      [tuiTextfieldCleaner]="true"
      [(ngModel)]="selectedOrganisations"
      (ngModelChange)="onChangeSelectedOrganisation()"
      [stringify]="stringifyOrganisation"
    >
      {{ 'components.filter.fields.organisation' | translate }}
      <tui-data-list *tuiDataList tuiMultiSelectGroup>
        <tui-loader
          *tuiLet="organisations$ | async as organisations"
          [inheritColor]="true"
          [showLoader]="!organisations"
        >
          <button *ngFor="let organisation of organisations" tuiOption class="list-item" [value]="organisation">
            <div>
              <div class="list-item__name">{{ organisation.shortName }}</div>
              <div class="list-item__description">{{ organisation.fullName }}</div>
            </div>
          </button>
        </tui-loader>
      </tui-data-list>
    </tui-multi-select>

    <app-committee-field
      *ngIf="fields.includes('committee')"
      [size]="size"
      [multiple]="true"
      [selectedIds]="selectedCommitteeIds"
      (selectedIdsChange)="onChangeSelectedCommitteeIds($event)"
    ></app-committee-field>

    <ng-container *ngFor="let contact of contacts">
      <tui-input [tuiTextfieldSize]="size" [tuiTextfieldCleaner]="true" [(ngModel)]="contact.value">
        {{ contact.name }}
      </tui-input>
    </ng-container>
  </div>
</tui-scrollbar>

<div class="actions">
  <button tuiButton type="button" [size]="'m'" (click)="onApplyFilter()">
    {{ 'common.buttons.apply' | translate }}
  </button>
  <button tuiButton type="button" [size]="'m'" (click)="onCancelFilter()">
    {{ 'common.buttons.cancellation' | translate }}
  </button>
</div>
