import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiBadgeModule,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFieldErrorPipeModule,
  TuiInputModule,
  TuiTabsModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiErrorModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { PhotoModule } from '@src/app/modules/photo';

import { UsersTableComponent } from './users-table.component';
import { UsersTableViewComponent, UsersTableEditComponent, CellUserComponent } from './components';
import { RolesService } from './services';

@NgModule({
  declarations: [UsersTableComponent, UsersTableViewComponent, UsersTableEditComponent, CellUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    PhotoModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    TuiBadgeModule,
    TuiButtonModule,
    TuiComboBoxModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiInputModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiScrollbarModule,
    TuiTableModule,
    TuiTabsModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
  ],
  providers: [RolesService],
  exports: [UsersTableComponent],
})
export class UsersTableModule {}
