import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TuiFilesModule, TuiInputModule, TuiTextareaModule } from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiLabelModule,
  TuiLinkModule,
} from '@taiga-ui/core';
import { ComponentsModule } from '@src/app/components';
import { TestingModule } from '@src/app/modules/testing/testing.module';
import { CustomNamesTabsModule } from '@src/app/modules/custom-name-tabs';
import { PhotoModule } from '@src/app/modules/photo';

import { SharedModule } from '../shared.module';

import { FieldComponent } from './field.component';
import { FieldPhoneComponent } from './field-phone/field-phone.component';
import { FieldEmailComponent } from './field-email/field-email.component';
import { FieldWebPageComponent } from './field-web-page/field-web-page.component';
import { FieldWebDocumentComponent } from './field-web-document/field-web-document.component';
import { FieldSocialNetworkComponent } from './field-social-network/field-social-network.component';
import { FieldMessengerComponent } from './field-messenger/field-messenger.component';
import { FieldTextComponent } from './field-text/field-text.component';
import { FieldFileComponent } from './field-file/field-file.component';
import { FieldUserComponent } from './field-user/field-user.component';
import { FieldOrganisationComponent } from './field-organisation/field-organisation.component';
import { FieldFilesListComponent } from './field-files-list/field-files-list.component';
import { FieldParseLinksComponent } from './field-parse-links/field-parse-links.component';
import { FieldCommitteeComponent } from './field-committee/field-committee.component';

@NgModule({
  declarations: [
    FieldComponent,
    FieldPhoneComponent,
    FieldEmailComponent,
    FieldWebPageComponent,
    FieldWebDocumentComponent,
    FieldSocialNetworkComponent,
    FieldMessengerComponent,
    FieldTextComponent,
    FieldFileComponent,
    FieldUserComponent,
    FieldOrganisationComponent,
    FieldFilesListComponent,
    FieldParseLinksComponent,
    FieldCommitteeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    TuiInputModule,
    TuiSvgModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    TuiFilesModule,
    TuiButtonModule,
    TuiLabelModule,
    TuiLinkModule,
    ComponentsModule,
    TestingModule,
    CustomNamesTabsModule,
    PhotoModule,
  ],
  exports: [FieldComponent, FieldTextComponent],
})
export class FieldModule {}
