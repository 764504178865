<div class="users-table-view">
  <tui-tabs class="users-table-view__tabs" [(activeItemIndex)]="activeTabIndex">
    <button
      *ngFor="let group of groups; let gIndex = index"
      tuiTab
      [tuiTextfieldSize]="'s'"
      (click)="setActiveTabIndex(gIndex)"
    >
      {{ group.title }}
      <tui-badge class="badge" status="primary" [value]="group.items.length"></tui-badge>
    </button>
  </tui-tabs>

  <tui-scrollbar class="users-table-view__body">
    <table tuiTable class="table" [columns]="columns">
      <thead>
        <tr tuiThGroup>
          <th *tuiHead="'user'" tuiTh [sorter]="null"></th>
          <th *tuiHead="'role'" tuiTh [sorter]="null">
            {{ 'components.usersTableView.labels.tableHeaderRole' | translate }}
          </th>
          <th *tuiHead="'comment'" tuiTh [sorter]="null">
            {{ 'components.usersTableView.labels.tableHeaderComment' | translate }}
          </th>
        </tr>
      </thead>

      <tbody tuiTbody>
        <tr *ngFor="let user of usersFiltered" tuiTr>
          <ng-container *ngIf="!!user">
            <td *tuiCell="'user'" tuiTd>
              <cell-user [user]="user"></cell-user>
            </td>
            <td *tuiCell="'role'" tuiTd>
              <div class="role">{{ user.participantRole?.participantRoleName }}</div>
            </td>
            <td *tuiCell="'comment'" tuiTd>
              <div class="comment">{{ user.participantRole?.participantComment }}</div>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </tui-scrollbar>
</div>
