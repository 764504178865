<div class="header">
  <button
    *ngIf="options.buttons?.left as leftButton"
    tuiButton
    class="header__button header__button_left"
    type="button"
    appearance="link"
    [icon]="leftButton.icon || ''"
    size="s"
    [title]="leftButton.label"
    (click)="leftButton.action()"
  >
    {{ leftButton.label }}
  </button>

  <div class="header__title">{{ options.title }}</div>

  <button
    *ngIf="options.buttons?.right as rightButton"
    tuiButton
    class="header__button header__button_right"
    type="button"
    appearance="link"
    [icon]="rightButton.icon || ''"
    size="s"
    [title]="rightButton.label"
    (click)="rightButton.action()"
  >
    {{ rightButton.label }}
  </button>
</div>
