import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { JobTitleUI } from '@src/models';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService } from '@src/core/services';

@Component({
  selector: 'app-select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectItemsComponent extends ResizableBaseComponent implements OnChanges {
  @Input() items?: JobTitleUI[] | null;
  @Input() actionForAdmin: boolean = false;
  @Input() titleVisible?: string;
  @Input() titleInvisible?: string;
  @Input() descriptionVisible?: string;
  @Input() descriptionInvisible?: string;
  @Output() save: EventEmitter<JobTitleUI[]> = new EventEmitter();

  searchVisibleItems?: JobTitleUI[];
  searchInvisibleItems?: JobTitleUI[];
  visibleItems?: JobTitleUI[];
  invisibleItems?: JobTitleUI[];
  searchQuery?: string = '';

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.visibleItems = this.items?.filter(item => item.isVisible);
      this.invisibleItems = this.items?.filter(item => !item.isVisible);
    }

    this.cdr.markForCheck();
  }

  onItemsChange() {
    this.visibleItems = this.items
      ?.filter(item => item.isVisible)
      .map(item => {
        if (item.sortOrder === undefined) {
          item.sortOrder =
            this.visibleItems?.length && this.visibleItems[this.visibleItems.length - 1].sortOrder
              ? this.visibleItems[this.visibleItems?.length - 1].sortOrder! + 1
              : 0;
        }
        return item;
      })
      .sort((jobTitle1, jobTitle2) => {
        const itemSortOrder1 = jobTitle1.sortOrder;
        const itemSortOrder2 = jobTitle2.sortOrder;

        if (itemSortOrder1 === undefined) return 1;
        if (itemSortOrder2 === undefined) return -1;

        if (itemSortOrder1 < itemSortOrder2) return -1;
        if (itemSortOrder1 > itemSortOrder2) return 1;
        return 0;
      })
      .map((item, index) => {
        item.sortOrder = index;
        return item;
      });

    this.invisibleItems = this.items
      ?.filter(item => !item.isVisible)
      .map(item => {
        item.sortOrder = undefined;
        return item;
      });

    this.onSearchQueryChange(this.searchQuery);
    this.cdr.markForCheck();
  }

  onSearchQueryChange(query?: string) {
    this.searchQuery = query;

    if (!query) {
      this.searchVisibleItems = undefined;
      this.searchInvisibleItems = undefined;
      return;
    }

    this.searchVisibleItems = this.visibleItems?.filter(item => item.name?.toLowerCase().includes(query.toLowerCase()));
    this.searchInvisibleItems = this.invisibleItems?.filter(item =>
      item.name?.toLowerCase().includes(query.toLowerCase()),
    );
  }

  onClickSaveButton($event: MouseEvent) {
    $event.stopPropagation();
    this.save.emit(this.visibleItems);
  }
}
