/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AddLoyaltyCategoryGroupWithCatrgories } from '../model/addLoyaltyCategoryGroupWithCatrgories';
// @ts-ignore
import { AddLoyaltyProduct } from '../model/addLoyaltyProduct';
// @ts-ignore
import { EditLoyaltyCategoryGroup } from '../model/editLoyaltyCategoryGroup';
// @ts-ignore
import { EditLoyaltyProduct } from '../model/editLoyaltyProduct';
// @ts-ignore
import { Id } from '../model/id';
// @ts-ignore
import { LoyaltyDiscountsListByPeriod } from '../model/loyaltyDiscountsListByPeriod';
// @ts-ignore
import { LoyaltyOrganization } from '../model/loyaltyOrganization';
// @ts-ignore
import { LoyaltyProductCode } from '../model/loyaltyProductCode';
// @ts-ignore
import { LoyaltyProductWCategiriesAdd } from '../model/loyaltyProductWCategiriesAdd';
// @ts-ignore
import { LoyaltyProductWCategiriesEdit } from '../model/loyaltyProductWCategiriesEdit';
// @ts-ignore
import { LoyaltyStatisticsByMonths } from '../model/loyaltyStatisticsByMonths';
// @ts-ignore
import { LoyaltyStatisticsByPeriod } from '../model/loyaltyStatisticsByPeriod';
// @ts-ignore
import { LoyaltyStatisticsSummary } from '../model/loyaltyStatisticsSummary';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { SearchParamsForLoyalty } from '../model/searchParamsForLoyalty';
// @ts-ignore
import { ViewLoyaltyCategoryGroup } from '../model/viewLoyaltyCategoryGroup';
// @ts-ignore
import { ViewLoyaltyProduct } from '../model/viewLoyaltyProduct';
// @ts-ignore
import { ViewLoyaltyRegion } from '../model/viewLoyaltyRegion';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Активировать продукт (товара/услуги) лояльности.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param unionId идентификатор-признак ассоциации из сторонней БД
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateLoyaltyProduct(productId: string, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Id>;
    public activateLoyaltyProduct(productId: string, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Id>>;
    public activateLoyaltyProduct(productId: string, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Id>>;
    public activateLoyaltyProduct(productId: string, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling activateLoyaltyProduct.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/activate/${this.configuration.encodeParam({name: "productId", value: productId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Id>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Добавить список категорий и подкатегорий продуктов лояльности.
     * @param addLoyaltyCategoryGroupWithCatrgories Данные программы лояльности для редактирования.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addLoyaltyCategories(addLoyaltyCategoryGroupWithCatrgories?: Array<AddLoyaltyCategoryGroupWithCatrgories>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<EditLoyaltyCategoryGroup>>;
    public addLoyaltyCategories(addLoyaltyCategoryGroupWithCatrgories?: Array<AddLoyaltyCategoryGroupWithCatrgories>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<EditLoyaltyCategoryGroup>>>;
    public addLoyaltyCategories(addLoyaltyCategoryGroupWithCatrgories?: Array<AddLoyaltyCategoryGroupWithCatrgories>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<EditLoyaltyCategoryGroup>>>;
    public addLoyaltyCategories(addLoyaltyCategoryGroupWithCatrgories?: Array<AddLoyaltyCategoryGroupWithCatrgories>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/CategoriesList`;
        return this.httpClient.request<Array<EditLoyaltyCategoryGroup>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: addLoyaltyCategoryGroupWithCatrgories,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Добавление продукта (товара/услуги) лояльности.
     * @param loyaltyProductWCategiriesAdd Данные продукта (товара/услуги) лояльности.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addLoyaltyProduct(loyaltyProductWCategiriesAdd?: LoyaltyProductWCategiriesAdd, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Id>;
    public addLoyaltyProduct(loyaltyProductWCategiriesAdd?: LoyaltyProductWCategiriesAdd, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Id>>;
    public addLoyaltyProduct(loyaltyProductWCategiriesAdd?: LoyaltyProductWCategiriesAdd, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Id>>;
    public addLoyaltyProduct(loyaltyProductWCategiriesAdd?: LoyaltyProductWCategiriesAdd, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/add`;
        return this.httpClient.request<Id>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: loyaltyProductWCategiriesAdd,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * УСТАРЕВШИЙ. Добавление продукта (товара/услуги) лояльности.
     * @param addLoyaltyProduct Данные продукта (товара/услуги) лояльности.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public addLoyaltyProductOld(addLoyaltyProduct?: AddLoyaltyProduct, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Id>;
    public addLoyaltyProductOld(addLoyaltyProduct?: AddLoyaltyProduct, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Id>>;
    public addLoyaltyProductOld(addLoyaltyProduct?: AddLoyaltyProduct, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Id>>;
    public addLoyaltyProductOld(addLoyaltyProduct?: AddLoyaltyProduct, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty`;
        return this.httpClient.request<Id>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: addLoyaltyProduct,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получение списка внешних источников программы лояльности для текущей организации
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public associationsList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<LoyaltyOrganization>>;
    public associationsList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<LoyaltyOrganization>>>;
    public associationsList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<LoyaltyOrganization>>>;
    public associationsList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/AssociationsList`;
        return this.httpClient.request<Array<LoyaltyOrganization>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Список лучших продуктов лояльности.
     * @param start начало выборки (0 по-умолчанию).
     * @param top кол-во (20 по-умолчанию).
     * @param unionId идентификатор-признак ассоциации из сторонней БД
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bestProducts(start?: number, top?: number, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<ViewLoyaltyProduct>>;
    public bestProducts(start?: number, top?: number, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<ViewLoyaltyProduct>>>;
    public bestProducts(start?: number, top?: number, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<ViewLoyaltyProduct>>>;
    public bestProducts(start?: number, top?: number, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (top !== undefined && top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>top, 'top');
        }
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/bestProducts`;
        return this.httpClient.request<Array<ViewLoyaltyProduct>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Список категорий продуктов лояльности.
     * @param inUse TRUE - значения справочника, имеющие поле inUse &#x3D; 1. При параметре в значении FALSE, либо его отсутствии выводить полные справочники.
     * @param unionId идентификаор-признак того, что данные нужно взять у ассоциации-участника глобальной программы лояльности
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoryGroups(inUse?: boolean, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<ViewLoyaltyCategoryGroup>>;
    public categoryGroups(inUse?: boolean, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<ViewLoyaltyCategoryGroup>>>;
    public categoryGroups(inUse?: boolean, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<ViewLoyaltyCategoryGroup>>>;
    public categoryGroups(inUse?: boolean, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (inUse !== undefined && inUse !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>inUse, 'inUse');
        }
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/categoriesList`;
        return this.httpClient.request<Array<ViewLoyaltyCategoryGroup>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Деактивировать продукт (товара/услуги) лояльности.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param unionId идентификатор-признак ассоциации из сторонней БД
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deactivateLoyaltyProduct(productId: string, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Id>;
    public deactivateLoyaltyProduct(productId: string, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Id>>;
    public deactivateLoyaltyProduct(productId: string, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Id>>;
    public deactivateLoyaltyProduct(productId: string, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling deactivateLoyaltyProduct.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/deactivate/${this.configuration.encodeParam({name: "productId", value: productId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Id>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получение списка применений скидок за период.
     * @param unionId ID объединения.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param dateStart Дата начала выборки статистики.
     * @param dateEnd Дата конца выборки статистики.
     * @param isCancellationRequested 
     * @param canBeCanceled 
     * @param waitHandleHandle 
     * @param waitHandleSafeWaitHandleIsInvalid 
     * @param waitHandleSafeWaitHandleIsClosed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discountsListByPeriod(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LoyaltyDiscountsListByPeriod>;
    public discountsListByPeriod(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LoyaltyDiscountsListByPeriod>>;
    public discountsListByPeriod(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LoyaltyDiscountsListByPeriod>>;
    public discountsListByPeriod(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }
        if (productId !== undefined && productId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productId, 'productId');
        }
        if (dateStart !== undefined && dateStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateStart, 'dateStart');
        }
        if (dateEnd !== undefined && dateEnd !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateEnd, 'dateEnd');
        }
        if (isCancellationRequested !== undefined && isCancellationRequested !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCancellationRequested, 'IsCancellationRequested');
        }
        if (canBeCanceled !== undefined && canBeCanceled !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>canBeCanceled, 'CanBeCanceled');
        }
        if (waitHandleHandle !== undefined && waitHandleHandle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleHandle, 'WaitHandle.Handle');
        }
        if (waitHandleSafeWaitHandleIsInvalid !== undefined && waitHandleSafeWaitHandleIsInvalid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleSafeWaitHandleIsInvalid, 'WaitHandle.SafeWaitHandle.IsInvalid');
        }
        if (waitHandleSafeWaitHandleIsClosed !== undefined && waitHandleSafeWaitHandleIsClosed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleSafeWaitHandleIsClosed, 'WaitHandle.SafeWaitHandle.IsClosed');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/DiscountsList`;
        return this.httpClient.request<LoyaltyDiscountsListByPeriod>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Редактировать список категорий и подкатегорий продуктов лояльности.
     * @param editLoyaltyCategoryGroup Данные программы лояльности для редактирования.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editLoyaltyCategoryGroups(editLoyaltyCategoryGroup?: Array<EditLoyaltyCategoryGroup>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any>;
    public editLoyaltyCategoryGroups(editLoyaltyCategoryGroup?: Array<EditLoyaltyCategoryGroup>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public editLoyaltyCategoryGroups(editLoyaltyCategoryGroup?: Array<EditLoyaltyCategoryGroup>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public editLoyaltyCategoryGroups(editLoyaltyCategoryGroup?: Array<EditLoyaltyCategoryGroup>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/CategoriesList`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: editLoyaltyCategoryGroup,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Редактирование продукта (товара/услуги) лояльности.
     * @param loyaltyProductWCategiriesEdit Данные продукта (товара/услуги) лояльности.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editLoyaltyProduct(loyaltyProductWCategiriesEdit?: LoyaltyProductWCategiriesEdit, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Id>;
    public editLoyaltyProduct(loyaltyProductWCategiriesEdit?: LoyaltyProductWCategiriesEdit, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Id>>;
    public editLoyaltyProduct(loyaltyProductWCategiriesEdit?: LoyaltyProductWCategiriesEdit, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Id>>;
    public editLoyaltyProduct(loyaltyProductWCategiriesEdit?: LoyaltyProductWCategiriesEdit, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/edit`;
        return this.httpClient.request<Id>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: loyaltyProductWCategiriesEdit,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * УСТАРЕВШИЙ. Редактирование продукта (товара/услуги) лояльности.
     * @param editLoyaltyProduct Данные продукта (товара/услуги) лояльности.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public editLoyaltyProductOld(editLoyaltyProduct?: EditLoyaltyProduct, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Id>;
    public editLoyaltyProductOld(editLoyaltyProduct?: EditLoyaltyProduct, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Id>>;
    public editLoyaltyProductOld(editLoyaltyProduct?: EditLoyaltyProduct, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Id>>;
    public editLoyaltyProductOld(editLoyaltyProduct?: EditLoyaltyProduct, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty`;
        return this.httpClient.request<Id>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: editLoyaltyProduct,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Данные продукта лояльности.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param unionId идентификатор-признак ассоциации из сторонней БД
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loyaltyProduct(productId: string, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ViewLoyaltyProduct>;
    public loyaltyProduct(productId: string, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ViewLoyaltyProduct>>;
    public loyaltyProduct(productId: string, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ViewLoyaltyProduct>>;
    public loyaltyProduct(productId: string, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling loyaltyProduct.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/product/${this.configuration.encodeParam({name: "productId", value: productId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<ViewLoyaltyProduct>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Генерация и получение кода для продукта лояльности.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param unionId идентификатор-признак ассоциации из сторонней БД
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loyaltyProductCode(productId: string, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<LoyaltyProductCode>>;
    public loyaltyProductCode(productId: string, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<LoyaltyProductCode>>>;
    public loyaltyProductCode(productId: string, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<LoyaltyProductCode>>>;
    public loyaltyProductCode(productId: string, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling loyaltyProductCode.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/productCode/${this.configuration.encodeParam({name: "productId", value: productId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Array<LoyaltyProductCode>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Список продуктов пользователя.              в начале списка выводятся продукты в статусе \&quot;На модерации\&quot;.              вторичная сортировка - по дате публикации, от самых новых к самым старым.
     * @param start начало выборки (0 по-умолчанию).
     * @param top кол-во (20 по-умолчанию).
     * @param unionId идентификатор-признак ассоциации из сторонней БД
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myProducts(start?: number, top?: number, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<ViewLoyaltyProduct>>;
    public myProducts(start?: number, top?: number, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<ViewLoyaltyProduct>>>;
    public myProducts(start?: number, top?: number, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<ViewLoyaltyProduct>>>;
    public myProducts(start?: number, top?: number, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (top !== undefined && top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>top, 'top');
        }
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/myProducts`;
        return this.httpClient.request<Array<ViewLoyaltyProduct>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Вычислить рейтинг продукта лояльности.
     * @param imagesCount Кол-во фотографий продукта.
     * @param charsCount Длина описания продкута в символах.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productRating(imagesCount?: number, charsCount?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public productRating(imagesCount?: number, charsCount?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public productRating(imagesCount?: number, charsCount?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public productRating(imagesCount?: number, charsCount?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (imagesCount !== undefined && imagesCount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>imagesCount, 'imagesCount');
        }
        if (charsCount !== undefined && charsCount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>charsCount, 'charsCount');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/productRating`;
        return this.httpClient.request<number>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Справочник регионов.
     * @param inUse TRUE - значения справочника, имеющие поле inUse &#x3D; 1. При параметре в значении FALSE, либо его отсутствии выводить полные справочники.
     * @param unionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regionsList(inUse?: boolean, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<ViewLoyaltyRegion>>;
    public regionsList(inUse?: boolean, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<ViewLoyaltyRegion>>>;
    public regionsList(inUse?: boolean, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<ViewLoyaltyRegion>>>;
    public regionsList(inUse?: boolean, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (inUse !== undefined && inUse !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>inUse, 'inUse');
        }
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/regionsList`;
        return this.httpClient.request<Array<ViewLoyaltyRegion>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удалить продукт лояльности. Админ ДО может удалять, лишь если он создал.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param unionId идентификатор-признак ассоциации из сторонней БД
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeLoyaltyProduct(productId: string, unionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<LoyaltyProductCode>>;
    public removeLoyaltyProduct(productId: string, unionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<LoyaltyProductCode>>>;
    public removeLoyaltyProduct(productId: string, unionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<LoyaltyProductCode>>>;
    public removeLoyaltyProduct(productId: string, unionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling removeLoyaltyProduct.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/${this.configuration.encodeParam({name: "productId", value: productId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Array<LoyaltyProductCode>>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Регистрации в таблице статистики ПЛ открытия ссылки из объявления.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param link Cсылка, по которой переходит пользователь.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveRedirectLink(productId: string, link?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<string>;
    public saveRedirectLink(productId: string, link?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public saveRedirectLink(productId: string, link?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public saveRedirectLink(productId: string, link?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling saveRedirectLink.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (link !== undefined && link !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>link, 'link');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/RedirectLink/${this.configuration.encodeParam({name: "productId", value: productId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<string>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Поиск продукта (товара/услуги) лояльности.
     * @param searchParamsForLoyalty Параметры поиска продуктов (товара/услуги) лояльности.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchForLoyaltyProduct(searchParamsForLoyalty?: SearchParamsForLoyalty, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<ViewLoyaltyProduct>>;
    public searchForLoyaltyProduct(searchParamsForLoyalty?: SearchParamsForLoyalty, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<ViewLoyaltyProduct>>>;
    public searchForLoyaltyProduct(searchParamsForLoyalty?: SearchParamsForLoyalty, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<ViewLoyaltyProduct>>>;
    public searchForLoyaltyProduct(searchParamsForLoyalty?: SearchParamsForLoyalty, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/SearchProducts`;
        return this.httpClient.request<Array<ViewLoyaltyProduct>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: searchParamsForLoyalty,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получение статистики за период с разбивкой по месяцам.
     * @param unionId ID объединения.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param dateStart Дата начала выборки статистики.
     * @param dateEnd Дата конца выборки статистики.
     * @param isCancellationRequested 
     * @param canBeCanceled 
     * @param waitHandleHandle 
     * @param waitHandleSafeWaitHandleIsInvalid 
     * @param waitHandleSafeWaitHandleIsClosed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsByMonths(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LoyaltyStatisticsByMonths>;
    public statisticsByMonths(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LoyaltyStatisticsByMonths>>;
    public statisticsByMonths(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LoyaltyStatisticsByMonths>>;
    public statisticsByMonths(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }
        if (productId !== undefined && productId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productId, 'productId');
        }
        if (dateStart !== undefined && dateStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateStart, 'dateStart');
        }
        if (dateEnd !== undefined && dateEnd !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateEnd, 'dateEnd');
        }
        if (isCancellationRequested !== undefined && isCancellationRequested !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCancellationRequested, 'IsCancellationRequested');
        }
        if (canBeCanceled !== undefined && canBeCanceled !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>canBeCanceled, 'CanBeCanceled');
        }
        if (waitHandleHandle !== undefined && waitHandleHandle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleHandle, 'WaitHandle.Handle');
        }
        if (waitHandleSafeWaitHandleIsInvalid !== undefined && waitHandleSafeWaitHandleIsInvalid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleSafeWaitHandleIsInvalid, 'WaitHandle.SafeWaitHandle.IsInvalid');
        }
        if (waitHandleSafeWaitHandleIsClosed !== undefined && waitHandleSafeWaitHandleIsClosed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleSafeWaitHandleIsClosed, 'WaitHandle.SafeWaitHandle.IsClosed');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/StatisticsByMonths`;
        return this.httpClient.request<LoyaltyStatisticsByMonths>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получение статистики за период.
     * @param unionId ID объединения.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param dateStart Дата начала выборки статистики.
     * @param dateEnd Дата конца выборки статистики.
     * @param isCancellationRequested 
     * @param canBeCanceled 
     * @param waitHandleHandle 
     * @param waitHandleSafeWaitHandleIsInvalid 
     * @param waitHandleSafeWaitHandleIsClosed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsByPeriod(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LoyaltyStatisticsByPeriod>;
    public statisticsByPeriod(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LoyaltyStatisticsByPeriod>>;
    public statisticsByPeriod(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LoyaltyStatisticsByPeriod>>;
    public statisticsByPeriod(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }
        if (productId !== undefined && productId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productId, 'productId');
        }
        if (dateStart !== undefined && dateStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateStart, 'dateStart');
        }
        if (dateEnd !== undefined && dateEnd !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateEnd, 'dateEnd');
        }
        if (isCancellationRequested !== undefined && isCancellationRequested !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCancellationRequested, 'IsCancellationRequested');
        }
        if (canBeCanceled !== undefined && canBeCanceled !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>canBeCanceled, 'CanBeCanceled');
        }
        if (waitHandleHandle !== undefined && waitHandleHandle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleHandle, 'WaitHandle.Handle');
        }
        if (waitHandleSafeWaitHandleIsInvalid !== undefined && waitHandleSafeWaitHandleIsInvalid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleSafeWaitHandleIsInvalid, 'WaitHandle.SafeWaitHandle.IsInvalid');
        }
        if (waitHandleSafeWaitHandleIsClosed !== undefined && waitHandleSafeWaitHandleIsClosed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleSafeWaitHandleIsClosed, 'WaitHandle.SafeWaitHandle.IsClosed');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/StatisticsByPeriod`;
        return this.httpClient.request<LoyaltyStatisticsByPeriod>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получение сводной статистики для объявлений с выбором периода.
     * @param unionId ID объединения.
     * @param productId ID продукта (товара/услуги) лояльности.
     * @param dateStart Дата начала выборки статистики.
     * @param dateEnd Дата конца выборки статистики.
     * @param isCancellationRequested 
     * @param canBeCanceled 
     * @param waitHandleHandle 
     * @param waitHandleSafeWaitHandleIsInvalid 
     * @param waitHandleSafeWaitHandleIsClosed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statisticsSummary(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LoyaltyStatisticsSummary>;
    public statisticsSummary(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LoyaltyStatisticsSummary>>;
    public statisticsSummary(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LoyaltyStatisticsSummary>>;
    public statisticsSummary(unionId?: string, productId?: string, dateStart?: string, dateEnd?: string, isCancellationRequested?: boolean, canBeCanceled?: boolean, waitHandleHandle?: object, waitHandleSafeWaitHandleIsInvalid?: boolean, waitHandleSafeWaitHandleIsClosed?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (unionId !== undefined && unionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>unionId, 'unionId');
        }
        if (productId !== undefined && productId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productId, 'productId');
        }
        if (dateStart !== undefined && dateStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateStart, 'dateStart');
        }
        if (dateEnd !== undefined && dateEnd !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateEnd, 'dateEnd');
        }
        if (isCancellationRequested !== undefined && isCancellationRequested !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCancellationRequested, 'IsCancellationRequested');
        }
        if (canBeCanceled !== undefined && canBeCanceled !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>canBeCanceled, 'CanBeCanceled');
        }
        if (waitHandleHandle !== undefined && waitHandleHandle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleHandle, 'WaitHandle.Handle');
        }
        if (waitHandleSafeWaitHandleIsInvalid !== undefined && waitHandleSafeWaitHandleIsInvalid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleSafeWaitHandleIsInvalid, 'WaitHandle.SafeWaitHandle.IsInvalid');
        }
        if (waitHandleSafeWaitHandleIsClosed !== undefined && waitHandleSafeWaitHandleIsClosed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>waitHandleSafeWaitHandleIsClosed, 'WaitHandle.SafeWaitHandle.IsClosed');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/Loyalty/StatisticsSummary`;
        return this.httpClient.request<LoyaltyStatisticsSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
