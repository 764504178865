import {
  Airgram,
  ApiMethod,
  ApiResponse,
  CallbackQueryPayloadInputUnion,
  ChatAdministratorUnion,
  ChatFilterInputUnion,
  ChatListUnion,
  ChatLocationInputUnion,
  ChatMemberStatusInputUnion,
  Chats,
  ContactInputUnion,
  FormattedText,
  InputMessageContentInputUnion,
  isError,
  MessageSenderInputUnion,
  MessageUnion,
  OkUnion,
  SearchMessagesFilterInputUnion,
  SendMessageParams,
  SetChatPhotoParams,
  SponsoredMessageUnion,
  TextParseModeInputUnion,
  toObject,
  UpdateUnion,
  UserPrivacySettingInputUnion,
  UsersUnion,
} from '@airgram/web';
import { from, Observable, Subject } from 'rxjs';
import { AttachmentItem, ChatModel } from '@src/models';
import { APP_CONFIG } from '@src/core';
import { TranslateService } from '@ngx-translate/core';
import { ChatMember } from '@airgram/core/types/outputs/ChatMember';
import { map } from 'rxjs/operators';

import { MUTE_FOR_WEEK } from '../constants';
import { ErrorHandler } from '../types';

export class AirgramApiWrapper {
  constructor(private readonly instance: Airgram, private readonly translateService?: TranslateService) {}

  use(updates$: Subject<UpdateUnion>, errorHandler: ErrorHandler): void {
    this.instance?.use(async (ctx, next) => {
      if ('update' in ctx) {
        updates$.next(ctx.update as any);
      }

      await next();

      if ('request' in ctx && isError(ctx.response)) {
        if (ctx.request.method === 'checkChatInviteLink' && ctx.response.message === 'INVITE_HASH_EXPIRED') {
          return;
        }

        errorHandler(ctx.request.method as ApiMethod, ctx.response.code, ctx.response.message);
      }
      return;
    });
  }

  /**
   * Returns the current user
   */
  async getMe() {
    return toObject(await this.instance.api.getMe());
  }

  logout() {
    return this.instance.api.logOut();
  }

  /**
   * Returns the current authorization state; this is an offline request.
   * For informational purposes only.
   */
  async getAuthorizationState() {
    return toObject(await this.instance.api.getAuthorizationState());
  }

  /**
   * Searches a public chat by its username. Currently, only private chats, supergroups and channels can be public.
   * @param username логин пользователя
   */
  async searchPublicChat(username: string): Promise<ChatModel> {
    return toObject(await this.instance.api.searchPublicChat({ username }));
  }

  /**
   * Informs TDLib that the chat is opened by the user.
   * @param chatId идентификатор чата
   */
  async openChat(chatId: number) {
    return await this.instance.api.openChat({ chatId });
  }

  /**
   * Changes the block state of a message sender.
   * @param senderId инициатор
   * @param isBlocked флаг блокировки
   */
  async toggleMessageSenderIsBlocked(senderId: MessageSenderInputUnion, isBlocked: boolean) {
    return toObject(await this.instance.api.toggleMessageSenderIsBlocked({ senderId, isBlocked }));
  }

  /**
   * Invites a bot to a chat (if it is not yet a member) and sends it the /start command.
   * @param botUserId идентификатор бота
   * @param chatId идентификатор чата
   * @param parameter
   */
  async sendBotStartMessage(botUserId: number, chatId: number, parameter?: string) {
    return await this.instance.api.sendBotStartMessage({ botUserId, chatId, parameter });
  }

  /**
   * Sends a message.
   * @param chatId идентификатор чата
   * @param contact данные контакта
   * @param replyToMessageId идентификатор пересылаемого сообщения
   */
  async sendMessageContact(chatId: number, contact: ContactInputUnion, replyToMessageId: number = 0) {
    return this.instance.api.sendMessage({
      chatId,
      replyToMessageId,
      inputMessageContent: {
        _: 'inputMessageContact',
        contact,
      },
    });
  }

  /**
   * Sends a text message.
   * @param chatId идентификатор чата
   * @param text содержимое сообщения
   * @param replyToMessageId идентификатор пересылаемого сообщения
   */
  sendMessageText(chatId: number, text: FormattedText, replyToMessageId: number = 0) {
    return this.instance.api.sendMessage({
      chatId,
      replyToMessageId,
      inputMessageContent: {
        _: 'inputMessageText',
        text,
      },
    });
  }

  /**
   * Deletes messages
   * @param chatId идентификатор чата
   * @param messageIds массив идентификаторов сообщений
   * @param revoke отмена
   */
  async deleteMessages(chatId: number, messageIds: number[], revoke: boolean = false) {
    return toObject(await this.instance.api.deleteMessages({ chatId, messageIds, revoke }));
  }

  unpinChatMessage(chatId: number, messageId: number) {
    return this.instance.api.unpinChatMessage({
      chatId,
      messageId,
    });
  }

  pinChatMessage(chatId: number, messageId: number, disableNotification: boolean, onlyForSelf: boolean) {
    return this.instance.api.pinChatMessage({
      chatId,
      messageId,
      disableNotification,
      onlyForSelf,
    });
  }

  async searchChatMessages(chatId: number, query: string, filter: SearchMessagesFilterInputUnion) {
    return toObject(
      await this.instance.api.searchChatMessages({
        chatId,
        query,
        filter,
        limit: APP_CONFIG.loadingLimit.messages,
      }),
    );
  }

  loadPinnedMessages(chatId: number) {
    return this.searchChatMessages(chatId, '', { _: 'searchMessagesFilterPinned' });
  }

  async getBlockedMessageSenders(offset: number, limit: number = 100) {
    return toObject(await this.instance.api.getBlockedMessageSenders({ offset, limit }));
  }

  async removeContacts(userIds: number[]) {
    return toObject(await this.instance.api.removeContacts({ userIds }));
  }

  async getContacts() {
    return toObject(await this.instance.api.getContacts());
  }

  async joinChatByInviteLink(inviteLink: string) {
    return toObject(await this.instance.api.joinChatByInviteLink({ inviteLink }));
  }

  async checkChatInviteLink(inviteLink: string) {
    return toObject(await this.instance.api.checkChatInviteLink({ inviteLink }));
  }

  async leaveChat(chatId: number) {
    return toObject(await this.instance.api.leaveChat({ chatId }));
  }

  async setChatMemberStatus(chatId: number, memberId: MessageSenderInputUnion, status: ChatMemberStatusInputUnion) {
    return toObject(await this.instance.api.setChatMemberStatus({ chatId, memberId, status }));
  }

  async sendBotCommand(chatId: number, messageId: number, payload: CallbackQueryPayloadInputUnion) {
    return toObject(await this.instance.api.getCallbackQueryAnswer({ chatId, messageId, payload }));
  }

  /**
   * True, if the new contact needs to be allowed to see current user's phone number.
   * A corresponding rule to userPrivacySettingShowPhoneNumber will be added if needed.
   * Use the field UserFullInfo.need_phone_number_privacy_exception to check whether the current
   * user needs to be asked to share their phone number.
   */
  async addContact(contact: ContactInputUnion) {
    return this.instance.api.addContact({
      contact,
      sharePhoneNumber: false,
    });
  }

  async readFilePart(fileId: number) {
    return toObject(
      await this.instance.api.readFilePart({
        fileId,
        offset: 0,
        count: 0,
      }),
    );
  }

  async getMessage(chatId: number, messageId: number): Promise<MessageUnion> {
    return toObject(
      await this.instance.api.getMessage({
        chatId,
        messageId,
      }),
    );
  }

  async parseTextEntities(text: string, parseMode: TextParseModeInputUnion) {
    return toObject(
      await this.instance.api.parseTextEntities({
        text,
        parseMode,
      }),
    );
  }

  async createPrivateChat(userId: number, force: boolean = false) {
    return await this.instance.api.createPrivateChat({ userId, force });
  }

  closeChat(chatId: number) {
    this.instance.api.closeChat({ chatId }).then();
  }

  async importContacts(contacts: ContactInputUnion[]) {
    return toObject(await this.instance.api.importContacts({ contacts }));
  }

  async getOption(name: string) {
    return toObject(await this.instance.api.getOption({ name }));
  }

  requestQrCodeAuthentication(otherUserIds?: number[]) {
    return this.instance.api.requestQrCodeAuthentication({ otherUserIds });
  }

  setAuthenticationPhoneNumber(phoneNumber: string) {
    return this.instance.api.setAuthenticationPhoneNumber({ phoneNumber });
  }

  checkAuthenticationCode(code: string) {
    return this.instance.api.checkAuthenticationCode({ code });
  }

  checkAuthenticationPassword(password: string) {
    return this.instance.api.checkAuthenticationPassword({ password });
  }

  /**
   * Priority of the upload (1-32). The higher the priority, the earlier the file will be uploaded.
   * If the priorities of two files are equal, then the first one for which uploadFile was called will be uploaded first
   */
  async uploadFile(id: number, priority: number = 1) {
    return toObject(
      await this.instance.api.uploadFile({
        file: {
          _: 'inputFileId',
          id,
        },
        fileType: {
          _: 'fileTypePhoto',
        },
        priority,
      }),
    );
  }

  /**
   * Priority of the download (1-32).
   * @see https://core.telegram.org/tdlib/docs/classtd_1_1td__api_1_1download_file.html#aee8acba2d3c43db1a310a3db9e78058b
   */
  async downloadFile(fileId: number, priority: number = 1, offset: number = 0) {
    return toObject(
      await this.instance.api.downloadFile({
        fileId,
        priority,
        offset,
        synchronous: true,
      }),
    );
  }

  async getUserPrivacySettingRules(setting: UserPrivacySettingInputUnion) {
    return toObject(await this.instance.api.getUserPrivacySettingRules({ setting }));
  }

  async getUser(userId: number) {
    return toObject(await this.instance.api.getUser({ userId }));
  }

  getUserObservable(userId: number) {
    return from(this.instance.api.getUser({ userId })).pipe(map(user => toObject(user)));
  }

  async getBasicGroup(basicGroupId: number) {
    return toObject(await this.instance.api.getBasicGroup({ basicGroupId }));
  }

  async getBasicGroupFullInfo(basicGroupId: number) {
    return toObject(await this.instance.api.getBasicGroupFullInfo({ basicGroupId }));
  }

  async getSupergroup(supergroupId: number) {
    return toObject(await this.instance.api.getSupergroup({ supergroupId }));
  }

  async getSupergroupMembers(
    supergroupId: number,
    offset: number = 0,
    limit: number = APP_CONFIG.loadingLimit.chatMembers,
  ) {
    return toObject(await this.instance.api.getSupergroupMembers({ supergroupId, offset, limit }));
  }

  editMessageText(chatId: number, text: FormattedText, messageId: number = 0) {
    return this.instance.api.editMessageText({
      chatId,
      messageId,
      inputMessageContent: {
        _: 'inputMessageText',
        text,
      },
    });
  }

  async getMessageViewers(chatId: number, messageId: number): Promise<UsersUnion> {
    return toObject(await this.instance.api.getMessageViewers({ chatId, messageId }));
  }

  async viewMessages(chatId?: number, messageId?: number): Promise<void> {
    if (!chatId || !messageId) return;

    await this.instance.api.viewMessages({ chatId, messageIds: [messageId] });
  }

  async searchChats(query: string, limit: number = 100): Promise<Chats> {
    return toObject(await this.instance.api.searchChats({ query, limit }));
  }

  async getChat(chatId: number): Promise<ChatModel> {
    return toObject(
      await this.instance.api.getChat({
        chatId,
      }),
    );
  }

  getChatObservable(chatId: number): Observable<ChatModel> {
    return from(this.instance.api.getChat({ chatId })).pipe(map(chat => toObject(chat)));
  }

  async getChatAdministrators(chatId: number): Promise<ChatAdministratorUnion[]> {
    return toObject(await this.instance.api.getChatAdministrators({ chatId })).administrators;
  }

  async getChatTitle(chatId: number): Promise<string> {
    return toObject(await this.instance.api.getChat({ chatId })).title;
  }

  async getSupergroupFullInfo(supergroupId: number) {
    return toObject(await this.instance.api.getSupergroupFullInfo({ supergroupId }));
  }

  async addChatMember(chatId: number, userId: number, forwardLimit?: number) {
    const result = await this.instance.api.addChatMember({ chatId, userId, forwardLimit });
    if (result.response._ === 'error') {
      return;
    }

    return toObject(result);
  }

  async addChatMembers(chatId: number, userIds: number[]) {
    const result = await this.instance.api.addChatMembers({ chatId, userIds });
    if (result.response._ === 'error') {
      return;
    }

    return toObject(result);
  }

  deleteChatMember(chatId: number, memberId: MessageSenderInputUnion) {
    return this.instance.api.setChatMemberStatus({ chatId, memberId, status: { _: 'chatMemberStatusLeft' } });
  }

  async getChatMember(chatId: number, memberId: MessageSenderInputUnion) {
    return toObject(await this.instance.api.getChatMember({ chatId, memberId }));
  }

  getChatMemberObservable(chatId: number, memberId: MessageSenderInputUnion): Observable<ChatMember> {
    return from(this.instance.api.getChatMember({ chatId, memberId })).pipe(map(response => toObject(response)));
  }

  async setChatTitle(chatId: number, title: string) {
    return toObject(await this.instance.api.setChatTitle({ chatId, title }));
  }

  async setChatDescription(chatId: number, description: string = '') {
    return toObject(await this.instance.api.setChatDescription({ chatId, description }));
  }

  deleteChat(chatId: number) {
    return this.instance.api.deleteChat({ chatId });
  }

  createChatFilter(params: ChatFilterInputUnion) {
    return this.instance.api.createChatFilter({ filter: params });
  }

  editChatFilter(chatFilterId: number, params: ChatFilterInputUnion) {
    return this.instance.api.editChatFilter({
      chatFilterId,
      filter: params,
    });
  }

  deleteChatFilter(chatFilterId: number) {
    return this.instance.api.deleteChatFilter({ chatFilterId });
  }

  async getChatSponsoredMessage(chatId: number): Promise<SponsoredMessageUnion | undefined> {
    try {
      const sponsoredMessage = await this.instance.api.getChatSponsoredMessage({ chatId });
      if (sponsoredMessage.response._ === 'error') {
        return;
      }

      return toObject(sponsoredMessage);
    } catch {
      return;
    }
  }

  async getChatHistory(chatId: number, fromMessageId: number = 0) {
    return toObject(
      await this.instance.api.getChatHistory({
        chatId,
        fromMessageId,
        limit: APP_CONFIG.loadingLimit.messages,
      }),
    );
  }

  setChatPhoto(chatId: number, photo: File): Observable<ApiResponse<SetChatPhotoParams, OkUnion>> {
    return from(
      this.instance.api.setChatPhoto({
        chatId,
        photo: {
          _: 'inputChatPhotoStatic',
          // TODO remove 'as any' (requires Airgram API update)
          photo: {
            _: 'inputFileBlob',
            data: photo,
          } as any,
        },
      }),
    );
  }

  deleteChatPhoto(chatId: number): Observable<ApiResponse<SetChatPhotoParams, OkUnion>> {
    return from(this.instance.api.setChatPhoto({ chatId }));
  }

  setChatNotificationSettings(chatId: number, value: boolean) {
    return this.instance.api.setChatNotificationSettings({
      chatId,
      notificationSettings: {
        _: 'chatNotificationSettings',
        muteFor: value ? 0 : MUTE_FOR_WEEK,
      },
    });
  }

  async forwardMessages(chatId: number, fromChatId: number, messageIds: number[]) {
    return this.instance.api.forwardMessages({ chatId, fromChatId, messageIds });
  }

  sendVoiceNoteMessage(
    chatId: number,
    voiceNote: Blob,
    replyToMessageId: number = 0,
  ): Observable<ApiResponse<SendMessageParams, MessageUnion>> {
    return from(
      this.instance.api.sendMessage({
        chatId,
        replyToMessageId,
        inputMessageContent: {
          _: 'inputMessageVoiceNote',
          voiceNote: {
            _: 'inputFileBlob',
            data: voiceNote,
            name: `${this.translateService?.instant(
              'components.telegramAirgramApiWrapper.labels.voiceNoteFrom',
            )} ${new Date().toLocaleDateString()} `,
          } as any,
        },
      }),
    );
  }

  sendMessageWithAttachment(
    chatId: number,
    attachmentItem: AttachmentItem,
    replyToMessageId: number = 0,
    caption?: string,
  ): Observable<ApiResponse<SendMessageParams, MessageUnion>> {
    let inputMessageContent: InputMessageContentInputUnion;

    // TODO remove 'as any' (requires Airgram API update)
    switch (attachmentItem.type) {
      case 'audio':
        inputMessageContent = {
          _: 'inputMessageAudio',
          audio: {
            _: 'inputFileBlob',
            data: attachmentItem.file,
            name: attachmentItem.file.name,
          } as any,
        };
        break;
      case 'video':
        inputMessageContent = {
          _: 'inputMessageVideo',
          video: {
            _: 'inputFileBlob',
            data: attachmentItem.file,
            name: attachmentItem.file.name,
          } as any,
        };
        break;
      case 'image':
        inputMessageContent = {
          _: 'inputMessagePhoto',
          photo: {
            _: 'inputFileBlob',
            data: attachmentItem.file,
            name: attachmentItem.file.name,
          } as any,
        };
        break;
      default:
        inputMessageContent = {
          _: 'inputMessageDocument',
          document: {
            _: 'inputFileBlob',
            data: attachmentItem.file,
            name: attachmentItem.file.name,
          } as any,
        };
        break;
    }

    if (
      caption &&
      (inputMessageContent._ === 'inputMessageAudio' ||
        inputMessageContent._ === 'inputMessageVideo' ||
        inputMessageContent._ === 'inputMessagePhoto' ||
        inputMessageContent._ === 'inputMessageDocument')
    ) {
      inputMessageContent = { ...inputMessageContent, caption: { _: 'formattedText', text: caption } };
    }

    return from(this.instance.api.sendMessage({ chatId, replyToMessageId, inputMessageContent }));
  }

  getChats(chatList: ChatListUnion) {
    return this.instance.api.getChats({
      chatList,
      limit: APP_CONFIG.loadingLimit.chats,
    });
  }

  async createNewSupergroupChat(
    title: string,
    isChannel: boolean,
    description?: string,
    location?: ChatLocationInputUnion,
  ) {
    return toObject(
      await this.instance.api.createNewSupergroupChat({
        title,
        isChannel,
        description,
        location,
      }),
    );
  }
}
