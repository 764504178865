<div class="folders">
  <div
    telegram-check-auth
    [infoText]="'components.telegramFolders.labels.telegramNeeded' | translate"
    (onLogged)="onLogged()"
  >
    <tui-loader [showLoader]="!(allChatsIds$ | async)?.length" [overlay]="true">
      <div class="folder-wrapper">
        <div class="folder">
          <div class="folder__title">
            {{
              !unionsFolder
                ? ('components.telegramFolders.buttons.create' | translate)
                : ('common.buttons.update' | translate)
            }}
            {{ folderConfig?.title }}
          </div>
          <button
            *ngIf="!unionsFolder"
            tuiIconButton
            class="folder__button folder__button_middle"
            type="button"
            [appearance]="'flat'"
            size="s"
            [iconRight]="'tuiIconPlus'"
            [disabled]="!!currentFolders && currentFolders.length === folderLimit"
            (click)="onClickCreateFolderButton()"
          ></button>
          <button
            *ngIf="unionsFolder"
            tuiButton
            class="folder__button folder__button_middle"
            type="button"
            [appearance]="'primary'"
            size="s"
            (click)="onClickEditFolderButton()"
          >
            Обновить
          </button>
          <button
            *ngIf="unionsFolder"
            tuiIconButton
            class="folder__button folder__button_last"
            type="button"
            appearance="primary"
            icon="tuiIconTrash"
            size="s"
            [title]="'common.buttons.delete' | translate"
            (click)="onClickDeleteFolderButton(unionsFolder)"
          ></button>
        </div>
      </div>
    </tui-loader>

    <div *ngIf="currentFolders && currentFolders.length === folderLimit" class="folders__error">
      {{ 'components.telegramFolders.labels.createLimitPart1' | translate }}
      <br />
      {{ 'components.telegramFolders.labels.createLimitPart2' | translate : { value: folderLimit } }}
      <br />
      {{ 'components.telegramFolders.labels.createLimitPart3' | translate : { value: currentFolders.length } }}
    </div>
  </div>

  <div class="folders__description">
    {{ 'components.telegramFolders.labels.description' | translate : { value: folderConfig?.title } }}
  </div>
</div>
