<div class="chat-layout" [class.chat-layout_col-2]="(detailsVisible || editing) && isLargeScreen">
  <ng-container [hide]="!showFullPage">
    <div *ngIf="chat" class="chat-layout__header">
      <div class="header" (click)="onClickDetailButton()">
        <div class="header__photo">
          <tui-avatar
            size="m"
            [autoColor]="!chat.userPhotoPath"
            [rounded]="true"
            [avatarUrl]="chat.userPhotoPath || '' | safe : 'url'"
            [text]="chat ? chat.title : ''"
          ></tui-avatar>
        </div>
        <div class="header__info info">
          <div class="info__title">{{ chat.title }}</div>
          <div class="info__status">
            <ng-container *ngIf="bot">{{ 'common.labels.bot' | translate }}</ng-container>
            <ng-container *ngIf="event">{{ 'components.telegramChat.labels.event' | translate }}</ng-container>
            <ng-container *ngIf="poll">{{ 'components.telegramChat.labels.poll' | translate }}</ng-container>
            <ng-container *ngIf="user">
              {{ chat.actionStatus ? (chat.actionStatus | actionStatus | async) : (user.status | userStatus) }}
            </ng-container>
            <ng-container *ngIf="superGroup">
              {{
                superGroup
                  ? (superGroup.memberCount
                    | numeralPlurals
                      : [
                          'components.telegramChat.labels.memberOne' | translate,
                          'components.telegramChat.labels.memberFew' | translate,
                          'components.telegramChat.labels.memberMany' | translate
                        ])
                  : ''
              }}
            </ng-container>
            <ng-container *ngIf="basicGroup">
              {{
                basicGroup
                  ? (basicGroup.members.length
                    | numeralPlurals
                      : [
                          'components.telegramChat.labels.memberOne' | translate,
                          'components.telegramChat.labels.memberFew' | translate,
                          'components.telegramChat.labels.memberMany' | translate
                        ])
                  : ''
              }}
            </ng-container>
          </div>
        </div>
      </div>
      <div class="header-actions">
        <button
          *ngIf="(event || poll) && (allowEditing$ | async)"
          tuiIconButton
          class="header-actions__menu"
          type="button"
          appearance="icon"
          icon="tuiIconEditLarge"
          size="s"
          [title]="'common.buttons.edit' | translate"
          (click)="onClickEditButton()"
        ></button>
        <button
          tuiIconButton
          class="header-actions__details"
          type="button"
          appearance="icon"
          icon="tuiIconLayoutLarge"
          size="s"
          [title]="'components.telegramChat.buttons.details' | translate"
          (click)="onClickDetailButton()"
        ></button>
        <tui-hosted-dropdown
          *ngIf="isChatPage"
          class="header-actions__menu"
          tuiDropdownAlign="left"
          [content]="actionMenuTemplate"
          [(open)]="actionMenuOpened"
        >
          <button
            tuiIconButton
            type="button"
            appearance="icon"
            icon="tuiIconMoreVerticalLarge"
            size="s"
            title="'components.telegramChat.buttons.moreActions' | translate"
          ></button>
        </tui-hosted-dropdown>
      </div>
    </div>
    <div class="chat-layout__pinned-message-list">
      <telegram-pinned-message-list
        [chatId]="chat?.id"
        (scrollToMessageId)="scrollToMessage($event)"
      ></telegram-pinned-message-list>
    </div>
    <div class="chat-layout__body">
      <app-message-list
        *ngIf="chat; else hint"
        [chat]="chat"
        [scrollToMessageId]="scrollToMessageId"
        [(replyToMessageId)]="replyToMessageId"
        [(editMessageId)]="editMessageId"
        (editMessageIdChange)="editMessageIdChange($event)"
        (forwardToChatId)="forwardToChat($event)"
      ></app-message-list>
      <ng-template #hint>
        <div class="hint__wrap">
          <div class="hint">{{ 'components.telegramChat.labels.selectChat' | translate }}</div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="chat && currentUserChatStatus" class="chat-layout__footer footer">
      <ng-container
        *ngIf="
          currentUserChatStatus &&
            (chat.type._ === 'chatTypeBasicGroup' || (chat.type._ === 'chatTypeSupergroup' && !chat.type.isChannel)) &&
            currentUserChatStatus._ !== 'chatMemberStatusCreator' &&
            ((currentUserChatStatus._ === 'chatMemberStatusRestricted' &&
              !currentUserChatStatus.permissions.canSendMessages) ||
              (currentUserChatStatus._ === 'chatMemberStatusMember' && !chatPermissions?.canSendMessages));
          else footerButton
        "
      >
        <div
          class="footer__text"
          *ngIf="
            currentUserChatStatus &&
            currentUserChatStatus._ === 'chatMemberStatusRestricted' &&
            !currentUserChatStatus.permissions.canSendMessages
          "
        >
          {{ 'components.telegramChat.labels.chatMemberStatusRestricted' | translate }}
        </div>
        <div
          class="footer__text"
          *ngIf="
            currentUserChatStatus &&
            currentUserChatStatus._ === 'chatMemberStatusMember' &&
            !chatPermissions?.canSendMessages
          "
        >
          {{ 'components.telegramChat.labels.groupSendMessagesForbiden' | translate }}
        </div>
      </ng-container>

      <ng-template #footerButton>
        <ng-container
          *ngIf="
            chat.type._ === 'chatTypeSupergroup' &&
              chat.type.isChannel &&
              currentUserChatStatus &&
              currentUserChatStatus._ !== 'chatMemberStatusCreator';
            else footerControl
          "
        >
          <div class="footer__button">
            <button mat-button (click)="onChangeNotificationsToggleValue()">
              {{
                chat.notificationSettings.muteFor === 0
                  ? ('components.telegramChat.buttons.notificationsOff' | translate)
                  : ('components.telegramChat.buttons.notificationsOn' | translate)
              }}
            </button>
          </div>
        </ng-container>

        <ng-template #footerControl>
          <telegram-conversation-box-input
            [(replyToMessageId)]="replyToMessageId"
            [(editMessageId)]="editMessageId"
            [(newMessage)]="newMessage"
            [chat]="chat"
            [allowAttachment]="
              !(
                currentUserChatStatus &&
                currentUserChatStatus._ === 'chatMemberStatusRestricted' &&
                !currentUserChatStatus.permissions.canSendMediaMessages
              )
            "
          ></telegram-conversation-box-input>
        </ng-template>
      </ng-template>
    </div>
  </ng-container>
  <div *ngIf="detailsVisible || editing" class="chat-layout__details details">
    <ng-container *ngIf="detailsVisible">
      <div class="details__header">
        <div class="header__title">{{ 'components.telegramChat.labels.info' | translate }}</div>
        <div class="header__button header__button_close">
          <button
            tuiIconButton
            class="button_close"
            type="button"
            appearance="icon"
            icon="tuiIconXLarge"
            size="s"
            [title]="'common.buttons.close' | translate"
            (click)="onClickDetailButton()"
          ></button>
        </div>
      </div>
      <tui-scrollbar class="details__content">
        <telegram-user *ngIf="user" [userTelegram]="user" [chat]="chat"></telegram-user>
        <telegram-bot *ngIf="bot || event || poll" [bot]="bot" [chat]="chat"></telegram-bot>
        <telegram-group *ngIf="superGroup" [superGroup]="superGroup" [chat]="chat"></telegram-group>
        <telegram-group *ngIf="basicGroup" [basicGroup]="basicGroup" [chat]="chat"></telegram-group>
      </tui-scrollbar>
    </ng-container>

    <ng-container *ngIf="editing">
      <div class="details__header">
        <div class="header__title">{{ 'common.buttons.edit' | translate }}</div>
      </div>
      <tui-scrollbar class="details__content">
        <app-poll-info
          *ngIf="poll"
          mode="edit"
          [pollId]="poll.id"
          (canceled)="onCancelEditing()"
          (saved)="onSaved($event)"
        ></app-poll-info>
        <app-event-info
          *ngIf="event"
          mode="edit"
          [eventId]="event.id"
          (canceled)="onCancelEditing()"
          (saved)="onSaved($event)"
          (eventDeleted)="onDeletedEvent()"
        ></app-event-info>
      </tui-scrollbar>
    </ng-container>
  </div>
</div>

<ng-template #confirmDeleteDialogTemplate let-observer>
  <p>{{ 'common.dialogs.areYouSureTitle' | translate }}</p>
  <div class="tui-space_bottom-3">
    <tui-checkbox-block
      *ngIf="currentUserChatStatus && currentUserChatStatus._ === 'chatMemberStatusCreator'"
      [(ngModel)]="deleteFromTelegram"
      [size]="tuiElementMediumSize"
    >
      {{ 'components.telegramChat.labels.deleteFromTelegram' | translate }}
    </tui-checkbox-block>
  </div>
  <button
    tuiButton
    type="button"
    class="tui-space_right-3"
    [size]="tuiElementMediumSize"
    (click)="applyChatDelete(observer)"
  >
    {{ 'common.buttons.yes' | translate }}
  </button>
  <button tuiButton type="button" [size]="tuiElementMediumSize" (click)="observer.complete()">
    {{ 'common.buttons.no' | translate }}
  </button>
</ng-template>

<ng-template #actionMenuTemplate>
  <tui-data-list class="menu-list">
    <ng-container *ngFor="let item of actionMenuItems">
      <button *ngIf="item.visible()" tuiOption class="menu-list__item" (click)="item.action()">
        <tui-svg class="menu-list__icon" [src]="item.iconName"></tui-svg>
        {{ item.title }}
      </button>
    </ng-container>
  </tui-data-list>
</ng-template>
