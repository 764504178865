<div class="web-page">
  <!-- Type of the web page. Can be: article, photo, audio, video, document, profile, app, or something else -->
  <div class="site">
    <a class="site__name" [href]="webPage?.url" target="_blank">{{ webPage?.siteName }}</a>
    <div class="site__title">{{ webPage?.title }}</div>
    <div class="site__description">{{ getSmallDescription(webPage?.description?.text) }}</div>
    <div *ngIf="webPage?.photo" class="site__img" [class.tui-skeleton]="!photo.src">
      <img
        #photo
        [src]="photoPath | safe: 'url'"
        [alt]="'common.labels.picture' | translate"
        width="100%"
        (click)="viewImage()"
      />
    </div>
  </div>
</div>
