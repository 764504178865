import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GroupedListItem } from '@src/app/shared/list/model/groups.model';
import { getGroups } from '@src/app/shared/list/utils/getGroups';
import { UserUI } from '@src/models';

@Component({
  selector: 'users-table-view',
  templateUrl: './users-table-view.component.html',
  styleUrls: ['./users-table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableViewComponent implements OnChanges {
  @Input() users?: UserUI[] | null;
  usersFiltered?: UserUI[];
  groups: GroupedListItem[] = [];

  activeTabIndex = 0;

  readonly columns = ['user', 'role', 'comment'];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      if (this.users) {
        this.groups = getGroups(this.users || [], 'users').filter(group => !!group.items.length);
        this.usersFiltered = this.groups[this.activeTabIndex].items as UserUI[];
      }
    }
  }

  setActiveTabIndex(index: number) {
    this.usersFiltered = this.groups[index].items as UserUI[];
  }
}
