import { EventWithParticipantsAdd, EventWithParticipantsEdit, EventViewWithParticipants } from '../api';

import { ChatModel } from './chat.model';

export interface EventViewUI extends EventViewWithParticipants {
  active?: boolean;
}

export interface EventUI extends EventWithParticipantsAdd, EventWithParticipantsEdit, EventViewWithParticipants {
  photo?: File;
  photoPath?: string;
  photoId?: string;
  programm?: File;
  docs?: File[];
  createGroupChat?: boolean;
  fillOnlineLink?: boolean;
  groupChat?: ChatModel;
  contactPersonFullName?: string;
}
